import Modal from 'react-bootstrap/esm/Modal'
import React, { useState, useRef, useEffect } from "react"
import { ModalType } from "../../../../../enums/ModalType"
import { ModalState } from "../../../../../types/ModalState"
import Form from 'react-bootstrap/esm/Form'
import { DropdownButton, IconWrapper, Label, StyledDropdown, StyledDropdownItemText, StyledDropdownMenu, StyledDropdownMenuItemsWrapper, StyledFormControl, StyledFormControlWrapper, StyledTypeheadMenuItem } from '../../../styledComponents/layoutButton'
import { ModalTrigger } from '../../sandboxModalTrigger'
import FeatherIcon from 'feather-icons-react';
import * as defaultTheme from '../../../../../theme';
import { SearchField } from '../../../../../components/search'
import { invalidFormMessages } from '../../../../../services/messages'

export const DefaultSaveModalBody = ({
    props,
    sidebarMenuItemsCode,
    actionButton,
    snippetName,
    setSnippetName,
    categoryName,
    setCategoryName,
    modalType,
    isInputInvalid: isInvalid,
    setIsInputInvalid: setIsInvalid
}: ModalState) => {
    const items = sidebarMenuItemsCode?.map(item => item.length > 0 && item[0]?.categoryName).filter(e => e)
    const [filteredCategories, setFilteredCategories] = useState(items)
    const [willCreateNewCategory, setWillCreateNewCategory] = useState(false)
    const categoryInputRef = useRef<HTMLDivElement>(null)
    const [openMenu, setOpenMenu] = useState(false);
    const [searchQuery, setSearchQuery] = useState(null)

    const [valueChanged, setValueChanged] = useState(false)

    useEffect(() => setFilteredCategories(items?.filter((cat: string) => cat.includes(searchQuery ?? ''))), [willCreateNewCategory, searchQuery])
    useEffect(() => {
        if(actionButton?.clicked) {
            setIsInvalid({message: invalidFormMessages.emptySnippetField, state: snippetName && snippetName?.trim()?.length > 0 && actionButton.clicked })
        }
    }, [actionButton, snippetName])
    useEffect(() => {
        return () => {
            setIsInvalid({message: '', state: false})
            setValueChanged(false)
        }
    }, [])

    useEffect(() => {
        const hasSameCategory = sidebarMenuItemsCode?.some(itemOrItems => Array.isArray(itemOrItems) && (itemOrItems[0]?.categoryName === categoryName && itemOrItems.some(item => item.snippetName === snippetName)))
        setIsInvalid({message: invalidFormMessages.existingSnippetName, state: hasSameCategory && valueChanged})
    }, [categoryName, valueChanged, snippetName])


    return (
        <Modal.Body className="px-4">
            <Form>
                <Form.Group className="position-relative mt-5" controlId="basicTextForm">
                    <Label className="ml-2">NAME</Label>
                    <StyledFormControl 
                        background="transparent" 
                        onChange={ev => {
                            setValueChanged(true)
                            setSnippetName(ev?.target?.value)
                        }}  
                        type="text" 
                        required 
                        value={snippetName}
                        isInvalid={isInvalid.state} 
                        placeholder="Name" 
                    />
                    <Form.Control.Feedback className="mt-2 mx-2" type="invalid">
                        {isInvalid.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
            <div className="pt-3" ref={categoryInputRef}>
                <StyledDropdown show={openMenu} onToggle={show => setOpenMenu(show)}>
                    <Label className="dropdown-label ml-2">Category</Label>
                    <DropdownButton onClick={() => {
                        setOpenMenu(open => !open)
                    }} width="100%" id="dropdown-basic w-100">
                        <div className="dropdown-button-text-container w-100">
                            {categoryName !== 'null' && categoryName ? categoryName : 'Select category'}
                        </div>
                        <div className="dropdown-button-icon-arrow"></div>
                    </DropdownButton>
                    <StyledDropdownMenu width="100%" className="no-scrollbar">

                        {filteredCategories?.length >= 3 && (
                            <StyledFormControlWrapper className="mx-1 mt-1">
                                <SearchField width="100%" placeholder="Search category" inputValue={searchQuery} onInputValueChange={value => setSearchQuery(value)}></SearchField>
                            </StyledFormControlWrapper>
                        )}


                        <StyledDropdownMenuItemsWrapper showBorderBottom className="no-scrollbar p-1" height={filteredCategories?.length >= 5 ? "215px" : !filteredCategories?.length ? '90px' : 'max-content'}>
                            {!filteredCategories?.length && (
                                <div style={{
                                    color: '#989898',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    width: '70%',
                                    textAlign: 'center',
                                    margin: '18px auto',
                                }} >
                                    You don't have any categories. Click below to create one
                                </div>
                            )}

                            {!willCreateNewCategory && filteredCategories?.map((result, index) => (
                                <StyledTypeheadMenuItem
                                    className="m-0"
                                    onClick={() => {
                                        setCategoryName(result)
                                        setOpenMenu(false)
                                    }}
                                    option={result}
                                    position={index}
                                    active={categoryName === result}
                                >
                                    {result}
                                </StyledTypeheadMenuItem>
                            ))}

                        </StyledDropdownMenuItemsWrapper>
                            <div >
                                <ModalTrigger
                                    props={props}
                                    id={2}
                                    buttonWidth="100%"
                                    buttonTitle="Save as ..."
                                    modalShow={willCreateNewCategory}
                                    setModalShow={setWillCreateNewCategory}
                                    modalType={ModalType.CreateCategory}
                                    customTriggerButton={(
                                        <StyledTypeheadMenuItem
                                            padding="10px"
                                            /* margin="0" */
                                            option={'Add new'}
                                            background="transparent"
                                            onClick={() => {
                                                setWillCreateNewCategory(true)
                                                setOpenMenu(false)
                                                setFilteredCategories([])
                                            }}
                                        >
                                            <IconWrapper margin="0px 8px 0px 0px">
                                                <FeatherIcon icon="plus" size={18} />
                                            </IconWrapper>
                                            <StyledDropdownItemText>
                                                Create new category
                                            </StyledDropdownItemText>
                                        </StyledTypeheadMenuItem >
                                    )}
                                    categoryName={categoryName}
                                    setCategoryName={setCategoryName}
                                    setOpenMenu={setOpenMenu}
                                    headerIconBackground={defaultTheme.default.colors.buttons.run.backgroundColor}
                                    headerIconColor={defaultTheme.default.colors.buttons.run.textColor}
                                    headerIconSrc='/icons/folder-box.svg'
                                    headerTitle='New category'
                                    sidebarMenuItemsCode={sidebarMenuItemsCode}
                                />
                            </div>
                    </StyledDropdownMenu>
                </StyledDropdown>

            </div>
        </Modal.Body>
    );
}
