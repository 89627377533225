import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { Link, navigate } from 'gatsby'
import { makeStyles, Theme, createStyles, Box, InputBase, TextField, InputLabel, Popper, Icon, List, ListItem, ListItemText } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { prettyRoute } from '../utils/formater';
import useWindowListener from '../hooks/useWindowListener';
import { WindowEventType } from '../enums/windowEventType';
import FeatherIcon from 'feather-icons-react';
import { ItemHeader } from './sidebar/userCodeSnippetsSidebar';
import { useStyles as sidebarStyles  } from './../components/sidebar/MuiStyles/MuiSidebarStyles';
import { StyledPopover } from '../templates/sandbox/styledComponents/layoutButton';

declare global {
    interface Window { __LUNR__: any; }
}
if (typeof window !== 'undefined') window.__LUNR__ = window.__LUNR__ || {};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            margin: 0,
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: 'auto',
                width: '100%',
            },
            maxWidth: '300px',
            border: 'none'
        },
        input: {
            padding: 0,
            '&::placeholder': {
                fontSize: '16px',
                fontWeight: '500', 
                color: '#000A0D!important',
                opacity: 1,
                marginLeft: '8px'
            },
        },
        textField: {
            margin: 0,
            padding: '0 16px',
            fontSize: '16px',
            fontWeight: 500, 
            backgroundColor: '#F0F0F3',
            borderRadius: '4px',
            

        },
        searchIcon: {
            width: '20px',
            right: 0,
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        autoComplete: {
           /*  maxWidth: '180px', */
            border: 'none',
            padding: 0,
            position: 'relative',
            height: '42px', 
            '&:hover': {
                border: 'none',
                outline: 'none'
            },
            '& fieldset': {
                border: 'none',
            },
            

        },
        label: {
            transform: 'translate(16px, 12px) scale(1)'
        },
        inputInput: {
            padding: theme.spacing(1, 0, 1, 1),
            // vertical padding + font size from searchIcon
            paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            zIndex: 9999999,
            [theme.breakpoints.up('md')]: {
                width: '12ch',
                '&:focus': {
                    width: '20ch',
                },
            },
            '&::placeholder': {
                fontStyle: 'italic',
                color: 'red',
              },
        },
    }),
);


type SearchFieldProps = {
    onInputValueChange: (args: string) => void
    inputValue?: string
    params?: AutocompleteRenderInputParams
    elementRef?: React.MutableRefObject<any>
    classes?: any
    width?: string | number
    placeholder?: string
    hideIcon?: boolean
    type?: string
    onClick?: () => void
    id?: string
}

export const SearchField = ({onInputValueChange, params, elementRef, inputValue, width, placeholder, hideIcon, type, onClick, id}: SearchFieldProps) => {

    const classes = useStyles()
    return (

            <TextField 
                id={id}
                {...params} 
                margin="normal" 
                inputRef={elementRef} 
                variant="outlined" 
                InputLabelProps={{
                    className: classes?.label
                }} 
                onClick={onClick}
                InputProps={{
                    ...params?.InputProps,
                    type: type ?? 'search',
                    className: classes?.autoComplete,
                    classes: {
                        input: classes?.input,
                    }, 
                    placeholder: placeholder ?? "Search",
                    endAdornment: (
                        <>
                            {!hideIcon && (
                                <div className={classes?.searchIcon}>
                                    <FeatherIcon icon="search" style={{color: '#000A0D'}} />
                                </div>
                            )}

                        </>

                    )
                }} 
                type='text' 
                value={inputValue} 
                onChange={event => {
                    onInputValueChange(event.target.value);
                }} 
                className={classes?.textField}
                style={{
                    width: width ?? '100%',
                }}
            />

        
    );
} 


const Search = () => {
    const classes = useStyles()
    const [query, setQuery] = useState(``)
    const [results, setResults] = useState([])
    const textFieldRef = useRef()
    const type: WindowEventType = WindowEventType.KeyDown
    const callback = () => { }
    useWindowListener({ type, callback })

    useEffect(
        () => {
          
            if (typeof window !== 'undefined') {
                if (!query || !window.__LUNR__) {
                    setResults([])
                    return
                }
                const lunrIndex = window.__LUNR__['en']
                const searchResults = lunrIndex.index.search(query)
                setResults(
                    searchResults.map(({ ref }) => {
                        return lunrIndex.store[ref]
                    })
                )
            }
        },
        [query]
    )
    const PopperMy = function (props) {
        return (<Popper container={autocompleteRef.current} {...props} style={{ width: 500, padding:0}} placement='bottom-end' anchorEl={autocompleteRef.current} />)
    }
    const options = results.map((option) => {

        const { template, link} = option
        const parentName = link?.split('/')[1]
        if (parentName) {
            const parent = prettyRoute(parentName).toUpperCase();
            return {
                parent: /[0-9]/.test(parent) ? '0-9' : parent,
                ...option,
            };
        }
        return {
            parent: '',
            ...option,
        };
    });
    const autocompleteRef = useRef(null)
    const classesGlobal = sidebarStyles()

    const parentChildAndChildClass = `${classesGlobal().listItem}`
    const list = `${classesGlobal().list}`
    return (
        <div style={{ width: '100%' }}>
            <Autocomplete
                ref={autocompleteRef}
                PopperComponent={PopperMy}
                noOptionsText={'No items found'}
                freeSolo={query?.length ? false : true}
                className={classes.autoComplete}
                id="autocomplete"
                disableClearable
                PaperComponent={props => (<StyledPopover {...props} padding="0" disableArrow width='100%'></StyledPopover>)}
                options={options.sort((a, b) => -b.parent.localeCompare(a.parent))}
                groupBy={(option) => option.parent}
                getOptionLabel={(option) => option.title}
                onChange={(event, newValue) => {
                    event.preventDefault()
                    event.stopPropagation()
                    if (typeof newValue === 'object' && newValue !== null) navigate(newValue.link + newValue?.id, { state: { id: newValue?.id } })
                }}
                renderGroup={(params) => (
                    <div className="mb-2" key={params?.key}>
                        <ItemHeader className="m-0" absolute>
                            {params?.group?.toUpperCase()}
                        </ItemHeader>
                        {(params?.children as Array<any>)?.map((category, index) => (
                            <List className={list} key={index}>
                                <ListItem
                                    button
                                    className={parentChildAndChildClass}
                                    onClick={(ev) => {
                                        //@ts-ignore
                                        const itemToNavigateTo = options?.find(option => option?.parent === params?.group && option?.title === ev?.target?.innerText)
                                        if(itemToNavigateTo) {
                                            setQuery('')
                                            navigate(itemToNavigateTo.link + itemToNavigateTo?.id, { state: { id: itemToNavigateTo?.id } })
                                        }
                                    }}
                                >
                                    <ListItemText primaryTypographyProps={{ style: { fontWeight: 500 }, /* className: "elipsis" */ }} primary={category?.props?.children}></ListItemText>                                    
                                </ListItem>
                            </List>
                        ))}
                    </div>
                )}
                renderInput={(params) => (
                    <div className={classes.search}>
                        <SearchField  placeholder="Search site" inputValue={query} onInputValueChange={setQuery} elementRef={textFieldRef} params={params}></SearchField>
                    </div>
                )}
            />
        </div>
    )
}

export default Search