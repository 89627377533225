import { useCallback, useEffect, useState } from "react";
import { Layout } from "../../../enums/layout";
import { isBrowser } from "../../../utils/utils";


const layouts = {
    showHTML: true, 
    showJS: true, 
    showCSS: true,
    showOutput: true,
}
type LayoutServiceProps = {
    setLanguage: (overlay: number) => void,
    isSdkApi: boolean,
    savedLayout: any,
}

export const LayoutService = ({setLanguage, isSdkApi, savedLayout}: LayoutServiceProps) => {
    const [{showHTML, showJS, showCSS, showOutput}, setLayout] = useState(layouts)

    const updateLayout = useCallback((key: string, value: boolean) => {
        setLayout(prev => ({...prev, [key]: value}));
    }, []);



    const [currentLayout, setCurrentLayout] = useState<Layout>(savedLayout > -1 ? savedLayout : Layout.FourByFour)

    useEffect(() => {
        if (isSdkApi) return setHorizontalView();
        switch(savedLayout as Layout) {
            case Layout.FourByFour: {
                return setGridView();
            }
            case Layout.TwoByTwo: {
                return setHorizontalView();
            }
            case Layout.OneByOne: {
                return setFullScreenView();
            }
        }
    }, [currentLayout])

    const setGridView = () => {
        updateLayout('showHTML', true)
        updateLayout('showCSS', true)
        updateLayout('showJS', true)
        updateLayout('showOutput', true)
        setLanguage(0)
        setCurrentLayout(Layout.FourByFour)
    }

    const setHorizontalView = () => {
        updateLayout('showHTML', false)
        updateLayout('showCSS', false)
        updateLayout('showJS', true)
        updateLayout('showOutput', true)
        setLanguage(1)
        setCurrentLayout(Layout.TwoByTwo)
    }

    const setFullScreenView = () => {
        updateLayout('showHTML', false)
        updateLayout('showCSS', false)
        updateLayout('showJS', false)
        updateLayout('showOutput', true)
        setLanguage(2)
        setCurrentLayout(Layout.OneByOne)
    }
    return {showHTML, showJS, showCSS, showOutput, setGridView, setHorizontalView, setFullScreenView, layouts, updateLayout, currentLayout}

}