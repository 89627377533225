import { CircularProgress, } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Overlay from "react-bootstrap/esm/Overlay";
import Popover from "react-bootstrap/esm/Popover";
import { LoaderComponent } from "../../../components/loader";
import { ModalActionType } from "../../../enums/ModalActionType";
import { Icon, Label, StyledButton, ShareUrlContainer, StyledPopover, StyledPopoverTitle } from "../styledComponents/layoutButton";
import * as defaultTheme from '../../../theme';
import PageType from "../../../enums/pages";
import { isBrowser } from "../../../utils/utils";
import { ClipboardService } from "../services/clipboardService";

export const ShareCodeComponent = (props) => {
    const [showTooptip, setShowTooltip] = useState(false)
    const ref = useRef()
    const url = props.pageType === PageType.MyCodeSnippets && isBrowser ? `${window.location.origin}/sandbox/shared/cG9zdDo0ODA1?quer=${props?.searchParams?.quer}` : `${window.location.origin}/sandbox/shared/cG9zdDo0ODA1?quer=${props?.newlyCreatedCodeSnippet?.createComment?.comment?.id}`
    return (
        <div ref={ref}>
            <StyledButton borderRadius="4px" disabled={props?.createPostLoading || !props?.postData?.post?.id && !props?.isInfrontUser}
                onClick={ev => {
                    setShowTooltip(true)
                    if (props.pageType !== PageType.MyCodeSnippets) {

                        props?.shareCode('share', ModalActionType.ShareOnly, 'Shared code');
                    }
                    else {
                        props?.setShowSharingUrl(true)
                    }
                }} className="ml-2"
                backgroundColor={defaultTheme.default.colors.buttons.share.backgroundColor}
                color={defaultTheme.default.colors.buttons.share.textColor}
            >
                {props?.createPostLoading ? <>
                    <CircularProgress size={20} className="mr-2"></CircularProgress>
                </> : <>
                    <div className={props?.shareIcon}></div>
                    <div>Share</div>

                </>}

            </StyledButton>
            <Overlay onHide={ev => {
                props?.setShowSharingUrl(false);
                setShowTooltip(false)
            }} rootClose={true} key="bottom" placement="bottom" show={props?.showSharingUrl && showTooptip} target={ref?.current} container={ref?.current} containerPadding={20}>
                <StyledPopover width="550px" className="ml-3" id={`popover`}>
                    {props?.loading || props?.createPostLoading ? <LoaderComponent /> : <ShareCodeContentComponent setShowSharingUrl={props.setShowSharingUrl} url={url} />}
                </StyledPopover>
            </Overlay>

        </div>
    );
}

type ShareCodeContentComponentProps = {
    url: string,
    setShowSharingUrl?: (show: boolean) => void,
    setShowShareContainer?: React.Dispatch<React.SetStateAction<{ item: any; show: boolean; }>>
}

export const ShareCodeContentComponent = ({ url, setShowSharingUrl, setShowShareContainer }: ShareCodeContentComponentProps) => {
    const clipboard = ClipboardService()
    useEffect(() => {
        clipboard.copyToClipboard(url);
    }, [url])
    return (
        <>
            <StyledPopoverTitle as="span">
                <div className="d-flex justify-content-start align-items-center">
                    <Icon height="35px" width="35px" background="transparent" backgroundImage={true} src="/icons/check.svg" className="mr-3" />
                    URL was created and copied to clipboard
                </div>
                <Icon alignSelf="baseline" width="12px" height="30px" src="/icons/close.svg" className="ml-5 cursor-pointer" onClick={() => {
                    setShowSharingUrl && setShowSharingUrl(false);
                    setShowShareContainer && setShowShareContainer(prev => ({ item: prev.item, show: false }));
                }} />
            </StyledPopoverTitle>
            <Popover.Content className="p-0">
                <div style={{
                    position: 'relative'
                }} className="mt-5">
                    <Label className="ml-2">FULL URL (valid for one week)</Label>
                    <ShareUrlContainer>{url}</ShareUrlContainer>
                </div>
            </Popover.Content>
        </>
    )
}