import styled from 'styled-components'

type ButtonProps = {
    clicked?: boolean
}

const ButtonBase = styled('button')`
    margin: auto;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 22px;
    &:focus {
        outline: none;
    }
`

export {ButtonProps, ButtonBase}