import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Link } from '@reach/router';
import React, { useState } from 'react';
import { useGlobal } from 'reactn';
import { navigate } from 'gatsby';
import { prettyRoute, capitalizeFirstLetters, matchText } from '../../../../utils/formater';
import { useStyles } from '../../MuiStyles/MuiSidebarStyles'
import { UserCodeSnippetSidebarActions } from '../../../../enums/UserCodeSnippetSidebarAction';
import PageType from '../../../../enums/pages';

type CollapsableProps = {
    subMenuItem: any
    index: number,
    width: number | string,
    filteredText: string,
    router: any,
    pageType: PageType,
}

const Collapsable = ({ subMenuItem, index, width, filteredText, router, pageType }: CollapsableProps) => {
    const compare = (first, second): boolean => {
        return first === second
    }
    const classes = useStyles(width);
    const shouldOpenMenu = compare(prettyRoute(router[3] ? router[3] : '').toLowerCase().replace(/[^a-zA-Z]/g, ''), subMenuItem.label.toLowerCase().replace(/[^a-zA-Z]/g, ''))
    const [secondLevelOpen, setSecondLevelOpen] = useState(shouldOpenMenu)
    const handleClick = () => {
        setSecondLevelOpen(!secondLevelOpen);
    };
    const [userCodeSnippetSidebarAction, setUserCodeSnippetSidebarAction] = useGlobal('userCodeSnippetSidebarAction')
    const handleOnNavigationClick = (ev, childItem) => {
        ev.preventDefault();

        if (pageType === PageType.Sandbox || pageType === PageType.MyCodeSnippets) {
            setUserCodeSnippetSidebarAction({
                action: UserCodeSnippetSidebarActions.Navigate, item: {
                    url: childItem.path + childItem?.connectedNode?.node?.id,
                    state: {
                        navigationId: childItem.id,
                        id: childItem?.connectedNode?.node?.id
                    }
                }
            })
        } else {
            navigate(childItem.path + childItem?.connectedNode?.node?.id, { state: { navigationId: childItem.id, id: childItem?.connectedNode?.node?.id } })
        }
    }

    return (
        <div key={index} >
            <List className={classes().list} aria-labelledby="nested-list-subheader">

                <ListItem className={`${classes().childItem}  ${classes().listItem}`} selected={shouldOpenMenu && !subMenuItem.childItems.nodes} button onClick={handleClick}>
                    <ListItemText primaryTypographyProps={{ style: { fontWeight: 500 },/*  className: "elipsis" */ }} primary={capitalizeFirstLetters(subMenuItem.label)} />
                    {secondLevelOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                    in={secondLevelOpen}
                    timeout="auto"
                    unmountOnExit
                >
                    <List className={classes().list}>
                        {subMenuItem.childItems.nodes.map((grandChildren, index) => {
                            const menuItemMatchesThirdLevelMenuItem = compare(prettyRoute(router[4] ? router[4] : '').toLowerCase().replace(/[^a-zA-Z]/g, ''), grandChildren.label.toLowerCase().replace(/[^a-zA-Z]/g, ''))
                            return (
                                <>
                                    <ListItem
                                        key={index}
                                        button
                                        component={Link}
                                        to={(grandChildren?.path + grandChildren?.connectedNode?.node?.id).toString()}
                                        state={{ id: grandChildren?.connectedNode?.node?.id }}
                                        className={`${classes().grandChildItem}  ${classes().listItem}`}
                                        selected={menuItemMatchesThirdLevelMenuItem}
                                        onClick={(ev) => {
                                            handleOnNavigationClick(ev, grandChildren);
                                        }}
                                    >
                                        <ListItemText primaryTypographyProps={{ style: { fontWeight: 500 },/*  className: "elipsis" */ }} primary={matchText(capitalizeFirstLetters(grandChildren.label), filteredText)} />
                                    </ListItem >
                                </>
                            )
                        }
                        )}
                    </List>
                </Collapse>
            </List>
        </div>

    )
}

export default Collapsable