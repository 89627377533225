import React from 'react'
import { ModalType } from '../../../enums/ModalType'
import { ModalActionType } from '../../../enums/ModalActionType'
import { ModalState } from '../../../types/ModalState'
import {ModalPart} from '../../../enums/modalPart'
import { DefaultSaveModalBody } from './modalParts/modalBodies/DefaultSaveModalBody'
import { SaveOnlyModalBody } from './modalParts/modalBodies/SaveOnlyModalBody'
import { SaveAndShareModalBody } from './modalParts/modalBodies/SaveAndShareModalBody'
import { CreateCategoryModalBody } from './modalParts/modalBodies/CreateCategoryModalBody'
import { DefaultSaveModalFooter } from './modalParts/modalFooters/DefaultSaveModalFooter'
import { SaveModalFooter } from './modalParts/modalFooters/SaveModalFooter'
import { DeafultModalHeader } from './modalParts/modalHeaders/DefaultModalHeader'
import { CreateCategoryModalFooter } from './modalParts/modalFooters/CreateCategoryModalFooter'
import { DeleteModalBody as DeleteModalOrDiscardChangesBody } from './modalParts/modalBodies/DeleteModalBody'
import { DeleteSnippetModalFooter } from './modalParts/modalFooters/DeleteSnippetModalFooter'
import { EditSnippetModalFooter } from './modalParts/modalFooters/EditSnippetModalFooter'
import { EditCategoryOrSnippetFooter } from './modalParts/modalFooters/EditCategoryOrSnippetFooter'
import { DeleteCategoryModalFooter } from './modalParts/modalFooters/DeleteCategoryModalFooter'
import { EditCategoryOrSnippetBody } from './modalParts/modalBodies/EditCategoryOrSnippetBody'
import { DiscardChangesModalFooter } from './modalParts/modalFooters/DiscardChangesModalFooter'
import { ReachedMaxAmountSavedSnippetsModalFooter } from './modalParts/modalFooters/ReachedMaxAmountSavedSnippetsModalFooter'


type ModalFactoryProps = {
    modalPart: ModalPart
    modalPartState : ModalState
}
const messages = {
    saveOnly:'The snippet was saved!',
    saveAndShare: 'The snippet was saved and share link has been copied to clipboard.',
    deleteSnippet: 'Are you sure you want to delete snippet?',
    deleteCategory: 'All snippets associated with this category will be deleted. Are you sure you want to procede?',
    discardChanges: 'You have made local changes that will be discarded if you continue. Do you want to discard and continue?',
    maxAmountOfCodeSnippetsReached: 'You have reached the max limit of saved snippets. Delete one or more before you create a new one.'
}

export const ModalFactory = ({modalPart,modalPartState}: ModalFactoryProps) => {
    return <Part modalPart={modalPart} modalPartState={modalPartState}></Part>
}

type PartProps = {
    modalPart: ModalPart
    modalPartState: ModalState
}

export const Part = ({modalPart, modalPartState}: PartProps) => {
    switch(modalPart) {
        case ModalPart.Header: return <HeaderPart modalPartState={modalPartState}/>
        case ModalPart.Body: return <BodyPart modalPartState={modalPartState}/>
        case ModalPart.Footer: return <FooterPart modalPartState={modalPartState}/>
    }
}

type HeaderPartProps = {
    modalPartState: ModalState
}
export const HeaderPart = ({modalPartState}: HeaderPartProps) => (
    <DeafultModalHeader {...modalPartState} />
)
export const BodyPart = ({modalPartState}: HeaderPartProps) => {
    switch(modalPartState.modalType) {
        case ModalType.DuplicateSnippet:
        case ModalType.SaveAs: {
            switch (modalPartState.modalActionType) {
                case ModalActionType.Default: 
                    return <DefaultSaveModalBody {...modalPartState} />
                case ModalActionType.SaveOnly: 
                    return <SaveOnlyModalBody saveMessage={messages.saveOnly} {...modalPartState} />
                case ModalActionType.SaveAndShare: 
                    return <SaveAndShareModalBody saveMessage={messages.saveAndShare} {...modalPartState} />
            }
        }
        case ModalType.CreateCategory: 
        case ModalType.CreateCategoryFromSidebar: 
            return <CreateCategoryModalBody {...modalPartState} /> 
        case ModalType.DeleteSnippet:
        case ModalType.DeleteCodeSnippetFromSidebar: 
            return <DeleteModalOrDiscardChangesBody message={messages.deleteSnippet} {...modalPartState} />
        case ModalType.Edit:
        case ModalType.EditCodeSnippet: 
            return <DefaultSaveModalBody {...modalPartState} />
        case ModalType.EditCategory: 
            return <EditCategoryOrSnippetBody {...modalPartState} />
        case ModalType.DeleteCategory: 
            return <DeleteModalOrDiscardChangesBody message={messages.deleteCategory} {...modalPartState} />
        case ModalType.DiscardConfirm: 
            return <DeleteModalOrDiscardChangesBody message={messages.discardChanges} {...modalPartState} />
        case ModalType.ReachedMaxAmountSavedSnippets:
            return <DeleteModalOrDiscardChangesBody message={messages.maxAmountOfCodeSnippetsReached} {...modalPartState} />
        default: 
            return <div></div>
    }
}
export const FooterPart = ({modalPartState}: HeaderPartProps) => {
    switch(modalPartState.modalType) {
        case ModalType.DuplicateSnippet:
        case ModalType.SaveAs: {
            switch (modalPartState.modalActionType) {
                case ModalActionType.Default: 
                    return <DefaultSaveModalFooter {...modalPartState} />
                default: 
                    return <SaveModalFooter {...modalPartState} />
            }
        }
        case ModalType.CreateCategory: 
        case ModalType.CreateCategoryFromSidebar: 
            return <CreateCategoryModalFooter {...modalPartState} /> 
        case ModalType.DeleteSnippet: 
        case ModalType.DeleteCodeSnippetFromSidebar:
            return <DeleteSnippetModalFooter {...modalPartState} />
        case ModalType.Edit: 
        case ModalType.EditCodeSnippet:
            return <EditSnippetModalFooter {...modalPartState} />
        case ModalType.EditCategory: 
            return <EditCategoryOrSnippetFooter {...modalPartState} />
        case ModalType.DeleteCategory: 
            return <DeleteCategoryModalFooter {...modalPartState} />
        case ModalType.DiscardConfirm: 
            return <DiscardChangesModalFooter {...modalPartState} />
        case ModalType.ReachedMaxAmountSavedSnippets: 
            return <ReachedMaxAmountSavedSnippetsModalFooter {...modalPartState} />
        default: 
            return <div></div>

    }
}