import { Paper } from '@material-ui/core';
import React from 'react';
import { MonacoEditor } from '../../../components/monaco-editor';
import { Language } from '../../../enums/language';
import * as defaultTheme from '../../../theme';
import { TabsContainer, TabsItem } from '../styledComponents/layoutButton';

export const SandboxCodeEditor = (props) => (
    <div style={{
        height: props.sandboxChildrenCount <= 2 || props.isSdk ? '100%' : '460px',
        maxHeight: props.sandboxChildrenCount <= 2 || props.isSdk ? '100%' : '460px'
    }}>
        <Paper style={{
            height: props.sandboxChildrenCount <= 2 || props.isSdk ? '100%' : '460px',
            maxHeight: props.sandboxChildrenCount <= 2 || props.isSdk ? '100%' : '460px',
            background: '#000A0D',
            color: 'white'
        }} elevation={0} square={false}>
            {!props.isSdk && props.language > 0 && props.sandboxChildrenCount <= 2 ? <>
                <TabsContainer>
                    {props.tabsItems.map(tab => {
                        if (tab.value === "showOutput" && props.language < 2) return;
                        return <TabsItem selected={tab.show} onClick={() => tab.callback(tab.value)}>
                            <div style={{
                                position: 'relative'
                            }}>
                                <div id="notification-dot" className='notification-dot' data-language={tab?.language} style={{
                                    position: 'absolute',
                                    width: '6px',
                                    height: '6px',
                                    display: 'none',
                                    borderRadius: '50%',
                                    backgroundColor: defaultTheme.default.colors.notifications.sandboxNotifications.backgroundColor,
                                    top: '-4px',
                                    right: '-11px'
                                }}></div>
                                {tab.title?.toUpperCase()}
                            </div>
                        </TabsItem>;
                    })}
                </TabsContainer>
            </> : <div style={{
                color: 'white',
                margin: 0,
                textTransform: 'capitalize',
                padding: '16px 16px',
                fontWeight: 400,
                fontSize: '12px',
                display: 'flex'
            }}>
                <div style={{
                    position: 'relative'
                }}>
                    {props.item.label?.toUpperCase()}
                    {<div id="notification-dot" className='notification-dot' data-language={props?.item?.language} style={{
                        position: 'absolute',
                        width: '6px',
                        height: '6px',
                        display: 'none',
                        borderRadius: '50%',
                        backgroundColor: defaultTheme.default.colors.notifications.sandboxNotifications.backgroundColor,
                        top: '-4px',
                        right: '-11px'
                    }}>

                    </div>}
                </div>

            </div>}

            <MonacoEditor
                typings={props?.typings}
                notifyOnRunCode={props.notifyOnRunCode}
                setSandboxValue={props.setSandboxValue}
                sandboxValue={props.sandboxValue}
                value={props.item.value}
                setConsoleLogs={props.item.setConsoleLogs}
                consoleLogs={props.item.consoleLogs}
                runCode={props.item.runCode}
                language={props.item.language}
                onChange={props.item.onChange}
                showConsole={props.item.showConsole}
                childrenCount={props.item.childrenCount}
                height={props.item.height}
                hasCodeSnippet={props.item.hasCodeSnippet}
                iframe={props.item.iframe}
                changeOrigValue={props.item.changeOrigValue}
                props={props.props}
                element={document.createElement('div')}
                hasCodeFromDatabase={props.hasCodeFromDatabase}
            />
        </Paper>
    </div>
);
