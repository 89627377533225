import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import { ModalState } from '../../../../types/ModalState'
import { ModalFactory } from "../sandboxModalFactory";
import { ModalPart } from "../../../../enums/modalPart";

export const DeleteSnippetModal = ({
    modalShow,
    setModalShow,
    headerIconBackground,
    headerIconColor,
    headerTitle,
    headerIconSrc,
    deleteComment,
    modalType
}: ModalState) => (
    <Modal contentClassName="delete-modal" centered show={modalShow} onHide={() => {
        setModalShow(false);
    }} id={1}>
        {[ModalPart.Header, ModalPart.Body, ModalPart.Footer].map(modalPart => (
            <ModalFactory modalPart={ModalPart[ModalPart[modalPart]]} modalPartState={{
                modalShow,
                setModalShow,
                headerIconBackground,
                headerIconColor,
                headerTitle,
                headerIconSrc,
                deleteComment,
                modalType
            }} />
        ))}
    </Modal>
);