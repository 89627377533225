import { makeStyles } from '@material-ui/core';
import * as defaultTheme from '../../../theme'

export const useStyles = makeStyles((theme) => ({
    root: {
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#FFF"
        }
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    select: {
        '&.MuiPaper-root , .MuiMenu-paper , .MuiPopover-paper': {
            backgroundColor: 'red'
        }
    },
    switch: {
        width: '70px',
        height: '40px',
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#FFF"
        }
    },
    switchIcon: {
        padding: '5px',
        position: 'relative',
        width: '30px',
        height: '30px',
        bottom: '5px',
        borderRadius: '50%',
        maskSize: 'contain',
        boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'
    },
    skeleton: {
        height: '100%'
    },
    runIcon: {
        color: defaultTheme.default.colors.buttons.run.fillColor,
        background: defaultTheme.default.colors.buttons.run.fillColor,
        maskImage: `url('/icons/play.svg')`,
        maskRepeat: 'no-repeat',
        maskPosition: 'center',
        width: '18px',
        maskSize: 'contain',
        height: '18px',
        marginRight: '0.5rem !important',
    },
    shareIcon: {
        color: defaultTheme.default.colors.buttons.share.fillColor,
        background: defaultTheme.default.colors.buttons.share.fillColor,
        maskImage: `url('/icons/share.svg')`,
        maskRepeat: 'no-repeat',
        maskPosition: 'center',
        maskSize: 'contain',
        width: '18px',
        height: '18px',
        marginRight: '0.5rem !important',
    },
    saveIcon: {
        color: defaultTheme.default.colors.buttons.save.fillColor,
        background: defaultTheme.default.colors.buttons.save.fillColor,
        maskImage: `url('/icons/save.svg')`,
        maskRepeat: 'no-repeat',
        maskPosition: 'center',
        width: '18px',
        maskSize: 'contain',
        height: '18px',
        marginRight: '0.5rem !important',
    }
}));
