import { Box, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
// import useMutationObserver from '@rooks/use-mutation-observer';
import { Dropdown, Menu } from 'antd';
import { trimEnd } from 'lodash';
import React, { Fragment, useMemo, /* useRef, */ useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { FaTruckMonster } from 'react-icons/fa';
// import { FaChevronDown } from 'react-icons/fa';
import { useEffect, useGlobal } from 'reactn';
import styled from 'styled-components';

import InfrontWidget from '../components/infront-widget';
// import { LoaderComponent } from '../components/loader';
import { useHighlightSidebarOnScroll } from '../hooks/useHighlightSidebarOnScroll';
// import { infront } from '../services/auth';
import { pipeInfrontOpts } from '../utils/utils';
import { ClipboardService } from './sandbox/services/clipboardService';

export const DropdownItem = styled.div`
    border-radius: 6px;
    border: 1px solid grey;
    color: black;
    height: 40px;
`

// const StyledHover = styled.div`
//     padding: ${props => props.padding ?? '20px'};
//     max-width: 550px;
//     min-width: ${props => props.minWidth};
//     width: ${props => props.width};
//     border: none;
//     background-color: 'white';
//     display: flex;
//     justify-content: center;
//     text-align: center;
//     box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.15);
// `

export const DropdownStyled = styled(Dropdown)`
    cursor: pointer;
    background-color: ${props => props.isDisabledStyle ? 'lightgrey' : 'white'};
    color: ${props => props.isDisabledStyle ? 'dimgrey' : 'black'};
`

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const emptyInstrumentMetaDataText = 'Please use the search field to find an instrument'

export default function AvailableFieldsTemplate(props) {
    // const { fieldGroup } = props?.widgetData?.page?.acfFields;
    const initialInstrument = new Infront.Instrument(18177, 'DNB');
    initialInstrument.full_name = 'DNB BANK ASA';
    const isSdkAvailableFields = window.location.pathname.split('/')[2] === 'sdk-available-fields';
    const [infront,] = useGlobal('infront');
    const [instrument, setInstrument] = useState(initialInstrument);
    const [contentPageLoaded, setContentPageLoaded] = useGlobal('contentPageLoaded');
    // const pageRefListener = (element) => {
    //     if (element) {
    //         !contentPageLoaded && setContentPageLoaded(true);
    //     }
    // }
    const [instrumentMetaData, setInstrumentMetaData] = useState<string>(emptyInstrumentMetaDataText);
    useHighlightSidebarOnScroll({ scrollContainer: 'available-fields-container', sidebarContainer: 'anchored-sidebar-available-fields' });
    useEffect(() => {
        if (instrument) {
            const feedMetadata = infront?.getModel().getFeedMetaData(instrument.feed);
            setInstrumentMetaData(`<b>${instrument.ticker}</b> | <b>${instrument?.full_name}</b> | <b>${feedMetadata?.feedCode}</b> | <b>${instrument?.feed}</b>`)
        }
    }, [infront, instrument])

    useEffect(() => {
        setContentPageLoaded(false)

    }, [])

    const searchOptions = (opts: Infront.SearchBoxOptions) => {
        opts.enableMultiSelect = false;
        opts.placeholder = instrument ? instrument.ticker : "Search instruments";
        opts.itemTypes = ["INSTRUMENT"];
        opts.showAfterSelect = "TICKER";
        opts.instrumentSelected = function (instrument: Infront.Instrument) {
            setInstrument(instrument);
        };
        return opts;
    };

    const sbOpts = pipeInfrontOpts(() => new Infront.SearchBoxOptions(), searchOptions)

    const page = useMemo(() => (
        <Fragment>
            <h1>{!isSdkAvailableFields ? (<>Available WTK fields</>) : (<>Available SDK fields</>)}</h1>
            <Fragment>

                {<InfrontWidget type="searchWidget" options={sbOpts}></InfrontWidget>}

                {(
                    <Box marginTop={1}>
                        {ReactHtmlParser(instrumentMetaData)}
                    </Box>
                )}
                {!isSdkAvailableFields ? (
                    // <>
                    //     {fieldGroup ? <FieldsGroup pageRef={pageRefListener} groups={fieldGroup} instrument={instrument} infront={infront} /> : <LoaderComponent />}
                    // </>
                    <>
                        <WTKGroup instrument={instrument}></WTKGroup>
                    </>
                ) : (
                    <>
                        <SDKGroup instrument={instrument}></SDKGroup>
                    </>
                )}

            </Fragment>
        </Fragment>
        // ), [fieldGroup, infront, instrument, instrumentMetaData, isSdkAvailableFields, sbOpts])
    ), [instrument, instrumentMetaData, isSdkAvailableFields, sbOpts])
    return (
        <>
            {page}
        </>
    )
}

const SnackBarComponent = ({ showSnackbar, handleCloseSnackbar, message }) => (
    <Snackbar
        open={showSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
    >
        <Alert onClose={handleCloseSnackbar} severity="success">
            {message}
        </Alert>
    </Snackbar>
)

// const getSingleValueWidgetOpts = (valueName, instrument) => {
//     const opts: Infront.InstrumentSingleValueWidgetOptions = new Infront.InstrumentSingleValueWidgetOptions();
//     opts.name = valueName.trim()
//     opts.column = valueName.trim()
//     opts.instrument = instrument
//     opts.enableChangeStatusColors = true;
//     return opts
// }

const splitCase = s => !s || s.indexOf(' ') >= 0 ? s :
    (s.charAt(0).toUpperCase() + s.substring(1))
        .split(/(?<=[a-z])(?=[A-Z])|(?<=[A-Z])(?=[A-Z][a-z])/g)
        .map(x => x.replace(/([0-9]+)/g, '$1 '))
        .join(' ');


type WTKFieldsContentMap = Map<string, Array<{ field: string, data: unknown, description: string }>>;

const getWTKFields = (contentType: string) => {
    return Object.keys(Infront.WTKFields).filter((field: string) => {
        // Here we can filter out WTK fields by name!
        if (field.startsWith('CONTRIBUTED_')) { return false; }

        const contentSet = Infront.getWTKDataContent(field, undefined);
        return contentSet[contentType];
    });
}

const WTKGroup = ({ instrument }) => {
    // Categorize the WTK fields we have by content type
    let fieldMap = useMemo(() => {
        const contentGroups = Object.keys(Infront.WTKDataContent);
        let map = new Map<string, Array<{ field: string, data: unknown, description: string }>>();
        contentGroups.forEach(contentType => {
            const fields = getWTKFields(contentType).map((field: any) => {
                return { field: field, data: undefined, description: undefined };
            });
            map.set(contentType, fields ?? []);
        });
        return map;
    }, []);

    const [fieldData, setFieldData] = useState<WTKFieldsContentMap>(fieldMap)
    const [infront,] = useGlobal('infront');
    useEffect(() => {
        if (!instrument) { console.log("WTKGroup fetch data: missing instrument", instrument); return }
        if (!infront) { console.log("WTKGroup fetch data: missing infront context"); return }
        else {
            const calculatedHistFields = Object.keys(InfrontSDK.CalculatedHistField)
                .filter(field => !['AverageDivYieldFactor'].includes(field)) // super-slow field removed!
                .map(field => InfrontSDK.SymbolField[field]);
            console.log("WTKGroup fetching data for", instrument);
            infront.sdk.get(InfrontSDK.symbolData({
                content: {
                    Basic: true,
                    CompanyMetaData: true,
                    HistoricalPerformance: true,
                    CompanyFundamentals: true,
                    FundDetails: true,
                    // TODO: to be tested:
                    // AdditionalData: true,
                    // Estimates: true,
                    CalculatedHist: true,
                    // TechnicalAnalysis: true,
                },
                fields: [
                    // ...calculatedHistFields, // uncomment this line, if you want very slow data loading!
                ],
                onData: (symbolData: InfrontSDK.SymbolData) => {
                    console.log("WTKGroup received data for", instrument, symbolData);

                    const contentGroups = Object.keys(Infront.WTKDataContent);
                    let map = new Map<string, Array<{ field: string, data: unknown, description: string }>>();
                    contentGroups.forEach(contentType => {
                        const fields = getWTKFields(contentType).map((field: string) => {
                            let data: any;
                            let description: string;
                            try {
                                const fullField = Infront[contentType + 'Fields']?.getField(field);
                                data = fullField.getSDKValue(symbolData);
                            } catch {}
                            try {
                                description = Infront.WTKFieldDescriptionMapping[field];
                            } catch {}
                            return { field, data, description };
                        })
                        map.set(contentType, fields ?? [])
                    });
                    setFieldData(map)
                },
                id: instrument,
            }))
        }
    }, [fieldMap, instrument, infront])

    return (
        <div id="available-fields-container">
            {
                Array.from(fieldData.keys()).map((contentType, contentIndex) => {
                    return (
                        <div id={contentType.toLowerCase()} key={contentIndex + contentType}>
                            <div className='my-2 mt-5 p-0' style={{ clear: "both", fontSize: '26px', fontWeight: 'bold' }}>{splitCase(contentType)}</div>
                            <div className='row m-0'>
                                {
                                    fieldData.get(contentType).map((field, fieldIndex) => (
                                        <DropdownComponentSdk key={fieldIndex + field.field} index={fieldIndex} fieldName={field.field} fieldData={field.data} fieldDescription={field.description}></DropdownComponentSdk>
                                    ))
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}


type SDKFieldsContentMap = Map<string, Array<{ field: InfrontSDK.SymbolField, data: unknown }>>;

const SDKGroup = ({ instrument }) => {
    // Categorize the fields we have by content type
    let fieldMap = useMemo(() => {
        const contentGroups = Object.keys(InfrontSDK.SymbolDataContent);
        let map = new Map<string, Array<{ field: InfrontSDK.SymbolField, data: unknown }>>();
        contentGroups.forEach(contentType => {
            const fields = Object.keys(InfrontSDK.SymbolField).filter((field: InfrontSDK.SymbolField) => {
                const contentSet = InfrontSDK.getSymbolDataContent(field, undefined);
                return contentSet[contentType]
            }).map((field: InfrontSDK.SymbolField) => {
                return { field: field, data: undefined }
            })
            map.set(contentType, fields ?? [])
        });
        return map;
    }, []);

    const [fieldData, setFieldData] = useState<SDKFieldsContentMap>(fieldMap)
    const [infront,] = useGlobal('infront');
    useEffect(() => {
        if (!instrument) { console.log("SDKGroup fetch data: missing instrument", instrument); return }
        if (!infront) { console.log("SDKGroup fetch data: missing infront context"); return }
        else {
            console.log("SDKGroup fetching data for", instrument);
            infront.sdk.get(InfrontSDK.symbolData({
                content: {
                    Basic: true,
                    CompanyMetaData: true,
                    HistoricalPerformance: true,
                    CompanyFundamentals: true,
                    FundDetails: true
                },
                onData: (symbolData: InfrontSDK.SymbolData) => {
                    console.log("SDKGroup received data for", instrument);

                    const contentGroups = Object.keys(InfrontSDK.SymbolDataContent);
                    let map = new Map<string, Array<{ field: InfrontSDK.SymbolField, data: unknown }>>();
                    contentGroups.forEach(contentType => {
                        const fields = Object.keys(InfrontSDK.SymbolField).filter((field: InfrontSDK.SymbolField) => {
                            const contentSet = InfrontSDK.getSymbolDataContent(field, undefined);
                            return contentSet[contentType]
                        }).map((field: InfrontSDK.SymbolField) => {
                            let data: any;
                            try {
                                data = symbolData.get(field);
                            } catch {
                            }
                            return { field, data };
                        })
                        map.set(contentType, fields ?? [])
                    });
                    setFieldData(map)
                },
                id: instrument,
            }))
        }
    }, [fieldMap, instrument, infront])

    return (
        <div id="available-fields-container">
            {
                Array.from(fieldData.keys()).map((contentType, contentIndex) => {
                    return (
                        <div id={contentType.toLowerCase()} key={contentIndex + contentType}>
                            <div className='my-2 mt-5 p-0' style={{ clear: "both", fontSize: '26px', fontWeight: 'bold' }}>{splitCase(contentType)}</div>
                            <div className='row m-0'>
                                {
                                    fieldData.get(contentType).map((field, fieldIndex) => (
                                        <DropdownComponentSdk key={fieldIndex + field.field} index={fieldIndex} fieldName={field.field} fieldData={field.data} fieldDescription={undefined}></DropdownComponentSdk>
                                    ))
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

const DropdownComponentSdk = ({ index, fieldName, fieldData, fieldDescription }) => {
    const [showSnackbar, setShowSnackbar] = useState(false)
    const clipboard = ClipboardService();
    const handleCloseSnackbar = () => {
        setShowSnackbar(false)
    }

    return (
        <>
            {
                <div key={index} style={{ position: 'relative' }} className="d-flex justify-content-around col-8 col-sm-6 col-xl-4 p-1">
                    <DropdownStyled overlay={MenuItem(fieldName, index)} isDisabledStyle={!fieldData} className="col-12 dropdown" disabled={!fieldData} arrow={true} trigger={['click']} >
                        <DropdownItem title={fieldDescription ?? fieldName}
                            onClick={() => {
                                clipboard.copyToClipboard(fieldName);
                                setShowSnackbar(true)
                            }} className="row ">
                            <div className="col-6 p-1 m-auto text-truncate justify-content-center ">{fieldName}</div>
                            <div className="col-5 p-1 m-auto align-content-center text-right justify-content-center text-truncate" style={{ "width": "1px" }}>{fieldData?.toString()}</div>
                        </DropdownItem>
                    </DropdownStyled>
                    <SnackBarComponent showSnackbar={showSnackbar} handleCloseSnackbar={handleCloseSnackbar} message={`Copied '${fieldName}' to clipboard`} ></SnackBarComponent>
                </div>
            }
        </>
    )
}

// const FieldsGroup = ({ groups, instrument, infront, pageRef }) => {
//     return (
//         <div ref={pageRef} id="available-fields-container">
//             {groups.map((group, index) => {
//                 const { fieldGroupTitle, field } = group;
//                 return (
//                     <div id={fieldGroupTitle.toLowerCase().replace(/\s/g, '')} key={index}>
//                         <div className='my-2 mt-5 p-0' style={{ clear: "both", fontSize: '26px', fontWeight: 'bold' }}>{fieldGroupTitle}</div>
//                         <div className='row m-0'>
//                             {field.map((fieldItem, index) => (
//                                 <DropdownComponent index={index} fieldItem={fieldItem} instrument={instrument} />
//                             ))}
//                         </div>
//                     </div>
//                 )
//             })}
//         </div>
//     )
// };

const MenuItem = (desc, k) => (
    <Menu style={{ border: '1px solid grey', borderRadius: '6px' }} >
        <Menu.Item key={k}>
            {ReactHtmlParser(desc)}
        </Menu.Item>
    </Menu>
);

// type DropdownComponentProps = {
//     index: number,
//     fieldItem: any,
//     instrument: Infront.Instrument
// }

// const DropdownComponent = ({ index, fieldItem, instrument }: DropdownComponentProps) => {
//     //const [expanded, setExpanded] = useState(false)
//     const ref = useRef<HTMLDivElement>(null)
//     const popupElementRef = useRef<HTMLDivElement>(null)
//     const [disabled, setDisabled] = useState(true)
//     const dropdownRef = useRef<HTMLDivElement>(null)
//     const [showSnackbar, setShowSnackbar] = useState(false)

//     let opts = getSingleValueWidgetOpts(fieldItem.name, instrument)
//     const clipboard = ClipboardService()

//     useMutationObserver(
//         ref,
//         mutationList => {
//             mutationList.forEach(mutation => {
//                 if (mutation.type === "childList") {
//                     const singleValueWidgetHasValue = (mutation.target.textContent.length && mutation.target.textContent?.trim() !== "-")
//                     setDisabled(!singleValueWidgetHasValue)
//                 }
//             })
//         },
//         {
//             attributes: false,
//             characterData: false,
//             subtree: true,
//             childList: true
//         }
//     );

//     const updatePopupElementVisibility = (show: boolean, element: HTMLElement) => {
//         show ? element.style.display = 'block' : element.style.display = 'none'
//     }
//     const updatePopupElementPosition = (leftPosition: number, topPosition: number, element: HTMLElement) => {
//         element.style.top = topPosition + 'px';
//         element.style.left = leftPosition + 'px';
//     }

//     let timeout = null;
//     const handleOnMouseMove = ev => {
//         if (popupElementRef.current) {
//             updatePopupElementPosition(ev.clientX + 25, ev.clientY - 25, popupElementRef.current)
//         }
//     }
//     const handleOnMouseLeave = ev => {
//         if (popupElementRef.current) {
//             clearTimeout(timeout);
//             updatePopupElementVisibility(false, popupElementRef.current)
//         }
//     }
//     const handleOnMouseOver = ev => {
//         if (popupElementRef.current) {
//             updatePopupElementPosition(ev.pageX + 25, ev.pageY - 25, popupElementRef.current)
//             timeout = setTimeout(() => updatePopupElementVisibility(true, popupElementRef.current), 700)
//         }
//     }

//     useEffect(() => {

//         dropdownRef?.current.addEventListener('mousemove', handleOnMouseMove)
//         dropdownRef?.current.addEventListener('mouseleave', handleOnMouseLeave)
//         dropdownRef?.current.addEventListener('mouseenter', handleOnMouseOver)
//         return () => {
//             dropdownRef?.current.removeEventListener('mousemove', handleOnMouseMove)
//             dropdownRef?.current.removeEventListener('mouseleave', handleOnMouseLeave)
//             dropdownRef?.current.removeEventListener('mouseenter', handleOnMouseOver)
//         }
//     }, [])

//     const handleCloseSnackbar = () => {
//         setShowSnackbar(false)
//     }

//     return (

//         <div ref={dropdownRef} key={index} style={{ position: 'relative' }} className="d-flex justify-content-around col-8 col-md-6 col-xl-4 p-1">

//             <DropdownStyled isDisabledStyle={disabled} className="col-12 dropdown" disabled={disabled} arrow={true} overlay={MenuItem(fieldItem.description, index)} trigger={['click']} >

//                 <DropdownItem onClick={ev => {
//                     clipboard.copyToClipboard(fieldItem.name)
//                     setShowSnackbar(true)
//                 }} className="row">
//                     <div className="col-6 p-1 m-auto text-truncate justify-content-center " /* title={fieldItem.name} */>{fieldItem.name}</div>
//                     <div className="col-5 p-1 m-auto align-content-center text-right justify-content-center text-truncate" >
//                         <InfrontWidget className='text-truncate' optionalRef={ref} type="instrumentSingleValueWidget" options={opts} />
//                     </div>
//                     <div className="col-1 p-1 m-auto align-content-center text-right">
//                         <FaChevronDown />
//                     </div>

//                 </DropdownItem>

//             </DropdownStyled>
//             <StyledHover minWidth='150px' padding="15px" className='popover' style={{ backgroundColor: 'white', position: 'fixed', display: 'none', zIndex: 99 }} ref={popupElementRef}>
//                 {fieldItem.name}
//             </StyledHover>
//             <SnackBarComponent showSnackbar={showSnackbar} handleCloseSnackbar={handleCloseSnackbar} message={`Copied '${fieldItem.name}' to clipboard`}></SnackBarComponent>
//         </div>
//     )
// };
