import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as defaultTheme from '../../../theme'

export const useStyles = (width?: number | string) => makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: '100%',
                maxWidth: width ? width : 360,
                backgroundColor: theme.palette.background.paper,
                padding:  theme.spacing(0)
            },
            nested: {
                margin: theme.spacing(0, 1),
            },
            MuiListItemText: {
                color: theme.palette.primary.contrastText
            }, 
            parentItem: {
                paddingLeft: theme.spacing(1),
            },
            childItem: {
                paddingLeft: '38px' /* theme.spacing(2) */,
            },
            grandChildItem: {
                paddingLeft: '58px',
            },
            listItem: {
                /* height: '43px', */
                fontWeight: 500,
                color: 'black',
                borderRadius: theme.shape.borderRadius,
                margin: '4px 4px',
                paddingTop: '5px',
                paddingBottom: '5px',
                position: 'relative',
                width: 'inherit',
                "&.Mui-selected": {
                  backgroundColor: defaultTheme.default.colors.sidemenuSelectedBackgroundColor,
                  color: defaultTheme.default.colors.sidemenuSelectedTextColor,
    
                },
                "&.Mui-selected:hover": {
                  backgroundColor: defaultTheme.default.colors.white,
                  color: defaultTheme.default.colors.sidemenuSelectedTextColor,
                },
                "&:hover": {
                  backgroundColor: defaultTheme.default.colors.sidemenuSelectedBackgroundColor,
                  color: defaultTheme.default.colors.sidemenuSelectedTextColor,
                  "& > .dropdown-button-icon-grid":  {
                        color: defaultTheme.default.colors.sidemenuSelectedTextColor,
                        background: defaultTheme.default.colors.sidemenuSelectedTextColor,
                        zIndex: 9999,
                    }
                },
                
            }, 
            list: {
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(0),
            },
            slideOutTab: {
                position: 'fixed',
                height: '150px',
                width: '20px',
                right: 0,
                boxShadow: '0 5px 15px rgba(0, 0, 0, .5)',
                background: defaultTheme.default.colors.prussianBlue,
                color: 'white',
                top: '40%',
                '&::after': {
                    content:'"..."',
                    position: 'absolute',
                    background: 'red',
                    fontSize: '70px',
                    writingMode: 'vertical-rl',
                    textOrientation: 'mixed',
                    top: '30%',
                    width: '40x',
                    right: '-11px',
                    lineHeight: '0'
                },
                '&.__swiped': {
                    left: '-20px',
                    position: 'absolute',
                    '&::after': {
                        right: '-10px'
                    }
                },

            },
            drawer: {
                width: '85vw',
                maxWidth: '360px',
                overflowY: 'visible'
            }
        }),
    );