
import React from "react";
import { ModalActionType } from "../../../../../enums/ModalActionType";
import { ModalState } from "../../../../../types/ModalState";
import { StyledButton } from "../../../styledComponents/layoutButton";
import * as defaultTheme from '../../../../../theme';
import Modal from "react-bootstrap/esm/Modal";
import { invalidFormMessages } from "../../../../../services/messages";

export const EditCategoryOrSnippetFooter = ({
    setActionButton,
    setModalShow,
    isInputInvalid, 
    setIsInputInvalid,
    sidebarMenuItemsCode,
    categoryName,
    snippetName
}: ModalState) => (
    <Modal.Footer className="d-flex justify-content-center pb-5 py-4" style={{ borderTop: 'none' }}>
        <StyledButton
            backgroundColor={defaultTheme.default.colors.buttons.run.backgroundColor}
            color={defaultTheme.default.colors.buttons.run.textColor}
            width="175px"
            borderRadius="4px"
            className="mx-2"
            disabled={isInputInvalid.state}
            onClick={() => {

                const hasSameCategory = sidebarMenuItemsCode?.some(itemOrItems => Array.isArray(itemOrItems) && (itemOrItems[0]?.categoryName === categoryName))
                setIsInputInvalid(hasSameCategory)
                if(!hasSameCategory) {
                    setModalShow(false)
                    setActionButton({type: ModalActionType.SaveOnly, clicked: true})
                } else {
                    setIsInputInvalid({message: invalidFormMessages.existingCategoryName, state:  true})
                }
            }}
        >
            Save
        </StyledButton>
    </Modal.Footer>
)