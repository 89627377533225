import React from "react";
import { isBrowser } from "../../../utils/utils";
import { StyledDropdown, StyledDropdownMenu, StyledDropdownItem, Icon, Label, DropdownButton, StyledDropdownMenuItemsWrapper, MenuItemTextWrapper  } from "../styledComponents/layoutButton";

const componentWidth = 170;

export const SandboxLayoutSelectorComponent = (props) => (
    <div>
        <StyledDropdown  $flip className="ml-2">
            <Label className="dropdown-label ml-2">Grid</Label>
            <DropdownButton disabled={props?.isSdkApi} width={componentWidth + 'px'} id="dropdown-basic">
                <div className="d-flex align-content-center align-items-center justify-content-between w-100">
                    <div className="d-flex justify-content-start align-items-center">
                        <Icon src={props?.isSdkApi ? props?.views[1]?.icon : props?.selectedView.icon} className="dropdown-button-icon-grid mr-2"></Icon>
                        <MenuItemTextWrapper>{props?.isSdkApi ? props?.views[1]?.title : props?.selectedView.title}</MenuItemTextWrapper>
                    </div>
                    <div>
                        <Icon width="10px" height="10px" size="contain" src={'/icons/dropdown-arrow.svg'} className="dropdown-button-icon-grid mr-1"></Icon>
                    </div>
                </div>
            </DropdownButton>
            <StyledDropdownMenu width={componentWidth + 'px'} alignRight>
                <StyledDropdownMenuItemsWrapper>
                    {props?.views.map((view, index) => {
                        return <StyledDropdownItem onClick={() => {
                            isBrowser && localStorage.setItem('layout', JSON.stringify(view))
                            view.callback();
                            props?.setInd(prev => prev + 1);
                            props?.setSelectedView(view);

                        }} key={index} value={ props?.isSdkApi ? props?.views[1]?.title : view?.title}>
                            <Icon src={props?.isSdkApi ? props?.views[1]?.icon : view.icon} className="dropdown-button-icon-grid mr-2"></Icon>
                            <MenuItemTextWrapper>{props?.isSdkApi ? props?.views[1]?.title : view?.title}</MenuItemTextWrapper>
                        </StyledDropdownItem>
                    })}
                </StyledDropdownMenuItemsWrapper>
            </StyledDropdownMenu>
        </StyledDropdown>
    </div>
);

