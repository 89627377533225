import React, { useMemo, useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import ReactHtmlParser from 'react-html-parser'; 
import { Highlight } from 'react-fast-highlight';
import 'highlight.js/styles/vs2015.css';      // import your preferred style
import Masonry from 'react-masonry-component';
import { navigate } from "gatsby";
import { Box, Button, CircularProgress } from "@material-ui/core";
import Theme from '../theme'
import { useLazyQuery, useQuery } from "@apollo/client";
import { queryDatabaseIdFromPageId } from "../queries/queryDatabaseIdFromPageId";
import { queryPageByUri } from "../queries/queryPageByName";
import { useEffect } from "react";
import { getBackendUrl } from "../utils/utils";
import { LoaderComponent } from "../components/loader";

export default function WidgetTemplate(props) {
    const {widgetData} = props
    const title = widgetData?.page?.title
    const extendsPages = widgetData?.page?.acfWidget?.extends
    const [dataLoading, setDataLoading] = useState(true)
    const { codeExample, description, options, example, sandboxLink  } = props?.widgetData?.page?.acfWidget ?? {};

    const optionsToSort = JSON.parse(JSON.stringify(options ?? []))
    const sortedOptions = optionsToSort?.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)); 
    useEffect(() => {
        if(props.widgetData) {
            setDataLoading(false)
        }

    }, [props.widgetData])

    const splittedUri = sandboxLink?.url?.split('/')
    const uri = splittedUri?.splice(3, splittedUri.length -1).join('/')
    const {data: databasePage} = useQuery(queryPageByUri, {variables: {uri: uri ?? ''}}) 

    /* const databasePage = props?.widgetData?.page?. */

    let relationship = '';
/*     if(acf.extends && acf.extends.length) {
        relationship = acf.extends[0];
    } */   /* sandbox/widgets-sandbox/market-data-widgets/alert-list/ */
    const masonryOptions = {
        transitionDuration: 0
    };
    const extendsProps = useMemo(() => {
        /* if(!!relationship) return props.allWordpressPage.edges.filter(edge => edge.node.wordpress_id === relationship)[0].node ?? {link: '', title: ''} */
        return ''
    }, [])

    const imagesLoadedOptions = { background: '.my-bg-image-el' }
    const widgetDescription = useMemo(() => (description ? description : ''), []);
    return (
        <div className="widget">
            {dataLoading ? (
                <>
                    <Box width='100%' height='600px' display='flex' justifyContent="center" alignItems="center">
                        <LoaderComponent />
                    </Box>

                </>
            ) : (
                <>
                    <h1>{title}</h1>
                    { codeExample &&
                        <Highlight
                            languages={['js', 'html']}
                            className="my-class"
                        >
                            {codeExample}
                        </Highlight>
                    }
                    { extendsPages ? (
                        <div className='mb-3'>
                            <b>Extends {extendsPages?.map(page => <Link state={{id:page?.id}} to={page?.uri + page?.id}>{page?.title}</Link>)}</b> 
                        </div>
                    ) : ''}
                    { ReactHtmlParser(widgetDescription) }
                    
                    <div className="options my-5">
                        <h2 className="ml-3">Options</h2>
                        <Masonry
                            className={'my-gallery-class'}
                            elementType={'ul'}
                            options={masonryOptions}
                            disableImagesLoaded={false}
                            updateOnEachImageLoad={false}
                            //@ts-ignore
                            imagesLoadedOptions={imagesLoadedOptions} 
                        >
                        { sortedOptions &&  sortedOptions.length ? 
                             sortedOptions?.map((option, index) => {
                                const { name, types, usage, description, required } = option;
                                return (
                                    <div key={index} className="options__item col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                        <h3 className="options__heading">{name}</h3>
                                        { required && <span className="options__label options__label--red">required</span>}
                                        {types && types.map(({type, index}) => <span key={Math.random()} className="options__label">{type}</span>)}
                                        <div className="options__inner">
                                            {usage && <span className="options__property"><b>Usage: </b>{usage}</span>}
                                            {option.default && <span className="options__property"><b>Default: </b>{option.default}</span>}
                                            {description && ReactHtmlParser(description)}
                                        </div>
                                    </div>
                                )
                            }) : '' 
                        }
                        </Masonry>
                    </div>
                    {example && 
                        <div className="example my-1">
                            <div className='d-flex justify-content-between my-3'>
                                <h2>Example</h2>
                                {(sandboxLink && databasePage?.page?.id) && (
                                    <>
                                        <Button variant="contained" color='secondary' onClick={() => navigate('/' + (sandboxLink.url as string).split('/').slice(3,(sandboxLink.url as string).split('/').length-1).join('/') + '/' + databasePage?.page?.id, {
                                            state: {
                                                id: databasePage?.pages?.edges[0]?.node?.id ?? ''
                                            }
                                        })}>
                                            Edit in sandbox
                                        </Button>
                                    </>
                                )}
                            </div>
                                    
                            <Highlight
                                languages={['js', 'html']}
                                className="my-class"
                            >
                                {example}
                            </Highlight>
                        </div>
                    }
                </>
            )}


        </div>
    )
}


