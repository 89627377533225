import typescript from "typescript"
import { SandboxValue } from "../../../types/SandboxValue"
import { b64_to_utf8 } from "../../../utils/utils"

type CodeSnippetServiceProps = {
    setHTML: (value: SandboxValue) => void
    setJS: (value: SandboxValue) => void
    setCSS: (value: SandboxValue) => void
    setHasCodeFromDatabase: (hasCodeFromDatabase: boolean) => void
    javascript: SandboxValue,
    markdown: SandboxValue,
    styles: SandboxValue
    codeSnippet: any
} 

export const CodeSnippetService = ({setHTML, setJS, setCSS, setHasCodeFromDatabase, codeSnippet, javascript, markdown, styles}: CodeSnippetServiceProps) => {
    const resolveCodeSnippet = () => {
        const snippet = codeSnippet
        if(snippet?.comment?.content) {
            const code = snippet.comment.content.replace(/[“”‘’]/g,'"')
            const html = code;
            const div = document.createElement("div");
            div.innerHTML = html;
            const text = div.textContent.toString() || div.innerText.toString() || "";

            try {
                const parts = text.split('|?');
                const html = {originalValue: b64_to_utf8(parts[0]), newValue: b64_to_utf8(parts[0])}
                const js = {originalValue: b64_to_utf8(parts[1]), newValue: b64_to_utf8(parts[1])}
                const css = {originalValue: b64_to_utf8(parts[2]), newValue: b64_to_utf8(parts[2])}
                const oldItems = [javascript, markdown, styles]
                const newItems = [js, html, css]
                const isNotSame = oldItems.some(item => newItems.findIndex(newItem => JSON.stringify(newItem) !==  JSON.stringify(item)) > -1)
                if(isNotSame) {
                    setHTML( {originalValue: b64_to_utf8(parts[0]), newValue: b64_to_utf8(parts[0])} )
                    setJS( {originalValue: b64_to_utf8(parts[1]), newValue: b64_to_utf8(parts[1])} )
                    setCSS( {originalValue: b64_to_utf8(parts[2]), newValue: b64_to_utf8(parts[2])} )
                    setHasCodeFromDatabase(true)
                }

                return parts
            } catch (error) {
                console.log(error)
            }
        }
    }
    return {resolveCodeSnippet}
}
