import React, { useEffect, useState } from "react";
import Search, { SearchField } from "../../../components/search";
import { DropdownButton, Label, MenuItemTextWrapper, StyledDropdown, StyledDropdownItem, StyledDropdownMenu, StyledDropdownMenuItemsWrapper } from "../styledComponents/layoutButton";

const componentButtonWidth = 100;
const componentDropdownWidth = 200;
const componentHeight = 400;

export const WTKVersionSelectorComponent = (props) => {
    const [filteredWtkAndSdkVersions, setFilteredWtkAndSdkVersions] = useState(null)
    const [query, setQuery] = useState<string>(null)

    useEffect(() => {
        if(query?.length) {
            const filteredVersions = props?.releaseNotesContainingManualVersions.filter(version => (version?.version as string)?.toLowerCase()?.replace(/\s/g,'').indexOf(query?.toLowerCase().replace(/\s/g,'')) !== -1)
            setFilteredWtkAndSdkVersions(filteredVersions)

        } else {
            setFilteredWtkAndSdkVersions(props?.releaseNotesContainingManualVersions)
        }
    }, [query])
    return (
        <StyledDropdown onToggle={toggle => setFilteredWtkAndSdkVersions(props?.releaseNotesContainingManualVersions)} className="ml-2">
            <Label className="dropdown-label ml-2">WTK VERSION</Label>
            <DropdownButton width={componentButtonWidth + 'px'} id="dropdown-basic">
                <div className="dropdown-button-text-container">

                    <MenuItemTextWrapper>{props?.toolkitVersion}</MenuItemTextWrapper>
                </div>
                <div className="dropdown-button-icon-arrow"></div>
            </DropdownButton>
            <StyledDropdownMenu width={componentDropdownWidth} height={componentHeight} maxHeight={componentHeight} className="thin-scrollbar" alignRight>
                <StyledDropdownMenuItemsWrapper padding="4px">
                    <SearchField onInputValueChange={inputText => setQuery(inputText)} inputValue={query} />
                    {(filteredWtkAndSdkVersions ?? props?.releaseNotesContainingManualVersions)?.map((releaseNotes, index) => {
                        if (releaseNotes?.version === 'Local' && !props?.isInfrontUser) return;
                        if ((props?.isSdk || props?.isSdkExample) && !releaseNotes?.sdkVersion && releaseNotes?.version !== 'Local' && releaseNotes?.version !== 'Master' && releaseNotes?.version !== 'Beta') return;
                        return (
                            <StyledDropdownItem margin="4px 0" onClick={() => props?.handleChange(releaseNotes?.version)} key={index} value={releaseNotes?.version}>
                                    {releaseNotes?.version}
                            </StyledDropdownItem>
                        ) 
                    })}
                </StyledDropdownMenuItemsWrapper>
            </StyledDropdownMenu>
        </StyledDropdown>
    )
} 