
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/esm/Modal";
import { ModalActionType } from "../../../../enums/ModalActionType";
import { ModalState } from '../../../../types/ModalState'
import { ModalFactory } from "../sandboxModalFactory";
import { ModalPart } from "../../../../enums/modalPart";
import { useLayoutEffect } from "reactn";

export const EditModal = ({
    modalShow,
    setModalShow,
    id,
    headerIconBackground, 
    headerIconColor, 
    headerTitle,
    headerIconSrc,
    modalType, 
    shareCode,
    searchParams,
    location,
    snippetName,
    categoryName,
    loading,
    sidebarMenuItemsCode
}: ModalState) => {
    const [actionButton, setActionButton] = useState({type: ModalActionType.Default, clicked: false})
   
    const [updatedCategoryName, setUpdatedCategoryName] = useState<string>(categoryName);
    const [updatedSnippetName, setUpdatedSnippetName] = useState<string>(snippetName);

    useEffect(() => {
        setTimeout(() => { setUpdatedCategoryName(categoryName)}, 200)
    }, [categoryName])
    useEffect(() => {
        setTimeout(() => {setUpdatedSnippetName(snippetName)}, 200)
        
    }, [snippetName])
    
    const [isInputInvalid, setIsInputInvalid] = useState({message: '', state: false})

    useEffect(() => {
        if(actionButton.clicked) {
            if (actionButton.type === ModalActionType.SaveOnly) {
                shareCode({categoryName: categoryName, updatedCategoryName: updatedCategoryName}, actionButton.type, updatedSnippetName, modalType)
            }
            
        }
    }, [actionButton])

    useLayoutEffect(() => {
        /* setTimeout(() => setCategoryName(searchParams.category), 500) */
        setUpdatedCategoryName(searchParams.category)
    }, [location, searchParams])
            
    useEffect(() => {
        !modalShow && setUpdatedCategoryName(null)
    }, [modalShow])

    useEffect(() => {
        setActionButton({type: ModalActionType.Default, clicked: false})
    }, [])

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modalShow}
            id={id}
            onHide={() => {
                setActionButton({type: ModalActionType.Default, clicked: false})
                setModalShow(false)
            }}
        >
            {[ModalPart.Header, ModalPart.Body, ModalPart.Footer].map(modalPart => (
                <ModalFactory modalPart={ModalPart[ModalPart[modalPart]]} modalPartState={{
                    snippetName: updatedSnippetName,
                    categoryName: updatedCategoryName,
                    setCategoryName: setUpdatedCategoryName,
                    setSnippetName: setUpdatedSnippetName,
                    actionButton,
                    setModalShow,
                    setActionButton,
                    modalType,
                    headerIconBackground, 
                    headerIconColor, 
                    headerTitle,
                    headerIconSrc,
                    loading,
                    sidebarMenuItemsCode,
                    isInputInvalid, 
                    setIsInputInvalid
                }} />
            ))}
        </Modal>
    );
}