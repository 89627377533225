import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { navigate } from '@reach/router';
import queryString from 'query-string';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Overlay from 'react-bootstrap/esm/Overlay';
import Popover from 'react-bootstrap/esm/Popover';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useGlobal } from 'reactn';
import styled from 'styled-components';

import { UserCodeSnippetSidebarActions } from '../../enums/UserCodeSnippetSidebarAction';
import { ShareCodeContentComponent } from '../../templates/sandbox/components/sandboxShareCode';
import {
    Icon,
    IconWrapper,
    StyledDropdownItem,
    StyledDropdownMenuItemsWrapper,
    StyledPopover,
} from '../../templates/sandbox/styledComponents/layoutButton';
import { SearchParams } from '../../types/SearchParams';
import { isBrowser } from '../../utils/utils';
import * as defaultTheme from './../../theme';
import MenuProps from './MenuProps';
import { useStyles } from './MuiStyles/MuiSidebarStyles';

export const BottomBarWrapper = styled('div')`
    display: ${props => props?.show ? 'flex' : 'none'};
`

export const NavBar = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`

export const ProgressBarWrapper = styled('div')`
    width: 65%;
    height: 45px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 25px;
`

export const ProgressBarHeader = styled('div')`
    color:  #B5C0C4;
`

export const ItemHeader = styled('div')`
    margin: 8px 5px;
    font-size: 12px;
    font-weight: 500;
    color: #B5C0C4;
`

export enum PopoverItemsTitle {
    Share = 'Share',
    Duplicate = 'Duplicate',
    EditDetails = 'Edit details',
    Delete = 'Delete'
}

export const UserCodeSnippetsSidebar = ({ menuItems, filteredText, contentTemplateRef, router, location, showSidebar }: MenuProps) => {
    const [filteredItems, setFilteredItems] = useState(null)
    const [userCodeSnippetSidebarAction, setUserCodeSnippetSidebarAction] = useGlobal('userCodeSnippetSidebarAction')
    const [showTooltipCreateNew, setShowTooltipCreateNew] = useState(false);
    const createNewButtonRef = useRef()

    const progressBarHeaderTitle = (currentAmountOfCodeSnippets: number, maxAmountOfCodeSnippets: number) => currentAmountOfCodeSnippets < maxAmountOfCodeSnippets ? `${currentAmountOfCodeSnippets} / ${maxAmountOfCodeSnippets} SNIPPETS SAVED` : `MAXIMUM NUMBER OF SAVED SNIPPETS REACHED. PLEASE DELETE A SNIPPET IN ORDER TO SAVE A NEW ONE.`
    const currentAmountOfCodeSnippets = menuItems?.map(itemsOrItem => (Array.isArray(itemsOrItem)) ? itemsOrItem[0]?.snippetName ? itemsOrItem?.length : 0 : 1)?.reduce((a, b) => a + b, 0)

    let maxAmountOfCodeSnippets = globalThis.snippetLimit;

    const searchParams = isBrowser && decodeURIComponent(window.location.search).substr(1)
        .split('&')
        .reduce((obj, parts) => Object.assign(obj, { [parts.split("=")[0]]: parts.split("=")[1] }), {}) as SearchParams

    const popoverItems = [
        { title: 'Share', iconSrc: '/icons/share.svg', action: (category: boolean, item: any) => setUserCodeSnippetSidebarAction({ action: UserCodeSnippetSidebarActions.Share, item: item }) },
        { title: 'Duplicate', iconSrc: '/icons/duplicate.svg', action: (category: boolean, item: any) => setUserCodeSnippetSidebarAction({ action: UserCodeSnippetSidebarActions.Duplicate, item: item }) },
        { title: 'Edit details', iconSrc: '/icons/edit.svg', action: (category: boolean, item: any) => setUserCodeSnippetSidebarAction({ action: category ? UserCodeSnippetSidebarActions.EditDetailsCategory : UserCodeSnippetSidebarActions.EditDetailsSnippet, item: item }) },
        { title: 'Delete', iconSrc: '/icons/trash.svg', action: (category: boolean, item: any) => setUserCodeSnippetSidebarAction({ action: category ? UserCodeSnippetSidebarActions.DeleteCategory : UserCodeSnippetSidebarActions.DeleteSnippet, item: item }) },
    ]

    const createNewPopoverItems = [
        { title: 'Category', iconSrc: '/icons/folder-box.svg', action: (category: boolean, item?: any) => setUserCodeSnippetSidebarAction({ action: UserCodeSnippetSidebarActions.CreateNewCategory, item: item }) },
        { title: 'Code Snippet', iconSrc: '/icons/code-icon.svg', action: (category: boolean, item?: any) => setUserCodeSnippetSidebarAction({ action: UserCodeSnippetSidebarActions.CreateNewSnippet, item: item }) },
    ]
    useEffect(() => {
        if (filteredText?.length) {
            const filteredMenuItems = menuItems?.filter(item => item.snippetName?.toLowerCase().replace(/\s/g, '').search(filteredText.toLowerCase().replace(/\s/g, '')) > -1);
            setFilteredItems(filteredMenuItems);
        } else {
            setFilteredItems(menuItems);
        }
    }, [filteredText, menuItems])

    const classes = useStyles()

    const parentChildAndChildClass = `${classes().listItem}`

    useEffect(() => {
        if (menuItems && menuItems.length && router && router.length <= 3 && isBrowser && !window.location.search?.length) {
            let children = menuItems.find(menuItem => (menuItem?.categoryName && menuItem?.snippetName) || (menuItem[0]?.categoryName && menuItem[0]?.snippetName))
            if (!!children) {
                if (!children.length) navigate('?' + 'quer=' + menuItems[0]?.id + '&name=' + children[0]?.snippetName + '&category=' + children[0]?.categoryName + '&categoryId=' + children[0]?.categoryId, { state: { isCodeSnippet: true } })
                if (children && children.length) {
                    navigate('?' + 'quer=' + children[0]?.id + '&name=' + children[0]?.snippetName + '&category=' + children[0]?.categoryName, { state: { isCodeSnippet: true } })
                }
            } else {
                navigate('?query=create-new-snippet')
            }
        } else {
            let menuNavigationItem = menuItems.find(menuItem => (menuItem?.categoryName === searchParams.category && menuItem?.snippetName === searchParams.name) || (menuItem[0]?.categoryName === searchParams.category && menuItem[0]?.snippetName === searchParams.name))
            let children = menuItems.find(menuItem => (menuItem?.categoryName && menuItem?.snippetName) || (menuItem[0]?.categoryName && menuItem[0]?.snippetName))

            Array.isArray(menuNavigationItem) && (menuNavigationItem = menuNavigationItem[0])
            if (menuNavigationItem) navigate('?' + 'quer=' + menuNavigationItem?.id + '&name=' + menuNavigationItem?.snippetName + '&category=' + menuNavigationItem?.categoryName, { state: { isCodeSnippet: true } })
            else if (!!children) {
                if (!children.length) navigate('?' + 'quer=' + menuItems[0]?.id + '&name=' + children[0]?.snippetName + '&category=' + children[0]?.categoryName + '&categoryId=' + children[0]?.categoryId, { state: { isCodeSnippet: true } })
                if (children && children.length) {
                    navigate('?' + 'quer=' + children[0]?.id + '&name=' + children[0]?.snippetName + '&category=' + children[0]?.categoryName, { state: { isCodeSnippet: true } })
                }
            }
            else navigate('?query=create-new-snippet')

        }

    }, [filteredItems])

    const navigateToSnippet = (id, categoryName, snippetName) => {
        setUserCodeSnippetSidebarAction({
            action: UserCodeSnippetSidebarActions.Navigate, item: {
                url: '?' + 'quer=' + id + '&name=' + snippetName + '&category=' + categoryName,
                state: {
                    isCodeSnippet: true
                }
            }
        })

    }
    const ContextMenu = (props) => (
        <Overlay
            onHide={ev => {
                props.setShowTooltip(false);
                setTimeout(() => {
                    props?.setShowShareContainer && props?.setShowShareContainer(prev => ({ item: prev.item, show: false }))
                }, 500)
            }}
            rootClose={true}
            placement={props.placement ?? 'right-start'}
            show={props.showTooltip}
            target={props.containerRef}
            container={document.getElementById('codesnippet-nav')}
            containerPadding={8}
        >
            <StyledPopover width={props.showShareContainer?.show ? '400px' : '200px'} padding="4px" rightAligned={!props.isBottomBar} bottomAligned={props.isBottomBar} marginLeft={props.isBottomBar && '8px'} marginTop={props.isBottomBar && '16px'} id={`popover`}>
                <Popover.Content className="p-0">
                    <StyledDropdownMenuItemsWrapper>
                        {props.showShareContainer?.show ? (
                            <div style={{ padding: '20px' }}>
                                <ShareCodeContentComponent
                                    setShowSharingUrl={props?.setShowTooltip}
                                    setShowShareContainer={props?.setShowShareContainer}
                                    url={isBrowser && `${window.location.origin}/sandbox/shared/cG9zdDo0ODA1?quer=${props?.showShareContainer?.item?.id}`}
                                />
                            </div>

                        ) : (
                            <>
                                {props.popoverItems.map((item, key) => <div key={key}>
                                    {props.isCategory ? (
                                        <>
                                            {item.title !== PopoverItemsTitle.Duplicate && item.title !== PopoverItemsTitle.Share && <StyledDropdownItem key={key} onClick={() => {
                                                item.action(true, props?.item)
                                            }}>
                                                <Icon className="mr-2 dropdown-button-icon-grid" src={item.iconSrc}></Icon>
                                                {item.title}
                                            </StyledDropdownItem>}
                                        </>
                                    ) : (
                                        <StyledDropdownItem key={key} onClick={() => {
                                            item?.title === 'Share' ? (
                                                props?.setShowShareContainer(prev => ({ item: prev.item, show: true }))
                                            ) : (
                                                item.action(false, props?.item)
                                            )

                                        }}>
                                            <Icon className="mr-2 dropdown-button-icon-grid" src={item.iconSrc}></Icon>
                                            {item.title}
                                        </StyledDropdownItem>
                                    )}
                                </div>)}
                            </>
                        )}



                    </StyledDropdownMenuItemsWrapper>

                </Popover.Content>
            </StyledPopover>
        </Overlay>
    );

    const ItemWithoutCategory = useCallback(props => {
        const [showShareContainer, setShowShareContainer] = useState({ item: props.category, show: false });
        const [showTooltipChild, setShowTooltipChild] = useState(false);
        const childItemContainerRef = useRef()
        const [showContextMenuIcon, setShowContextMenuIcon] = useState(false);
        const [isSelected, setIsSelected] = useState(searchParams.name === props?.category?.snippetName && searchParams.category === props?.category?.categoryName);

        useEffect(() => {
            const searchParams = isBrowser && decodeURIComponent(window.location.search).substr(1)
                .split('&')
                .reduce((obj, parts) => Object.assign(obj, { [parts.split("=")[0]]: parts.split("=")[1] }), {}) as SearchParams

            setIsSelected(searchParams.name === props?.category?.snippetName && searchParams.category === props?.category?.categoryName)
        }, [searchParams.name, window.location.search])

        return (
            <List className={classes().list} key={props.index}>
                <ListItem
                    onClick={() => {
                        navigateToSnippet(props.category?.id, props.category?.categoryName, props.category?.snippetName)
                    }}
                    selected={isSelected}
                    button
                    onMouseEnter={enter => setShowContextMenuIcon(true)}
                    onMouseLeave={leave => setShowContextMenuIcon(false)}
                    ref={childItemContainerRef}
                    className={parentChildAndChildClass}
                >
                    <ListItemText primaryTypographyProps={{ style: { fontWeight: 500 }, /* className: "elipsis" */ }} primary={props.category?.snippetName}></ListItemText>
                    {showContextMenuIcon && (
                        <Icon absolute right="15px" onClick={() => {
                            setShowTooltipChild(true)
                        }} className="dropdown-button-icon-grid" src='/icons/more-vertical.svg'></Icon>
                    )}


                </ListItem>
                <ContextMenu showShareContainer={showShareContainer} setShowShareContainer={setShowShareContainer} item={props.category} showTooltip={showTooltipChild} isCategory={false} setShowTooltip={setShowTooltipChild} containerRef={childItemContainerRef} popoverItems={popoverItems}></ContextMenu>

            </List>
        )
    }, [])




    const Collapsable = (props) => {
        const searchQueryParams = queryString.parse(location.search)
        const [secondLevelOpen, setSecondLevelOpen] = useState(!!props.category?.find(cat => cat.categoryName === searchQueryParams?.category))
        const [showTooltipParent, setShowTooltipParent] = useState(false);
        const [showContextMenuIcon, setShowContextMenuIcon] = useState(false);
        const handleClick = () => {
            setSecondLevelOpen(!secondLevelOpen);
        };

        const itemContainerRef = useRef()

        return (
            <div key={props.index} >
                <List className={classes().list}>
                    <ListItem
                        onMouseEnter={enter => setShowContextMenuIcon(true)}
                        onMouseLeave={leave => setShowContextMenuIcon(false)}
                        ref={itemContainerRef}
                        className={parentChildAndChildClass}
                        button
                    >
                        {showContextMenuIcon && (
                            <Icon absolute right="45px" onClick={() => {
                                setShowTooltipParent(true)
                            }} className="mr-1 dropdown-button-icon-grid" src='/icons/more-vertical.svg' ></Icon>
                        )}
                        <div onClick={handleClick} className="d-flex align-items-center" style={{ width: '100%' }}>
                            <ListItemText primaryTypographyProps={{ style: { fontWeight: 500 } }} primary={props.category[0].categoryName?.trim().length ? props.category[0].categoryName : 'Uncategorized'} />
                            {secondLevelOpen ? <ExpandLess /> : <ExpandMore />}
                        </div>

                    </ListItem>

                    <ContextMenu item={props.category[0]} showTooltip={showTooltipParent} isCategory={true} setShowTooltip={setShowTooltipParent} containerRef={itemContainerRef} popoverItems={popoverItems}></ContextMenu>
                    <Collapse
                        /* mountOnEnter */
                        //onTitleClick={()=> handleSecondLevelParentMenus(group)}
                        in={secondLevelOpen} timeout="auto" /* unmountOnExit */
                    >
                        <List className={classes().list}>
                            {props.category.map((snippet, index) => {

                                const childItemContainerRef = useRef()
                                const [showTooltipChild, setShowTooltipChild] = useState(false);
                                const [showContextMenuIcon, setShowContextMenuIcon] = useState(false);
                                const [showShareContainer, setShowShareContainer] = useState({ item: snippet, show: false })

                                const [isSelected, setIsSelected] = useState(searchParams.name === props?.category?.snippetName && searchParams.category === props?.category?.categoryName);
                                useEffect(() => {
                                    const searchParams = isBrowser && decodeURIComponent(window.location.search).substr(1)
                                        .split('&')
                                        .reduce((obj, parts) => Object.assign(obj, { [parts.split("=")[0]]: parts.split("=")[1] }), {}) as SearchParams
                                    setIsSelected(searchParams.name === snippet?.snippetName && searchParams.category === snippet?.categoryName)
                                }, [searchParams.name, window.location.search])

                                useEffect(() => {
                                    setTimeout(() => {
                                        if (!showTooltipChild) setShowShareContainer({ item: snippet, show: false })
                                    }, 500)
                                }, [showTooltipChild])
                                useEffect(() => {
                                    if (userCodeSnippetSidebarAction?.action === UserCodeSnippetSidebarActions.Share && snippet.id === userCodeSnippetSidebarAction.item?.id) {
                                        setShowTooltipChild(true)
                                    }
                                }, [userCodeSnippetSidebarAction?.item])

                                return (
                                    <div key={index}>
                                        {snippet?.snippetName && (
                                            <>
                                                <ListItem
                                                    button
                                                    ref={childItemContainerRef}
                                                    className={`${classes().childItem}  ${classes().listItem}`}
                                                    onMouseEnter={enter => setShowContextMenuIcon(true)}
                                                    onMouseLeave={leave => setShowContextMenuIcon(false)}
                                                    selected={isSelected}
                                                >

                                                    {showContextMenuIcon && (
                                                        <Icon absolute right="15px" onClick={() => {
                                                            setShowTooltipParent(false)
                                                            setShowTooltipChild(true)
                                                        }} className="dropdown-button-icon-grid" src='/icons/more-vertical.svg' ></Icon>
                                                    )}

                                                    <div style={{ maxWidth: '185px', width: '100%' }} onClick={() => navigateToSnippet(snippet?.id, snippet?.categoryName, snippet?.snippetName)} className="d-flex align-items-center">
                                                        <ListItemText primaryTypographyProps={{ style: { fontWeight: 500 }, /* className: "elipsis" */ }} primary={snippet?.snippetName?.trim() ? snippet?.snippetName : 'No name'} ></ListItemText>
                                                    </div>
                                                </ListItem >
                                                <ContextMenu showShareContainer={showShareContainer} setShowShareContainer={setShowShareContainer} item={snippet} showTooltip={showTooltipChild} isCategory={false} setShowTooltip={setShowTooltipChild} containerRef={childItemContainerRef} popoverItems={popoverItems}></ContextMenu>
                                            </>

                                        )}
                                    </div>

                                )
                            })}

                        </List>

                    </Collapse>
                </List>
            </div>
        )
    }
    return (
        <NavBar id="codesnippet-nav">
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes().list}
            >
                <div>
                    <ItemHeader>
                        CATEGORIZED SNIPPETS
                    </ItemHeader>
                    {filteredItems?.map((category, index) => Array.isArray(category) && (
                        <Collapsable category={category} index={index}></Collapsable>
                    ))}
                </div>
                <div className="mt-5">
                    <ItemHeader absolute>
                        SNIPPETS WITHOUT CATEGORY
                    </ItemHeader>
                    {filteredItems?.map((category, index) => !Array.isArray(category) && (
                        <ItemWithoutCategory category={category} index={index} />
                    ))}
                </div>
            </List>
            <BottomBarWrapper show={showSidebar}>
                <IconWrapper
                    shadow="0px 3px 6px 0px rgba(203,245,213,0.65);"
                    background={defaultTheme.default.colors.buttons.action.backgroundColor}
                    width="55px"
                    height="55px"
                    className="cursor-pointer"
                    ref={createNewButtonRef}
                    onClick={() => setShowTooltipCreateNew(true)}
                >
                    <Icon
                        width="14px"
                        height="14px"
                        background={defaultTheme.default.colors.buttons.action.fillColor}
                        src='/icons/plus-thin.svg'
                    />
                </IconWrapper>
                <ProgressBarWrapper>
                    <ItemHeader>
                        {progressBarHeaderTitle(currentAmountOfCodeSnippets, maxAmountOfCodeSnippets)}
                    </ItemHeader>
                    <ProgressBar now={currentAmountOfCodeSnippets} max={maxAmountOfCodeSnippets} />
                </ProgressBarWrapper>
                <ContextMenu isBottomBar showTooltip={showTooltipCreateNew} isCategory={false} setShowTooltip={setShowTooltipCreateNew} containerRef={createNewButtonRef} popoverItems={createNewPopoverItems}></ContextMenu>
            </BottomBarWrapper>

        </NavBar>

    )
}