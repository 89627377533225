import { Grid } from '@material-ui/core';
import { navigate } from '@reach/router';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGlobal, useMemo } from 'reactn';
import { StringParam, useQueryParam } from 'use-query-params';

import { LoaderComponent } from '../../components/loader';
import queryStringWrapper from '../../components/querystring-wrapper';
import { SearchField } from '../../components/search';
import { Language } from '../../enums/language';
import { Layout } from '../../enums/layout';
import { ModalActionType } from '../../enums/ModalActionType';
import { ModalType } from '../../enums/ModalType';
import PageType from '../../enums/pages';
import { SavedCodeProperties } from '../../enums/savedCodeProperties';
import { UserCodeSnippetSidebarActions } from '../../enums/UserCodeSnippetSidebarAction';
import useWindowSize from '../../hooks/useWindowSize';
import { getUserCodeSnippetSidebarItems } from '../../layouts/services/sidebarMenuItems';
import { GetPostById } from '../../queries/queryPost';
import * as defaultTheme from '../../theme';
import { SandboxValue } from '../../types/SandboxValue';
import { prettyRoute } from '../../utils/formater';
import { debounce, getBackendUrl, isBrowser, isOdd, translateDatabaseShareItems } from '../../utils/utils';
import { createEditCategoryOrSnippetModal } from './components/modalTriggers/CreateEditCategoryOrSnippetModal';
import { createDeleteModal } from './components/modalTriggers/DeleteModal';
import { createEditModal } from './components/modalTriggers/EditModal';
import { createEditSnippetModal } from './components/modalTriggers/EditSnippetModal';
import { createSaveAsOrDuplicateModal } from './components/modalTriggers/SaveAsOrDuplicateModal';
import { SandboxCodeEditor } from './components/sandboxCodeEditor';
import { SandboxCodeOutput } from './components/sandboxCodeOutput';
import { SandboxLayoutSelectorComponent } from './components/sandboxLayoutSelector';
import { ModalTrigger } from './components/sandboxModalTrigger';
import { ShareCodeComponent } from './components/sandboxShareCode';
import { SandboxSkeletonLoader } from './components/sandboxSkeletonLoader';
import { WTKLanguageSelectorComponent } from './components/sandboxWTKLanguageSelector';
import { WTKSwitchThemeComponent } from './components/sandboxWTKSwitchTheme';
import { WTKVersionSelectorComponent } from './components/sandboxWTKVersionSelector';
import { CodeExecutorService } from './services/codeExecutorService';
import { CodeSnippetService } from './services/codeSnippetService';
import { CodeStringifierService } from './services/codeStringifierService';
import { handleCRUD } from './services/CRUD';
import {
    addCommentMutation,
    deleteCommentMutation,
    getCommentLazyQuery,
    updateCommentAndRefreshPostDataMutation,
    updateCommentMutation,
    updatePostMutation,
} from './services/graphqlQueries';
import { LayoutService } from './services/layoutService';
import {
    createSandboxItem,
    createSandboxItems,
    createSandboxLayoutViews,
    createSandboxTabsItems,
} from './services/staticState';
import { Icon, Label, StyledButton, TextWrapper } from './styledComponents/layoutButton';
import { useStyles } from './styles/sandboxStyles';


function SandboxTemplate(props) {
    const isSdk = props?.router[2]?.search('sdk-api') > -1;
    const isMyCodeSnippets = props?.router[1]?.search('my-code-snippets') > -1;
    const isAnalytics = props?.router[2]?.search('analytics-widgets') > -1;
    const isSdkExample = props?.router[1]?.search('sdk-examples') > -1;
    const isMobileExample = props?.router[2]?.search('mobile-app') > -1;
    const isNewCodeExample = props?.router[1]?.search('new') > -1;
    const isSdkApi = props?.router[2]?.search('sdk-api') > -1;
    const isSandboxShared = (props?.router[0]?.search('sandbox') > -1) && (props?.router[1]?.search('shared') > -1);
    const href = (props?.location?.href || '').replace(new RegExp("#state=.*$", "gm"), '');
    let hasNoSnippet = isBrowser && window.location.search.search('query=create-new-snippet') > -1;
    const releaseNotesData = props?.wtkReleaseNotesData?.nodes[0]?.acfReleaseNotes?.releaseNotes ?? [];
    const userCodeSnippets = getUserCodeSnippetSidebarItems(props?.postData);
    const savedLayout = isBrowser && JSON.parse(localStorage.getItem('layout'))?.layout;

    const currentAmountOfCodeSnippets = userCodeSnippets?.map(itemsOrItem => (Array.isArray(itemsOrItem)) ? itemsOrItem[0]?.snippetName ? itemsOrItem?.length : 0 : 1)?.reduce((a, b) => a + b, 0)
    const maxAmountOfCodeSnippets = globalThis.snippetLimit;

    if (currentAmountOfCodeSnippets <= 0 && (isBrowser && window.location.search.search('Unsaved') < 0 && currentAmountOfCodeSnippets <= 0)) hasNoSnippet = true;

    const reachedMaximumAmountOfSnippets = (currentAmountOfCodeSnippets, maxAmountOfCodeSnippets) => {
        return currentAmountOfCodeSnippets >= maxAmountOfCodeSnippets
    }

    const [token] = useGlobal('token')
    const [loginUrl] = useGlobal('loginUrl')
    const [infront] = useGlobal('infront')
    const [_, setShowLoadingInSidebar] = useGlobal('showLoadingInSidebar')

    const loginId = infront?.getModel()?.login?.loginInfo?.loginId
    const headerName = props?.search?.name ?? props?.router[3]

    const [userCodeSnippetSidebarAction, setUserCodeSnippetSidebarAction] = useGlobal('userCodeSnippetSidebarAction')
    const [sandboxValue, setSandboxValue] = useState([
        { language: Language.Javascript, hasChanged: false },
        { language: Language.HTML, hasChanged: false },
        { language: Language.CSS, hasChanged: false },
    ])

    useEffect(() => {
        onSidebarItemClick(
            userCodeSnippetSidebarAction,
            setDeleteCategoryModalShow,
            setEditCategoryModalShow,
            setEditSnippetModalShow,
            reachedMaximumAmountOfSnippets,
            currentAmountOfCodeSnippets,
            maxAmountOfCodeSnippets,
            setMaxAmountOfSnippetReachedModalShow,
            createCleanNewSnippet,
            setSaveAsModalShow,
            setDeleteSnippetModalShow,
            setCreateNewCategoryModalShow,
            sandboxValue,
            setOpenConfirmPopup,
            resetState
        );
    }, [userCodeSnippetSidebarAction])

    const masterBetaLocalWTKVersions = [
        { version: 'Master' },
        { version: 'Beta' },
        { version: 'Local' }
    ]
    const releaseNotesContainingManualVersions = [...masterBetaLocalWTKVersions, ...releaseNotesData]
    const resetSandboxValues = (reset: boolean) => {
        if (reset) {
            setJS(null);
            setHTML(null);
            setCSS(null);
        }
    }


    const classes = useStyles();
    // GRAPHQL STATE
    const [addComment, { loading: createdCommentLoading, data: newlyCreatedCodeSnippet }] = addCommentMutation();
    const [getComment, { data: codeSnippet, loading: codeSnippetLoading }] = getCommentLazyQuery();
    const [deleteComment, { loading: deletedCodeSnippetLoading }] = deleteCommentMutation({ ...props, userCodeSnippets, resetSandboxValues })
    const [updateComment, { loading: updatingCommentLoading }] = updateCommentMutation(props)
    const [updateCommentAndRefresh] = updateCommentAndRefreshPostDataMutation(props)
    const [updatePostData, { loading: updatedPostLoading }] = updatePostMutation(props)

    useEffect(() => {
        if (codeSnippetLoading) {
            setHasCodeFromDatabase(false)
        } else {
            let timeout = setTimeout(() => setHasCodeFromDatabase(true), 1000)
        }

    }, [codeSnippetLoading])

    useEffect(() => {
        if (createdCommentLoading || updatedPostLoading || deletedCodeSnippetLoading) {
            setShowLoadingInSidebar(true)
        } else {
            setShowLoadingInSidebar(false)
        }
    }, [createdCommentLoading, updatedPostLoading, deletedCodeSnippetLoading])

    // STATE HOOKS
    const [saveAsModalShow, setSaveAsModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [deleteSnippetModalShow, setDeleteSnippetModalShow] = useState(false)
    const [editCategoryModalShow, setEditCategoryModalShow] = useState(false)
    const [editSnippetModalShow, setEditSnippetModalShow] = useState(false)
    const [deleteCategoryModalShow, setDeleteCategoryModalShow] = useState(false)
    const [createNewCategoryModalShow, setCreateNewCategoryModalShow] = useState(false)
    const [maxAmountOfSnippetReachedModalShow, setMaxAmountOfSnippetReachedModalShow] = useState(false)
    const [iframeLoaded, setIframeLoaded] = useState(false)
    const [discardChanges, setDiscardChanges] = useState(false)

    const [hasCodeFromDatabase, setHasCodeFromDatabase] = useState(false)
    const [sandboxChildrenCount, setSandboxChildrenCount] = useState(0)
    const [shareCodeUrl, setShareCodeUrl] = useQueryParam('quer', StringParam);
    const [runCode, setRunCode] = useState(false)
    const [showSharingUrl, setShowSharingUrl] = useState(false);
    const [consoleLogs, setConsoleLogs] = useState([])

    const [localToolkitVersionPort, setLocalToolkitVersionPort] = useState(localStorage.getItem('localToolkitPort') ?? null)

    const [language, setLanguage] = useState(0)
    const windowSize = useWindowSize()
    const [toolkitVersion, setToolkitVersion] = useState(isBrowser && (localStorage.getItem('wtk-version')?.trim() || 'Master'))
    const [isTerminalTheme, setIsTerminalTheme] = useState(false);
    const [hasSandboxData, setHasSandboxData] = useState(false);
    const [isInvalidSnippet, setIsInvalidSnippet] = useState(false);
    const [refreshView, setRefreshView] = useState(0)
    const [openConfirmPopup, setOpenConfirmPopup] = useState(false)

    const [javascript, setJS] = useState<SandboxValue>(null)
    const [markdown, setHTML] = useState<SandboxValue>(null)
    const [styles, setCSS] = useState<SandboxValue>(null)
    const [wtkLanguage, setWtkLanguage] = useState({ language: 'English', code: 'en' })

    useEffect(() => {
        setRunCode(runCode => !runCode)
    }, [wtkLanguage])

    const deps = [javascript, markdown, styles]

    const transformedCommentToCode = translateDatabaseShareItems(codeSnippet?.comment?.content)
    const [typings, setTypings] = useState(null)
    useEffect(() => {
        setRefreshView(prev => prev + 1)
    }, [typings])

    useEffect(() => {
        try {

            const saveTypingsIntoState = async () => {
                const res = await fetch(getBackendUrl() + '/api/get-typings?version=' + toolkitVersion)
                const data = await res.json()
                setTypings(data?.typings)
            }
            saveTypingsIntoState()
        } catch (error) {
            console.warn(error)
        }
    }, [toolkitVersion])

    useEffect(() => {
        !editSnippetModalShow && setUserCodeSnippetSidebarAction(null)
    }, [editSnippetModalShow])

    useEffect(() => {
        if (props?.widgetData?.page?.acfSandbox && !codeSnippet) {
            setJS({ originalValue: props?.widgetData?.page?.acfSandbox?.js, newValue: props?.widgetData?.page?.acfSandbox?.js })
            setHTML({ originalValue: props?.widgetData?.page?.acfSandbox?.html, newValue: props?.widgetData?.page?.acfSandbox?.html })
            setCSS({ originalValue: props?.widgetData?.page?.acfSandbox?.css, newValue: props?.widgetData?.page?.acfSandbox?.css })
            setHasSandboxData(true)
            setRefreshView(prev => prev + 1)
            setRunCode(prev => !prev)
        }
    }, [props?.widgetData?.page?.acfSandbox])

    const createCleanNewSnippet = () => {
        setJS({ newValue: '' });
        setHTML({ newValue: '' });
        setCSS({ newValue: '' });
        setUserCodeSnippetSidebarAction(null);
        navigate(`?&name=Unsaved new snippet`);
        setUserCodeSnippetSidebarAction(null);
    }
    // STATE REF ELEMENTS
    const iframeRef = useRef<HTMLIFrameElement>(null)
    const elmRef = useRef<HTMLDivElement>(null)
    const sandboxRef = useRef<HTMLDivElement>(null)
    //@ts-ignore
    const isReady = useSelector(state => state.infront.isReady);
    useEffect(() => {
        !deleteSnippetModalShow && setUserCodeSnippetSidebarAction(null)
    }, [deleteSnippetModalShow])

    // SERVICES
    const { showHTML, showJS, showCSS, showOutput, setGridView, setHorizontalView, setFullScreenView, layouts, updateLayout, currentLayout } = LayoutService({ setLanguage, isSdkApi, savedLayout })

    const [contentPageLoaded, setContentPageLoaded] = useGlobal('contentPageLoaded')
    useEffect(() => {
        setContentPageLoaded(true)
        return () => {
            resetState()
        }

    }, [])

    const resetState = () => {
        setSaveAsModalShow(false);
        setEditSnippetModalShow(false);
        setEditModalShow(false);
        setDeleteSnippetModalShow(false);
        setEditCategoryModalShow(false);
        setDeleteCategoryModalShow(false);
        setCreateNewCategoryModalShow(false);
        setMaxAmountOfSnippetReachedModalShow(false);
        setDiscardChanges(false);
        setUserCodeSnippetSidebarAction(null);
        setJS(null);
        setHTML(null);
        setCSS(null);
    }

    const codeResolver = CodeSnippetService({ setHTML, setJS, setCSS, setHasCodeFromDatabase, codeSnippet, javascript, markdown, styles })
    const [selectedView, setSelectedView] = useState(isBrowser && localStorage.getItem('layout') ? JSON.parse(localStorage.getItem('layout')) : { title: 'Standard', icon: '/icons/grid.svg', callback: setGridView, layout: Layout.FourByFour });

    const sandboxItems = createSandboxItems(showJS, javascript, setConsoleLogs, consoleLogs, runCode, setJS, sandboxChildrenCount, sandboxRef, hasCodeFromDatabase, iframeRef, showHTML, markdown, setHTML, showCSS, styles, setCSS);
    const sandboxSdkItem = createSandboxItem(showJS, javascript, runCode, setJS, sandboxChildrenCount, sandboxRef, hasCodeFromDatabase, iframeRef);
    const itemsToShow = isSdk ? sandboxSdkItem : sandboxItems;

    /* const [sandboxItems, setSandboxItems] = useState(null) */

    const changeDropdownLanguage = (selectedLayout) => {

        if (!selectedLayout) return

        Object.keys(layouts).forEach(key => key === selectedLayout ? updateLayout(selectedLayout, true) : updateLayout(key, false))
        if (language < 2) {
            updateLayout('showOutput', true)
        }
        if (language >= 2) {
            setRefreshView(prev => prev + 1)
            setRunCode(prev => !prev)
        }
    }
    //const languageMenu = LanguageMenu({changeDropdownLanguage, overlay: language})

    // Parse code snippet we got from database and execute that code in an iframe
    useLayoutEffect(() => {
        if (codeSnippet) {
            if (codeSnippet.comment != null) {
                setJS({ newValue: '' });
                setHTML({ newValue: '' });
                setCSS({ newValue: '' });
                codeResolver.resolveCodeSnippet();
                setRefreshView(prev => prev + 1);
                setRunCode(prev => !prev);
                setHasSandboxData(true);
                setIsInvalidSnippet(false);
            } else {
                setHasSandboxData(false);
                setIsInvalidSnippet(true);
            }
        }
    }, [codeSnippet])

    useEffect(() => {

        const oldCode = [javascript?.originalValue, markdown?.originalValue, styles?.originalValue]
        const newCode = [javascript?.newValue, markdown?.newValue, styles?.newValue]

        const isSameCode = oldCode.join('|') === newCode.join('|')

        if (isReady) {
            const codeExecutor = CodeExecutorService({ iframeRef, toolkitVersion, javascript, markdown, styles, isTerminalTheme, token, loginUrl, localToolkitVersionPort, isAnalytics, isSdkExample, isMobileExample, isNewCodeExample, wtkLanguage: wtkLanguage?.code })
            const timeout = () => setTimeout(() => {
                if (props?.search?.quer) {
                    if (!codeSnippetLoading) {
                        codeExecutor.executeCode()
                    }
                } else {
                    codeExecutor.executeCode()
                }
            }, 1000)
            timeout()
        }
    }, [isReady, toolkitVersion, isTerminalTheme, /* hasCodeFromDatabase, */ /* currentLayout */, runCode])

    useEffect(() => {
        if (isSdk) setHorizontalView()
        else setGridView()
    }, [isSdk])
    /*
        savedLayout */

    // Setting how many child elements sandbox has in order to shift elements when disabling/enabling views
    useEffect(() => {
        try {
            if (isSdkApi) return setSandboxChildrenCount(2);
            const layout = savedLayout ?? currentLayout
            switch (layout) {
                case Layout.FourByFour: {
                    /* setGridView() */
                    return setSandboxChildrenCount(4)
                }
                case Layout.TwoByTwo: {
                    /* setHorizontalView() */
                    return setSandboxChildrenCount(2)
                }
                case Layout.OneByOne: {
                    /* setFullScreenView() */
                    return setSandboxChildrenCount(1)
                }
            }
        } catch (error) {

        }
    }, [showHTML, showJS, showCSS, currentLayout, savedLayout])

    useEffect(() => {
        if (discardChanges) {
            navigate(userCodeSnippetSidebarAction.item?.url, { state: userCodeSnippetSidebarAction.item?.state })
            setDiscardChanges(false)
        }
    }, [discardChanges])

    // Get code snippet from document based on url query string
    useEffect(() => {
        if (props?.search?.quer) {
            getComment({ variables: { id: props?.search?.quer } })
            setRefreshView(prev => prev + 1)
        }
    }, [])

    useEffect(() => {
        if (!createdCommentLoading/*  && shareCodeUrl */) {
            setRefreshView(prev => prev + 1)
            getComment({ variables: { id: props?.search?.quer } })
        }
    }, [shareCodeUrl, createdCommentLoading])

    useEffect(() => {
        if (props?.search?.quer && codeSnippetLoading) setHasSandboxData(false)
        else setHasSandboxData(true)
    }, [props?.search?.quer, codeSnippetLoading])

    // Create share code url after we upload code snippet.
    // Ex. from 'market-data-widgets/historical-overview/' to 'market-data-widgets/historical-overview/:id?quer=commentId'
    useEffect(() => {
        if (newlyCreatedCodeSnippet?.createComment?.comment?.id) {
            //setShareCodeUrl(setCodeSnippet?.createComment.comment.id, 'replace')
        }
    }, [newlyCreatedCodeSnippet])

    const handleWtkVersionChange = (version) => {
        setToolkitVersion(version);
        setRunCode(runCode => !runCode)
    };

    const handleThemeSwitch = (isTerminalTheme) => {
        setIsTerminalTheme(isTerminalTheme)
        setRunCode(runCode => !runCode)
    }

    const rightAreaRef = useRef()

    useEffect(() => {
        if (localToolkitVersionPort) {
            //iframeRef.current?.contentWindow?.location?.reload()
            const codeExecutor = CodeExecutorService({ iframeRef, toolkitVersion, javascript, markdown, styles, isTerminalTheme, token, loginUrl, localToolkitVersionPort, isAnalytics, isSdkExample, isMobileExample, isNewCodeExample, wtkLanguage: wtkLanguage?.code })
            codeExecutor.executeCode()
        }
    }, [localToolkitVersionPort])

    const [notifyOnRunCode, setNotifyOnRunCode] = useState<Language | string>(null)
    useEffect(() => {
        if (notifyOnRunCode) {
            const notTheSameCode = [javascript, markdown, styles].some(code => code?.originalValue !== code?.newValue)
            notTheSameCode && setRunCode(prev => !prev)
            switch (notifyOnRunCode) {
                case Language.Javascript:
                case Language.Typescript:
                    setJS({ originalValue: javascript?.newValue, newValue: javascript?.newValue })
                    break;
                case Language.CSS:
                    setCSS({ originalValue: styles?.newValue, newValue: styles?.newValue })
                    break;
                case Language.HTML:
                    setHTML({ originalValue: markdown?.newValue, newValue: markdown?.newValue })
                    break;
            }
            (!hasNoSnippet && notTheSameCode && props?.pageType === PageType.MyCodeSnippets) && saveCode(markdown?.newValue, javascript?.newValue, styles?.newValue, transformedCommentToCode, editComment);
        }
        const dotElements: Array<HTMLDivElement> = [].slice.call(document.getElementsByClassName('notification-dot'))
        dotElements.forEach(elm => elm.style.display = 'none')
        setSandboxValue(prev => {
            prev?.forEach(value => value.hasChanged = false)
            return prev
        })
        setNotifyOnRunCode(null)
    }, [notifyOnRunCode])

    useEffect(() => {
        const changedLanguageIndex = [javascript, markdown, styles].findIndex(code => code?.originalValue !== code?.newValue)
        const dotElements: Array<HTMLDivElement> = [].slice.call(document.getElementsByClassName('notification-dot'))
        const javascriptElm = dotElements.find(elm => elm.attributes.getNamedItem('data-language').value === Language.Javascript)
        const htmlElm = dotElements.find(elm => elm.attributes.getNamedItem('data-language').value === Language.HTML)
        const cssElm = dotElements.find(elm => elm.attributes.getNamedItem('data-language').value === Language.CSS)
        switch (changedLanguageIndex) {
            case 0:
                setSandboxValue(prev => {
                    prev[0].hasChanged = true
                    return prev
                })

                javascriptElm && (javascriptElm.style.display = 'block')
                break;
            case 1:
                setSandboxValue(prev => {
                    prev[1].hasChanged = true
                    return prev
                })
                htmlElm && (htmlElm.style.display = 'block')
                break;
            case 2:
                setSandboxValue(prev => {
                    prev[2].hasChanged = true
                    return prev
                })
                cssElm && (cssElm.style.display = 'block')
                break;
            case -1:
                setSandboxValue(prev => {
                    prev?.forEach(value => value.hasChanged = false)
                    return prev
                })
                dotElements.forEach(elm => elm.style.display = 'none')
                break;
        }
    }, [javascript, markdown, styles])

    const onRunCodeClick = () => {
        const timeout = () => setTimeout(() => setRunCode(prev => !prev), 500)
        timeout()
    }

    const shareCode = (categoryName: string, actionType: ModalActionType, snippetName: string, modalType: ModalType) => {
        handleCRUD(setUserCodeSnippetSidebarAction, actionType, markdown?.newValue, javascript?.newValue, styles?.newValue, categoryName, snippetName, loginId, modalType, props, userCodeSnippetSidebarAction, updatePostData, updateComment, deleteComment, updateCommentAndRefresh, addComment, editComment, setShowSharingUrl, resetState);
    }

    const sandboxLayoutViews = createSandboxLayoutViews(setGridView, setHorizontalView, setFullScreenView)
    const tabItems = createSandboxTabsItems(showOutput, changeDropdownLanguage, showJS, showHTML, showCSS)

    const deleteModal = createDeleteModal(deleteSnippetModalShow, setDeleteSnippetModalShow, props, deleteComment, userCodeSnippetSidebarAction?.action === UserCodeSnippetSidebarActions.DeleteSnippet ? ModalType.DeleteCodeSnippetFromSidebar : ModalType.DeleteSnippet)
    const editCategoryOrSnippetModal = createEditCategoryOrSnippetModal(shareCode, setEditCategoryModalShow, editCategoryModalShow, props, updatingCommentLoading, codeSnippetLoading, userCodeSnippetSidebarAction?.action, updatedPostLoading, userCodeSnippetSidebarAction?.item?.categoryName, userCodeSnippetSidebarAction?.item?.snippetName)
    const createNewModal = createSaveAsOrDuplicateModal(
        { ...props, newlyCreatedCodeSnippet },
        shareCode,
        reachedMaximumAmountOfSnippets(currentAmountOfCodeSnippets, maxAmountOfCodeSnippets) ? setMaxAmountOfSnippetReachedModalShow : setSaveAsModalShow,
        reachedMaximumAmountOfSnippets(currentAmountOfCodeSnippets, maxAmountOfCodeSnippets) ? maxAmountOfSnippetReachedModalShow : saveAsModalShow,
        createdCommentLoading,
        codeSnippetLoading,
        updatedPostLoading,
        reachedMaximumAmountOfSnippets(currentAmountOfCodeSnippets, maxAmountOfCodeSnippets) ? ModalType.ReachedMaxAmountSavedSnippets : userCodeSnippetSidebarAction?.action === UserCodeSnippetSidebarActions.Duplicate ? ModalType.DuplicateSnippet : ModalType.SaveAs,
        userCodeSnippetSidebarAction?.action === UserCodeSnippetSidebarActions.Duplicate ? 'Duplicate' : 'Save code snippet'
    )
    const editModal = createEditModal(setEditModalShow, shareCode, editModalShow, props, updatingCommentLoading, codeSnippetLoading)
    const editCodeSnippetModal = createEditSnippetModal(setEditSnippetModalShow, shareCode, editSnippetModalShow, props, updatingCommentLoading, codeSnippetLoading, userCodeSnippetSidebarAction?.item?.categoryName, userCodeSnippetSidebarAction?.item?.snippetName)
    const createCategoryModal = createCreateCategoryModal(createNewCategoryModalShow, setCreateNewCategoryModalShow, shareCode, props)
    const deleteCategoryModal = createDeleteCategoryModal(shareCode, setDeleteCategoryModalShow, deleteCategoryModalShow, updatingCommentLoading, codeSnippetLoading, props)

    const discardConfirmModal = useMemo(() => (
        <ModalTrigger
            modalType={ModalType.DiscardConfirm}
            noButton
            setModalShow={setOpenConfirmPopup}
            modalShow={openConfirmPopup}
            id={Math.floor(100000 + Math.random() * 900000)}
            discardChanges={setDiscardChanges}
            headerTitle='Discard changes'
            headerIconSrc="/icons/warning-triangle.svg"
            headerIconBackground={defaultTheme.default.colors.buttons.delete.backgroundColor}
            headerIconColor={defaultTheme.default.colors.buttons.delete.textColor} />
    ), [openConfirmPopup]);

    const maxAmountOfSnippetReachedModal = useMemo(() => (
        <ModalTrigger
            modalType={ModalType.ReachedMaxAmountSavedSnippets}
            noButton
            setModalShow={setMaxAmountOfSnippetReachedModalShow}
            modalShow={maxAmountOfSnippetReachedModalShow}
            id={Math.floor(100000 + Math.random() * 900000)}
            discardChanges={setMaxAmountOfSnippetReachedModalShow}
            headerTitle='Max limit reached'
            headerIconSrc="/icons/warning-triangle.svg"
            headerIconBackground={defaultTheme.default.colors.buttons.delete.backgroundColor}
            headerIconColor={defaultTheme.default.colors.buttons.delete.textColor} />
    ), [maxAmountOfSnippetReachedModalShow]);

    const handleLocalPortChange = debounce(() => {

        setRefreshView(prev => prev + 1)
        setRunCode(prev => !prev)
    }, 1000, false);


    return (
        <div className="h-100">
            {props?.pageType === PageType.MyCodeSnippets && (props?.postDataLoading || createdCommentLoading || codeSnippetLoading || updatedPostLoading || deletedCodeSnippetLoading) ? (
                <LoaderComponent />
            ) : (

                <div style={{ height: '100%' }} ref={sandboxRef}>
                    {(isInvalidSnippet && isSandboxShared) ? (
                        <>
                            <div className="d-flex justify-content-center flex-column align-items-center mb-3 h-100">
                                <div className="text-center mb-3">
                                    <TextWrapper fontSize="30px">
                                        Shared link is invalid or has been expired
                                    </TextWrapper>
                                    <TextWrapper color={defaultTheme.default.colors?.text.secondary} fontSize="16px">
                                        {href}
                                    </TextWrapper>
                                </div>
                            </div>
                        </>
                    ) : (
                        (hasNoSnippet && isMyCodeSnippets) ? (
                            <>
                                <div className="d-flex justify-content-center flex-column align-items-center mb-3 h-100">
                                    <div className="text-center mb-3">
                                        <TextWrapper fontSize="30px">
                                            No saved snippets
                                        </TextWrapper>

                                    </div>
                                    <div className="text-center my-3">
                                        <TextWrapper color={defaultTheme.default.colors?.text.secondary} fontSize="13px">
                                            You have no saved snippets. Create a new one?
                                        </TextWrapper>

                                    </div>
                                    <div className="text-center my-3">
                                        <StyledButton
                                            backgroundColor={defaultTheme.default.colors.buttons.action.backgroundColor}
                                            width="300px"
                                            height="35px"
                                            justifyContent="flex-start"
                                            borderRadius="0"
                                            padding="18px 20px"
                                            color={defaultTheme.default.colors.buttons.action.textColor}
                                            onClick={() => createCleanNewSnippet()}
                                        >
                                            <Icon
                                                width="11px"
                                                height="11px"
                                                background={defaultTheme.default.colors.buttons.action.fillColor}
                                                src='/icons/plus-thin.svg'
                                            />
                                            <div className="d-flex justify-content-center w-100">
                                                <TextWrapper fontSize="12px">
                                                    New snippet
                                                </TextWrapper>

                                            </div>

                                        </StyledButton>
                                    </div>

                                </div>
                            </>
                        ) : (

                            <>
                                <div className="d-flex justify-content-between mb-3">
                                    <div>
                                        <h1 className='capitalize'>{prettyRoute(headerName)}</h1>
                                    </div>
                                    {props.pageType === PageType.MyCodeSnippets && (
                                        <div className="d-flex justify-content-between">
                                            {editModal}
                                            {deleteModal}
                                        </div>
                                    )}


                                </div>

                                <div className="d-flex justify-content-between mb-4">
                                    <div className="d-flex align-items-center col-6 px-0">
                                        <div className='my-auto d-flex align-items-center justify-content-start'>
                                            <StyledButton
                                                backgroundColor={defaultTheme.default.colors.buttons.run.backgroundColor}
                                                color={defaultTheme.default.colors.buttons.run.textColor}
                                                onClick={onRunCodeClick}
                                                borderRadius="4px"
                                            >
                                                <div className={classes.runIcon}></div>
                                                Run
                                            </StyledButton>
                                            {(props.pageType === PageType.MyCodeSnippets) && (
                                                <StyledButton
                                                    {...{
                                                        backgroundColor: defaultTheme.default.colors.buttons.save.backgroundColor,
                                                        color: defaultTheme.default.colors.buttons.save.textColor,
                                                        className: "ml-2"
                                                    }}
                                                    borderRadius="4px"
                                                    onClick={() => {
                                                        setJS({ originalValue: javascript?.newValue, newValue: javascript?.newValue })
                                                        setHTML({ originalValue: markdown?.newValue, newValue: markdown?.newValue })
                                                        setCSS({ originalValue: styles?.newValue, newValue: styles?.newValue })
                                                        saveCode(markdown?.newValue, javascript?.newValue, styles?.newValue, transformedCommentToCode, editComment);
                                                    }}
                                                >
                                                    <div className={classes["saveIcon"]}></div>
                                                    <div>Save</div>
                                                </StyledButton>
                                            )}


                                            <StyledButton borderRadius="4px" onClick={() => setSaveAsModalShow(true)} className="ml-2" width={(props.pageType === PageType.MyCodeSnippets) ? "130px" : "95px"} backgroundColor={defaultTheme.default.colors.buttons.save.backgroundColor} color={defaultTheme.default.colors.buttons.save.textColor}>
                                                <div className={classes['saveIcon']}></div>
                                                {(props.pageType === PageType.MyCodeSnippets) ? "Save as..." : "Save"}
                                            </StyledButton>

                                            {props.pageType !== PageType.Shared && (
                                                <ShareCodeComponent
                                                    shareIcon={classes.shareIcon}
                                                    postData={props?.postData}
                                                    showSharingUrl={showSharingUrl}
                                                    setShowSharingUrl={setShowSharingUrl}
                                                    shareCode={shareCode}
                                                    createPostLoading={props?.createPostLoading}
                                                    loading={createdCommentLoading}
                                                    pageType={props.pageType}
                                                    searchParams={props?.search}
                                                    newlyCreatedCodeSnippet={newlyCreatedCodeSnippet}
                                                />

                                            )}

                                        </div>
                                    </div>
                                    <div ref={rightAreaRef} className="d-flex align-items-center justify-content-end col-6 pr-0">
                                        <SandboxLayoutSelectorComponent
                                            setInd={setRefreshView}
                                            selectedView={selectedView}
                                            setSelectedView={setSelectedView}
                                            views={sandboxLayoutViews}
                                            isSdkApi={isSdk}
                                        />
                                        <WTKLanguageSelectorComponent
                                            isSdk={isSdk}
                                            isSdkExample={isSdkExample}
                                            languages={props?.languages}
                                            wtkLanguage={wtkLanguage}
                                            isInfrontUser={props?.isInfrontUser}
                                            setWtkLanguage={setWtkLanguage}
                                        />
                                        <WTKSwitchThemeComponent
                                            isTerminalTheme={isTerminalTheme}
                                            handleSwitch={handleThemeSwitch}
                                        />

                                        <WTKVersionSelectorComponent
                                            isSdk={isSdk}
                                            isSdkExample={isSdkExample}
                                            releaseNotesContainingManualVersions={releaseNotesContainingManualVersions}
                                            toolkitVersion={toolkitVersion}
                                            isInfrontUser={props?.isInfrontUser}
                                            handleChange={handleWtkVersionChange}
                                        />
                                        {toolkitVersion?.toLowerCase() === 'local' && (
                                            <div className="ml-2">
                                                <Label className="dropdown-label ml-2">LOCAL PORT</Label>
                                                <SearchField width="100px" type="number" inputValue={localToolkitVersionPort} onInputValueChange={port => {
                                                    isBrowser && window.localStorage.setItem('localToolkitPort', port)
                                                    setLocalToolkitVersionPort(port)
                                                    handleLocalPortChange()
                                                }} hideIcon placeholder={localToolkitVersionPort} />
                                            </div>
                                        )}



                                    </div>
                                </div>
                                <Grid justifyContent="space-between" /* key={hasSandboxData ? 1: 0} */ spacing={2} container ref={elmRef} style={{ height: sandboxChildrenCount <= 3 || isSdk ? '68vh' : '90%' }}>
                                    <>
                                        {showOutput && (
                                            <>
                                                {hasSandboxData ? (
                                                    <SandboxCodeOutput
                                                        key={runCode ? 1 : 0}
                                                        isSdk={isSdk}
                                                        setIframeLoaded={setIframeLoaded}
                                                        sandboxChildrenCount={sandboxChildrenCount}
                                                        runCode={runCode}
                                                        consoleLogs={consoleLogs}
                                                        setConsoleLogs={setConsoleLogs}
                                                        language={language}
                                                        isTerminalTheme={isTerminalTheme}
                                                        iframeRef={iframeRef}
                                                        tabsItems={tabItems}
                                                    />
                                                ) : (
                                                    <Grid item xs={language < 2 ? 6 : 12}>
                                                        <SandboxSkeletonLoader />
                                                    </Grid>
                                                )}

                                            </>
                                        )}
                                        <React.Fragment  >
                                            {itemsToShow?.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    {item.show && (
                                                        <Grid key={hasCodeFromDatabase ? 1 : 0} style={{ height: (sandboxChildrenCount <= 2 || isSdk) && '100%' }} item xs={isOdd(sandboxChildrenCount) > 0 && sandboxChildrenCount < 2 ? 12 : 6}>
                                                            {(hasSandboxData && typings?.length && deps?.some(dep => dep)) ? (
                                                                <SandboxCodeEditor
                                                                    key={currentLayout}
                                                                    props={props}
                                                                    isSdk={isSdk}
                                                                    sandboxChildrenCount={sandboxChildrenCount}
                                                                    language={language}
                                                                    changedValue={sandboxValue}
                                                                    notifyOnRunCode={setNotifyOnRunCode}
                                                                    tabsItems={tabItems}
                                                                    item={item}
                                                                    setSandboxValue={setSandboxValue}
                                                                    sandboxValue={sandboxValue}
                                                                    hasCodeFromDatabase={hasCodeFromDatabase}
                                                                    typings={typings}
                                                                />
                                                            ) : (
                                                                <SandboxSkeletonLoader />
                                                            )}
                                                        </Grid>
                                                    )}
                                                </React.Fragment>
                                            ))}

                                        </React.Fragment>


                                    </>

                                </Grid>


                            </>

                        ))}

                </div>
            )}
            {editCategoryOrSnippetModal}
            {deleteCategoryModal}
            {createCategoryModal}
            {editCodeSnippetModal}
            {discardConfirmModal}
            {maxAmountOfSnippetReachedModal}
            {createNewModal}
        </div>

    )

    function editComment(editedStringifiedCode: string, refetchQueries?: boolean) {
        refetchQueries ? (
            updateComment(
                {
                    variables: {
                        content: editedStringifiedCode,
                        id: codeSnippet?.comment?.id
                    },
                    refetchQueries: [
                        {
                            query: GetPostById,
                            variables: { title: loginId?.toString() }
                        }
                    ]
                }
            )
        ) : (
            updateComment(
                {
                    variables: {
                        content: editedStringifiedCode,
                        id: codeSnippet?.comment?.id
                    },
                }
            )
        )

    }

}


export default queryStringWrapper(SandboxTemplate)




function onSidebarItemClick(
    userCodeSnippetSidebarAction: { action: UserCodeSnippetSidebarActions; item: any; },
    setDeleteCategoryModalShow: React.Dispatch<React.SetStateAction<boolean>>,
    setEditCategoryModalShow: React.Dispatch<React.SetStateAction<boolean>>,
    setEditSnippetModalShow: React.Dispatch<React.SetStateAction<boolean>>,
    reachedMaximumAmountOfSnippets: (currentAmountOfCodeSnippets: any, maxAmountOfCodeSnippets: any) => boolean,
    currentAmountOfCodeSnippets: any, maxAmountOfCodeSnippets: number,
    setMaxAmountOfSnippetReachedModalShow: React.Dispatch<React.SetStateAction<boolean>>,
    createCleanNewSnippet: () => void,
    setSaveAsModalShow: React.Dispatch<React.SetStateAction<boolean>>,
    setDeleteSnippetModalShow: React.Dispatch<React.SetStateAction<boolean>>,
    setCreateNewCategoryModalShow: React.Dispatch<React.SetStateAction<boolean>>,
    sandboxValue: { language: Language; hasChanged: boolean; }[],
    setOpenConfirmPopup: React.Dispatch<React.SetStateAction<boolean>>,
    resetState: () => void
) {
    switch (userCodeSnippetSidebarAction?.action) {
        case UserCodeSnippetSidebarActions.DeleteCategory: {
            setDeleteCategoryModalShow(true);
            break;
        }
        case UserCodeSnippetSidebarActions.EditDetailsCategory: {
            setEditCategoryModalShow(true);
            break;
        }
        case UserCodeSnippetSidebarActions.EditDetailsSnippet: {
            setEditSnippetModalShow(true);
            break;
        }
        case UserCodeSnippetSidebarActions.CreateNewSnippet: {
            reachedMaximumAmountOfSnippets(currentAmountOfCodeSnippets, maxAmountOfCodeSnippets) ? setMaxAmountOfSnippetReachedModalShow(true) : createCleanNewSnippet();
            break;
        }
        case UserCodeSnippetSidebarActions.Duplicate: {
            setSaveAsModalShow(true);
            break;
        }
        case UserCodeSnippetSidebarActions.DeleteSnippet: {
            setDeleteSnippetModalShow(true);
            break;
        }
        case UserCodeSnippetSidebarActions.CreateNewCategory: {
            setCreateNewCategoryModalShow(true);
            break;
        }
        case UserCodeSnippetSidebarActions.Navigate: {
            const sandboxValueHasChanged = sandboxValue.some(({ hasChanged }) => hasChanged);
            if (sandboxValueHasChanged) {
                setOpenConfirmPopup(true);
            } else {
                if (!window?.location
                    || (
                        // Widgets -> Widgets
                        (decodeURI(window.location.pathname) !== userCodeSnippetSidebarAction.item?.url)
                        // Widgets -> Sandbox
                        && (decodeURI(window.location.search) !== userCodeSnippetSidebarAction.item?.url)
                    )
                ) {
                    resetState();
                    navigate(userCodeSnippetSidebarAction.item?.url, { state: userCodeSnippetSidebarAction.item?.state });
                }
            }
            break;
        }
    }
}

function saveCode(markdown: any, javascript: any, styles: any, transformedCommentToCode: string[], editComment: (editedStringifiedCode: string, refetchQueries?: boolean) => void) {
    if (transformedCommentToCode) {
        const editedStringifiedCode = CodeStringifierService(
            {
                originalmarkdown: markdown,
                originaljavascript: javascript,
                originalstyles: styles,
                category: transformedCommentToCode[SavedCodeProperties.CategoryName],
                codeType: ModalActionType[transformedCommentToCode[SavedCodeProperties.SaveType]],
                name: transformedCommentToCode[SavedCodeProperties.SnippetName],
                owner: transformedCommentToCode[SavedCodeProperties.Owner],
            }
        ).getStrigifiedCodeSnippet();
        editComment(editedStringifiedCode);
    }

}

function createCreateCategoryModal(createNewCategoryModalShow: boolean, setCreateNewCategoryModalShow: React.Dispatch<React.SetStateAction<boolean>>, shareCode: (categoryName: string, actionType: ModalActionType, snippetName: string, modalType: ModalType) => void, props: any) {
    return useMemo(() => {
        return (
            <ModalTrigger
                id={2}
                modalShow={createNewCategoryModalShow}
                setModalShow={setCreateNewCategoryModalShow}
                modalType={ModalType.CreateCategoryFromSidebar}
                noButton
                headerIconBackground={defaultTheme.default.colors.buttons.run.backgroundColor}
                headerIconColor={defaultTheme.default.colors.buttons.run.textColor}
                headerIconSrc='/icons/folder-box.svg'
                headerTitle='New category'
                shareCode={shareCode}
                sidebarMenuItemsCode={props.sidebarMenuItemsCode} />
        );
    }, [createNewCategoryModalShow]);
}

function createDeleteCategoryModal(shareCode: (categoryName: string, actionType: ModalActionType, snippetName: string, modalType: ModalType) => void, setDeleteCategoryModalShow: React.Dispatch<React.SetStateAction<boolean>>, deleteCategoryModalShow: boolean, updatingCommentLoading: boolean, codeSnippetLoading: any, props: any) {
    return useMemo(() => (
        <ModalTrigger
            modalType={ModalType.DeleteCategory}
            noButton
            shareCode={shareCode}
            setModalShow={setDeleteCategoryModalShow}
            modalShow={deleteCategoryModalShow}
            loading={updatingCommentLoading || codeSnippetLoading}
            id={Math.floor(100000 + Math.random() * 900000)}
            location={props.location}
            headerTitle="Delete category"
            headerIconSrc="/icons/warning-triangle.svg"
            headerIconBackground={defaultTheme.default.colors.buttons.delete.backgroundColor}
            headerIconColor={defaultTheme.default.colors.buttons.delete.textColor} />
    ), [deleteCategoryModalShow]);
}
