import styled from 'styled-components'

export const SearchBar = styled('input')<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>`
    width: 90%;
    margin: auto;
    height: 35px;
    border-radius: 3px;
    padding: 6px;
    border: 1px solid ${props => props.theme.colors.alto};
    box-shadow: 0px 0px 10px -4px ${props => props.theme.colors.alto};

`
export const SearchBarWrapper = styled('div')<HTMLDivElement>`
    top: 0;
    z-index: 999;
    background-color: white;
    display: flex;
    justify-content: center;
    padding: 8px 5px;
`