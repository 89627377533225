import { gql } from "apollo-boost";

export const querySidebarMenuItems = gql`
query QueryAllMenus($id: ID!) {
  menuItem(id: $id) {
    id
    childItems(first:35) {
      nodes {
        childItems(first:35) {
          nodes {
            childItems(first:35)  {
              nodes {
                id
                label
                path
                connectedNode {
                  node {
                    id
                  }
                }
              }
            }
            connectedNode {
              node {
                id
              }
            }
            id
            label
            path
          }
        }
        connectedNode {
          node {
            id
          }
        }
        id
        label
        path
      }
    }
    connectedNode {
      node {
        id
      }
    }
    path
    label
  }
}
`
