import React from "react";
// import { makeStyles } from '@material-ui/core';

const styles = {
    fullSpace: {
        height: 'calc(100% + 124px)',
        margin: '-62px',
    }
};

const firstWTKTypedocVersion = [3, 11, 13];

export default function TypeDocTemplate(props) {
    const { wtkReleaseNotesData, sdkReleaseNotesData } = props;
    const releaseNotes = wtkReleaseNotesData?.nodes[0]?.acfReleaseNotes?.releaseNotes;
    // Currently get only the latest version of wtk-sdk
    // TODO: version should be selectable
    const availableVersions: string[] = releaseNotes?.filter((releaseNote) => {
        const versionParts = releaseNote.version?.split('.');
        return (
            (+versionParts[0] > +firstWTKTypedocVersion[0])
            || (
                (+versionParts[0] === +firstWTKTypedocVersion[0])
                && (+versionParts[1] > +firstWTKTypedocVersion[1])
            )
            || (
                (+versionParts[0] === +firstWTKTypedocVersion[0])
                && (+versionParts[1] === +firstWTKTypedocVersion[1])
                && (+versionParts[2] >= +firstWTKTypedocVersion[2])
            )
        );
    }).map((releaseNote) => releaseNote.version);
    const version = `${availableVersions?.[0]}`;
    const type = window.location.pathname.split('/')[2] ?? 'getting-started';
    const typeDocUrl = {
        'getting-started': `https://wtk.infrontservices.com/docs/${version}/`,
        'wtk-reference': `https://wtk.infrontservices.com/docs/${version}/modules/Infront_WTK.default.html`,
        'sdk-reference': `https://wtk.infrontservices.com/docs/${version}/modules/Infront_SDK.default.html`,
        'util-reference': `https://wtk.infrontservices.com/docs/${version}/modules/Util.default.html`,
    };
    return (
        <div style={styles.fullSpace}>
            <iframe
                title="WTK Documentation"
                src={typeDocUrl[type]}
                width="100%"
                height="100%"
                frameBorder="0"
            ></iframe>
        </div>
    )
}
