import { setTimeout } from "timers";
import typescript from "typescript";
import { COMPILER_OPTIONS } from "../../../constants/monaco-editor";
import { getSandboxMarkdown } from "../../../services/sandboxMarkdownCreator";
import { SandboxValue } from "../../../types/SandboxValue";

type CodeExecutorServiceProps = {
    iframeRef: React.MutableRefObject<HTMLIFrameElement>
    toolkitVersion: string
    javascript: SandboxValue
    markdown: SandboxValue
    styles: SandboxValue
    isTerminalTheme: boolean
    token: any
    localToolkitVersionPort: string
    loginUrl: string
    isAnalytics: boolean
    isSdkExample: boolean
    isMobileExample: boolean
    isNewCodeExample: boolean
    wtkLanguage: string
}


export const CodeExecutorService = ({iframeRef, markdown, styles, javascript, toolkitVersion, isTerminalTheme, token, loginUrl, localToolkitVersionPort, isAnalytics, isSdkExample, isMobileExample, isNewCodeExample, wtkLanguage: language}: CodeExecutorServiceProps) => {

    const executeCode = () => {
        try {
            if(iframeRef.current && iframeRef.current.contentWindow && iframeRef.current.contentDocument && javascript?.newValue) {
                
                const doc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
                iframeRef.current.contentWindow['Highcharts'] && delete iframeRef.current.contentWindow['Highcharts']
                iframeRef.current.contentWindow['sdk'] = window.sdk

                const html = markdown
                const css = styles
                const js = javascript

                const result = getSandboxMarkdown(  typescript.transpile(js?.newValue, COMPILER_OPTIONS), html?.newValue, css?.newValue, toolkitVersion, isTerminalTheme, token, loginUrl, localToolkitVersionPort, isAnalytics, isSdkExample, isMobileExample, isNewCodeExample, language)

                try {
                    if(doc.getElementById('exscript')?.innerHTML === JSON.stringify(javascript?.newValue)) return
                    else {
                        doc.open()
                        doc.writeln(result)
                        doc.close()
    
                    }

                } catch (error) {
                    console.log(error)
                }
               
            }
        } catch (error) {
            console.log(error)
        }
    }
    return {executeCode}
}

