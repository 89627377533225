import _ from "lodash";
import { useLayoutEffect } from "react"

type useHighlightSidebarOnScrollProps = {
    scrollContainer: string
    sidebarContainer: string
}
export const useHighlightSidebarOnScroll = ({scrollContainer, sidebarContainer}: useHighlightSidebarOnScrollProps) => {
    function between(x, min, max) {
        return x >= min && x <= max;
    }
    useLayoutEffect(() => {
        const handleScroll = (ev: Event) => {
            const anchorElements = document.getElementById(scrollContainer) && document.getElementById(scrollContainer)?.children
            if(anchorElements) {
                const arrayFromAnchorElements = Array.prototype.slice.call(anchorElements)
                const anchoredeSidebarElement = document.getElementById(sidebarContainer)    
                const eventTarget = ev.target as HTMLElement
                const targetedAnchorElement = arrayFromAnchorElements.filter((element: HTMLElement) => {
                    return between(eventTarget.scrollTop, element.offsetTop, element.offsetTop + element.scrollHeight - 10)
                })[0]
    
                if(anchoredeSidebarElement) {
                    const anchoredSidebarItems = Array.prototype.slice.call(document.getElementById(sidebarContainer)?.children)
                    const targetedAnchorSidebarItem = anchoredSidebarItems.map((item: HTMLElement) => {
                        if((item?.firstElementChild as HTMLElement)?.innerText?.toLowerCase()?.replace(/\s/g, '') === targetedAnchorElement?.id?.toLowerCase()?.replace(/\s/g, '')) {
                            return item?.firstElementChild
                        } else {
                            item?.firstElementChild.classList.remove('Mui-selected')
                        }
                    }).filter(e => e)
                    targetedAnchorSidebarItem[0]?.classList.add('Mui-selected')
                }
            }

        }
        document.getElementById('content-template')?.addEventListener('scroll', _.debounce((ev) => handleScroll(ev), 100))
        return  document.getElementById('content-template')?.removeEventListener('scroll', _.debounce((ev) => handleScroll(ev), 100))
    }, [])
}
