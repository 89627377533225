import { LazyQueryHookOptions, QueryTuple, useLazyQuery } from "@apollo/client";
import { OperationVariables } from "apollo-boost";
import { DocumentNode } from "graphql";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useLazySwrQuery<TData = any, TVariables = OperationVariables>(
    query: DocumentNode,
    options?: LazyQueryHookOptions<TData, TVariables>
  ): QueryTuple<TData, TVariables> {
    const [fetch, result] = useLazyQuery(query, options);
  
    let inCache = true;
    if (result.loading) {
      try {
        result.client.readQuery({
          query: query,
          variables: result.variables
        });
      } catch (error) {
        inCache = false;
      }
    }
    result.loading = !inCache;
    return [fetch, result];
  }