
export enum UserCodeSnippetSidebarActions {
    Share,
    Duplicate,
    EditDetailsCategory,
    EditDetailsSnippet,
    DeleteCategory,
    DeleteSnippet,
    CreateNewCategory,
    CreateNewSnippet,
    Navigate
  }