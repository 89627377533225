import ReactHtmlParser from 'react-html-parser'; 

export const matchText = (originalText, searchText) => ReactHtmlParser(originalText.replace(new RegExp(searchText, "gi"), (match) => `<mark>${match}</mark>`));
export const prettyRoute = (route) => route && route.charAt(0).toUpperCase() + route.split('-').join(' ').substring(1);
export const capitalizeFirstLetters = (str = "") => str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');


export function chunk(arr, chunkSize) {
    var R = [];
    for (var i=0,len=arr.length; i<len; i+=chunkSize)
      R.push(arr.slice(i,i+chunkSize));
    return R;
}
