import React, { useEffect, useRef, useState }  from "react"
import 'highlight.js/styles/vs2015.css';      // import your preferred style
import * as objTraverse from 'obj-traverse/lib/obj-traverse'
import { navigate } from "gatsby";
import Masonry from 'react-masonry-component';
import ReactHtmlParser from 'react-html-parser'; 
import { Highlight } from 'react-fast-highlight';
import { useGlobal } from "reactn";
import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {materialDark} from 'react-syntax-highlighter/dist/esm/styles/prism'
import { getBackendUrl } from "../utils/utils";
import { StyledDropdown, DropdownButton, StyledDropdownMenu, StyledDropdownMenuItemsWrapper, StyledDropdownItem, MenuItemTextWrapper, Label } from "./sandbox/styledComponents/layoutButton";
import { SdkDocumentationMetaData } from "../components/sdkDocumentationMetaData";
const listOfTypes = [
    "boolean",
    "number",
    "string",
    "bigint",
    "symbol",
    "Object", 
    "Array",
    "Map", 
    "Set", 
    "WeakMap", 
    "WeakSet", 
    "Date",
    "void"
]

export default function SDKTemplate(props) {
    const {documentation, location: {state}} = props
    const parsedDocumentation = documentation
    const [sdkDocumentation, setSkdDocumentation] = useState(undefined)
    const masonryOptions = {
        transitionDuration: 0
    };
    const imagesLoadedOptions = { background: '.my-bg-image-el' }
    const router = typeof window !== 'undefined' && window?.location?.pathname?.split('/')?.filter(e => e?.length)
    const [allSdkRouteItems, setAllSdkRouteItems] = useGlobal('allSdkRouteItems')
    
    useEffect(() => {
        if(parsedDocumentation) {
            if(state?.id) {
                setSkdDocumentation(objTraverse?.findFirst(parsedDocumentation, 'children',  {id: state?.id}))
            } else if(state?.name) {
                setSkdDocumentation(objTraverse?.findFirst(parsedDocumentation, 'children',  {name: state?.name}))
            } else if(router[router?.length -1]?.length) {
                setSkdDocumentation(objTraverse?.findFirst(parsedDocumentation, 'children',  {id: parseInt(router[router?.length -1])}))
            }    
        }
    }, [state, parsedDocumentation])
    const textWithLink = sdkDocumentation?.comment && (sdkDocumentation?.comment?.shortText as string)
        ?.replace(/(\[(?:\[))/g, `<a>`)
        ?.replace(/(\](?:\]))/g, '</a>')
    const handleOnTagLinkClicked = (tagName: string, bypassInheritedFrom?: boolean) => {
        if(tagName.split('.').length > 0) tagName = tagName.split('.')[0]
        const docFromTagLink = objTraverse?.findFirst(parsedDocumentation, 'children',  {name: tagName.replace(/[^a-zA-Z ]/g, "")})


        if(docFromTagLink) {
            const sdk = objTraverse?.findFirst(parsedDocumentation, 'children',  {name: docFromTagLink?.name})
           
            const pathToNavigate = allSdkRouteItems?.find(data => data?.parent === sdk?.name)
            if(pathToNavigate) {
                const pathFromInheritedParent = findInheritedParents(sdk, allSdkRouteItems)
                if(pathFromInheritedParent && pathFromInheritedParent[0] && bypassInheritedFrom) {
                    navigate('/' + pathFromInheritedParent[0]?.slug, {state: {id: pathFromInheritedParent[0]?.id}})
                } else {
                    navigate('/' + pathToNavigate?.slug, {state: {id: pathToNavigate?.id}})
                }
            }

        }
    }


    const getParametersForFunctionSignature = (signatures: any[], allSdkRouteItems: any[]) => {
        return signatures?.map(signature => 
            `${signature?.name}${signature?.typeParameter ? '<' + signature?.typeParameter?.map(typeParam => typeParam?.name)?.join(', ') + '>' : ''}` + ' ('+ (
                signature?.parameters ? signature?.parameters?.map(parameter => {
                    if(parameter?.type?.declaration?.signatures) {
                        return (
                            `${findInheritedParents( parameter?.name, allSdkRouteItems) && findInheritedParents( parameter?.name, allSdkRouteItems)[0]
                                ? (`${<a onClick={() => handleOnTagLinkClicked(findInheritedParents( parameter?.name, allSdkRouteItems)[0])}>{findInheritedParents( parameter?.name, allSdkRouteItems)[0]}</a>}`) : 
                                (parameter?.name)}: (${parameter?.type?.declaration?.signatures?.map(signature => {
                                return signature?.parameters?.map(param => {
                                    return `${findInheritedParents( param?.name, allSdkRouteItems) && findInheritedParents( parameter?.name, allSdkRouteItems)[0]
                                        ? (`${<a onClick={() => handleOnTagLinkClicked(findInheritedParents( param?.name, allSdkRouteItems)[0])}>{findInheritedParents( param?.name, allSdkRouteItems)[0]}</a>}`) : 
                                        (param?.name)}: ${param?.type?.name}`
                                } )
                            })?.join(' , ')}) => ${parameter?.type?.declaration?.signatures?.map(signature => signature?.type?.name)?.join(' | ')}`
                        )
                    } else if(parameter?.type?.type === 'union') {
                        return parameter?.type?.types?.map(type => {
                            if(!type?.typeArguments) {
                                if(findInheritedParents(type?.elementType?.name || type?.name, allSdkRouteItems) && findInheritedParents(type?.elementType?.name || type?.name, allSdkRouteItems)[0]) {
                                    return `${<a onClick={() => handleOnTagLinkClicked(findInheritedParents(type?.elementType?.name || type?.name, allSdkRouteItems)[0])}>{findInheritedParents( type?.elementType?.name || type?.name, allSdkRouteItems)[0]}{getStringifiedType(type?.type)}</a>}`
                                } else return `${type?.elementType?.name || type?.name} ${getStringifiedType(type?.type)}`
                            } else {
                                return `${type?.elementType?.name || type?.name} <${type?.typeArguments?.map(arg => arg?.name)?.join('|')}>`
                            }

                        })?.join(' | ')
                    } else {
                        if(findInheritedParents(parameter?.name, allSdkRouteItems) && findInheritedParents(parameter?.name, allSdkRouteItems)[0]) {
                            return `${<a onClick={() => handleOnTagLinkClicked(findInheritedParents(parameter?.name, allSdkRouteItems)[0])}>{findInheritedParents( parameter?.name, allSdkRouteItems)[0]}</a>}`
                        } else return `${parameter?.name}: ${parameter?.type?.name}`
                    }
                
                })?.join(' , ') : ''
        )) + `) ${signatures[0]?.type?.name !== "void" ? ': ' : '=> ' }`
    }

    const getTextWithLink = (text: string) => {
        return text?.split('|')[0]?.replace(/(\[(?:\[))/g, `<a>`)?.replace(/(\](?:\]))/g, '</a>')
    }
    const convertTextToLink = (text: string) => {
        return text?.split(' ').map(txt => {
            if(txt.indexOf(':') > -1) return txt
            const routeItem = allSdkRouteItems?.find(data => data?.parent === txt.replace(/[^a-zA-Z ]/g, ""))

            if(routeItem) {
                return `<a>${txt}</a>`
            } else return txt
        }).join(' ')
    }
    const renderers = {
        code: ({language, value}) => {
          return <SyntaxHighlighter style={materialDark} language={language} children={value} />
        }
      }
      
    const OptionTag = ({tags, allSdkRouteItems}) => {
        return (
            <div>
                {tags?.map(tag => {
                    if(tag?.tag?.replace(/[^a-zA-Z ]/g, "") === "example") {
                        return (
                            <div className="example my-1">
                                <ReactMarkdown renderers={renderers} children={tag?.text} />
                            </div>
                        )
                    }
                    if(tag?.tag === 'see' || tag?.tag === 'remarks' ||  tag?.tag === 'remark') {
                        return(
                            <div className="my-2">
                                 <span className='options__tag'>{tag?.tag}</span>
                                 {ReactHtmlParser(getTextWithLink(tag?.text), {
                                    transform: node => {
                                        if(node?.children) {
                                            return node?.children?.map(child => (
                                                <a style={{color: '#1890ff'}} onClick={() => handleOnTagLinkClicked(child?.data)}>{child?.data}</a>
                                            ))
                                        }
                                    }
                                })}
                            </div>
                        )
                    }
                })}
            </div>
        )
    }
    

    const OptionGetType = ({option, allSdkRouteItems}) => {
        let stringifiedElements = option?.getSignature?.map(signature => {
            const pathToNavigate = allSdkRouteItems.find(route => route?.parent === signature?.type?.name)
            if(pathToNavigate) return `<a>${signature?.type?.name}</a>`
            else return signature?.type?.name
        }).join(' | ')
        return (
            <div>
                <b>get </b>
                <span>
                    {`${option?.name}(): `}
                    {ReactHtmlParser(stringifiedElements, {
                        transform: node => {
                            if(node?.children) {
                                return node?.children?.map(child => (
                                    <a style={{color: '#1890ff'}} onClick={() => handleOnTagLinkClicked(child?.data, true)}>{child?.data}</a>
                                ))
                            }
                        }
                    })}
                </span>
                
            </div>
        )
    }

    const DataInfo = ({option, index, allSdkRouteItems}) => {
        const { kindString, description} = option;
        let pathToNavigate = allSdkRouteItems.find(route => route?.parent?.trim()?.toLowerCase() === option?.name?.trim()?.toLowerCase())
        //if(!option?.children) pathToNavigate = null
        return (
            <div key={index} className="options__item col-lg-6 col-md-12 col-sm-12 col-xs-12">

                <h3 className="options__heading" style={{color: (pathToNavigate && '#1890ff'), cursor: (pathToNavigate && 'pointer')}} onClick={() => {
                   if(pathToNavigate) {
                       navigate('/' + pathToNavigate?.slug, {state: {id: pathToNavigate?.id}})
                   }
                }}>{option?.name}</h3>
                {kindString && <span key={Math?.random()} className="options__label">{kindString}</span>}
                {option?.flags?.isOptional && <span key={Math?.random()} className="options__label">Optional</span>}
                <div className="options__inner">
                    {(option?.type || option?.signatures) && (
                        <OptionType option={option} allSdkRouteItems={allSdkRouteItems}></OptionType>
                    )}
                    {(option?.getSignature) && (
                        <OptionGetType option={option} allSdkRouteItems={allSdkRouteItems}></OptionGetType>
                    )}
                    {(option?.comment?.shortText || (option?.signatures && option?.signatures?.find(signature => signature?.comment))) && (
                        <span className="options__property"><b>Description: </b>{ReactHtmlParser(getTextWithLink( option?.comment?.shortText ?? (option?.signatures && option?.signatures[0]?.comment?.shortText) ), {
                            transform: node => {
                                if(node?.children) {
                                    return node?.children?.map(child => (
                                        <a style={{color: '#1890ff'}} onClick={() => handleOnTagLinkClicked(child?.data)}>{child?.data}</a>
                                    ))
                                }
                            }
                        })}
                        </span>
                    )}
                    {(option?.comment?.text && (
                        <span className="options__property">{ReactHtmlParser(getTextWithLink( option?.comment?.text), {
                            transform: node => {
                                if(node?.children) {
                                    return node?.children?.map(child => (
                                        <a style={{color: '#1890ff'}} onClick={() => handleOnTagLinkClicked(child?.data, true)}>{child?.data}</a>
                                    ))
                                }
                            }
                        })}
                        </span>
                    ))}
                    {(option?.comment?.tags && (
                        <div className="options__property"> <OptionTag tags={option?.comment?.tags} allSdkRouteItems={allSdkRouteItems} ></OptionTag></div>
                    ))}

                    {option?.defaultValue && <span className="options__property"><b>Default: </b>{option?.defaultValue}</span>}
                    {option?.signatures && <span className="options__property"><b>Usage: </b>{
                        `${getParametersForFunctionSignature(option?.signatures, allSdkRouteItems) } ${getReturnTypes(option?.signatures)}`                        
                    }</span>}

                    {option?.signatures && option?.signatures[0]?.comment?.tags?.map((tag, index) => (
                        <>
                            <span key={index} className="options__property">
                                <span className="options__tag">{tag?.tag}</span>
                                <span>{ReactHtmlParser(getTextWithLink(tag?.text), {
                                    transform: node => {
                                        if(node?.children) {
                                            return node?.children?.map(child => (
                                                <a style={{color: '#1890ff'}} onClick={() => handleOnTagLinkClicked(child?.data, true)}>{child?.data}</a>
                                            ))
                                        }
                                    }
                                })}
                                </span>
                            </span>

                        </>
                    ))}
                    {option?.signatures && option?.signatures[0]?.parameters && (
                        <div  className="options__property">
                            <h5 className="options__subheading">Parameters</h5>
                            <ParameterList option={option} allSdkRouteItems={allSdkRouteItems} parameters={option?.signatures[0]?.parameters}></ParameterList>
                        </div>
                    )}
                    {option?.inheritedFrom && (
                        <div  className="options__property">
                            <span className="options__property">
                                <b>Inherited from: </b>
                            <a className="options__property__navigate" onClick={() => {
                                const pathToNavigate = findInheritedParents(option, allSdkRouteItems)[0]

                                if(pathToNavigate) {
                                    navigate('/' + pathToNavigate?.slug, {state: {id: pathToNavigate?.id}})
                                }

                            }}>
                                {option?.inheritedFrom?.name}
                            </a>
                            </span>
                        </div>
                    )}
                    {description && ReactHtmlParser(description)}
                </div>
            </div>
        );
    }

    const ExtendedBy = ({extendedBy, allSdkRouteItems}) => {
        return(
            <div className='container-fluid'>
                <div className={extendedBy?.length <= 6 ? 'column' : 'row'}>
                    {
                        extendedBy?.map(extended => {
                            const isNavigationLink = allSdkRouteItems?.find(data => data?.parent === extended?.name)
                            return isNavigationLink && !listOfTypes?.includes(extended?.name) ? (
                                <div className='col p-0 col-2'>
                                    <a style={{color: '#1890ff'}} onClick={() => {navigate('/' + isNavigationLink?.slug, {state: {id: isNavigationLink?.id}})}}>
                                        {extended?.name}
                                    </a>
                                </div>

                            ) : (
                                <div className='col p-0 col-2'>
                                    <span>
                                        {extended?.name}
                                    </span>
                                </div>
                            )
                        })
                    }
                </div>
                
            </div>
        )
    }

    const getFunctionSignature = (parameter) => {
        if(parameter?.type?.declaration?.signatures) {
            return (
                `(${parameter?.type?.declaration?.signatures?.map(signature => {
                    return signature?.parameters?.map(param => {

                        return `${param?.name}: ${param?.type?.name}`
                    } )
                })?.join(' , ')}) => ${parameter?.type?.declaration?.signatures?.map(signature => signature?.type?.name)?.join(' | ')}`
            )
        } else if(parameter?.type?.elementType) {
            return getTypeTextFromElementType(parameter?.type?.elementType?.name, parameter?.type?.type, null)
        } else return parameter?.type?.name
    }
    const ParameterList = ({parameters, option, allSdkRouteItems}) => {
        return(
            <ul>
                {parameters?.map(param => {
                    const pathToNavigate = findInheritedParents(param, allSdkRouteItems)[0]
                    return(
                        <li>
                            {pathToNavigate && pathToNavigate?.children && !listOfTypes?.includes(param?.type?.name ?? (param?.signatures && param?.signatures[0]?.type?.name)) ? (
                                <>
                                    <div>
                                        <b>{param?.name}: </b> 
                                        <a className="options__property__navigate"  onClick={() => navigate('/' + pathToNavigate?.slug, {state: {id: pathToNavigate?.id}})}>
                                            {getFunctionSignature(param)}
                                        </a>
                                    </div>
                                    <div>{param?.comment?.text}</div>
                                </>
                            ) : (
                                <>
                                    <div><b>{param?.name}: </b><i>{getFunctionSignature(param)}</i></div>
                                    <div>{param?.comment?.text}</div>
                                </>

                            )}

                        </li>
                    )
                }

                )}
            </ul>
        )
    }

    const getStringifiedType = (type: string) => {
        const routeToType = findInheritedParents(type, allSdkRouteItems)[0]
        if(routeToType) return `${<a onClick={() => handleOnTagLinkClicked(routeToType)}>{type}</a>}`
        switch(type) {
            case 'array': return '[]';
            case 'object': return '{}';
            case 'reference': return '';
            default: return type;
        }
    }


    const getReturnTypes = (signatures: any[]) => {
        const returnTypes = signatures?.map(signature => {
            if(signature?.type?.checkType) {
                let output = ''
                output += signature?.type?.checkType?.name
                if(signature?.type?.extendsType) {
                    output += ` extends ${ signature?.type?.extendsType?.name}`
                }
                output += ` ? ${signature?.type?.trueType?.name}`
                output += ` : ${signature?.type?.falseType?.name}`
                return output
            }
            if(signature?.type?.elementType) {
                return signature?.type?.elementType?.name
            }
            if(signature?.type?.objectType && signature?.type?.indexType) {
                return signature?.type?.objectType?.name + '[' + signature?.type?.indexType?.name + ']'
            }
            return  signature?.type?.name
        })
        return [... new Set(returnTypes)]?.length ? [... new Set(returnTypes)]?.join(' | ') : (returnTypes &&  returnTypes[0])
    }

    const findInheritedParents = (sdk, sdkRouteItems) => {
        let referenceParent = ''
        if(sdk?.inheritedFrom?.name?.split('.')?.length) {
            referenceParent = sdk?.inheritedFrom?.name?.split('.')[0]
        } else if(sdk?.type?.name) {
            referenceParent = sdk?.type?.name
        } else if(sdk?.signatures && sdk?.signatures[0]?.type) {
            referenceParent =  sdk?.signatures[0]?.type?.name
        }
        else if(sdk?.type && sdk?.type?.elementType) {
            referenceParent =  sdk?.type?.elementType?.name
        } else if(sdk?.type?.types) {
            return sdk?.type?.types?.map(type => {
                return sdkRouteItems?.find(data => data?.parent === (type?.name || type?.elementType?.name)) ?? type?.name ?? type?.elementType?.name
            })
        }
        return [sdkRouteItems?.find(data => data?.parent === referenceParent) ?? referenceParent]
    }

    const getTypeTextFromElementType = (text, type, typeArguments) => {
        if(type !== 'conditional' && type !== 'union' && !text) return
        switch(type) {
            case "array": return text + '[]';
            case "reference": return text + '<' + typeArguments?.map(argument => argument?.name)?.join(', ') + '>';
            case "conditional" : return typeArguments?.falseType?.name + ' | ' + typeArguments?.trueType?.name;
            case "union": return typeArguments?.map(type => (type?.elementType?.name || type?.name) + getStringifiedType(type?.type))?.join(' | ')
        }
    }

    const OptionType = ({option, allSdkRouteItems}) => {
        const pathsToNavigate = findInheritedParents(option, allSdkRouteItems)
        const pathToNavigate = pathsToNavigate[0]
        return(
            <>
                <b>{option?.kindString?.toLowerCase() === 'method' ? 'Return type: ' : 'Type: '} </b>
                {option?.kindString?.toLowerCase() === 'method' ?  
                    (<ReturnTypeFromString  option={option} allSdkRouteItems = {allSdkRouteItems}></ReturnTypeFromString>) : 
                    (<ReferenceTypeFromString option={option} allSdkRouteItems = {allSdkRouteItems} ></ReferenceTypeFromString>)
                }
            </>
        )

        
    }

    const ReturnTypeFromString   = ({option, allSdkRouteItems}) => {
        
        const elements = [... new Set(option?.signatures?.map(signature => {
            const pathToNavigate = allSdkRouteItems.find(route => route?.parent?.search(signature?.name) > -1)
            return pathToNavigate ? `<a>${signature?.name}</a>` : signature?.name
        }))].join(' | ')
        
        return (
            <>
                {ReactHtmlParser(elements, {
                   transform: node => {
                       if(node?.children) {
                           return node?.children?.map(child => (
                               <a style={{color: '#1890ff'}} onClick={() => handleOnTagLinkClicked(child?.data, true)}>{child?.data}</a>
                           ))}
                       }
                   })
                }
            </>
        )
    }
    const ReferenceTypeFromString = ({option, allSdkRouteItems}) => {
        if(option?.type?.typeArguments || option?.type?.types) {
            const args = option?.type?.typeArguments || option?.type?.types

            return (
                <>
                    {option?.type?.name ?? option?.name}
                    {' <'}
                    {args?.map((typeArgument, index) => {
                            const type = option?.type?.types ? option?.type?.type :  typeArgument?.type
                            switch(type) {
                                case 'union': {
                                    let stringifyedHtmlElement = ''
                                    let pathToNavigate = {}
                                    if(typeArgument?.types) {
                                        stringifyedHtmlElement = typeArgument?.types?.map(type => {
                                            pathToNavigate = allSdkRouteItems.find(route => route?.parent === (type?.name || type?.elementType?.name))
                                            const text = type?.name || type?.elementType?.name + getStringifiedType(type?.type || type?.elementType?.type)
                                            
                                            if(pathToNavigate) {
                                                return `<a>${text}</a>`
                                            } else return `${text}`
                                        }).join(' | ')
                                    } else {
                                        pathToNavigate = allSdkRouteItems.find(route => route?.parent === (typeArgument?.name || typeArgument?.elementType?.name))
                                        const text = typeArgument?.name || typeArgument?.elementType?.name + getStringifiedType(typeArgument?.type || typeArgument?.elementType?.type)
                                        if(pathToNavigate) {
                                            stringifyedHtmlElement = `<a>${text}</a>`
                                        } else stringifyedHtmlElement = `${text}`
                                    } 
                                    return (
                                        <>
                                            {ReactHtmlParser(stringifyedHtmlElement, {
                                                transform: node => {
                                                    if(node?.children) {
                                                        return node?.children?.map(child => (
                                                            <a style={{color: '#1890ff'}} onClick={() => handleOnTagLinkClicked(child?.data, true)}>{child?.data}</a>
                                                        ))}
                                                    }
                                                })
                                            }
                                            {index !== (option?.type?.typeArguments?.length -1 || option?.type?.types?.length -1)  && (option?.type?.types ? ' | ' : ' , ')}
                                        </>
                                    )
                                        
                                }
                                default: {
                                    const pathToNavigate = allSdkRouteItems.find(route => route?.parent === (typeArgument?.name || typeArgument?.elementType?.name))
                                    const text = typeArgument?.name || typeArgument?.elementType?.name + getStringifiedType(typeArgument?.type || typeArgument?.elementType?.type)
                                    let stringifyedHtmlElement = ''
                                    if(pathToNavigate) {
                                        stringifyedHtmlElement = `<a>${text}</a>`
                                    } else stringifyedHtmlElement = `${text}`
                                    return (
                                        <>
                                            {ReactHtmlParser(stringifyedHtmlElement, {
                                                transform: node => {
                                                    if(node?.children) {
                                                        return node?.children?.map(child => (
                                                            <a style={{color: '#1890ff'}} onClick={() => handleOnTagLinkClicked(child?.data, true)}>{child?.data}</a>
                                                        ))}
                                                    }
                                                })
                                            }
                                            { (option?.type?.typeArguments?.length > 1 || option?.type?.types?.length > 1) && index !== (option?.type?.typeArguments?.length -1 || option?.type?.types?.length -1)  && (option?.type?.types ? ' | ' : ' , ')}
                                        </>
                                    )
                                }
                            }
                        })
                    }
                    {'>'}
                </>
            ) 
        } else {
            return <span>{option?.type?.name || option?.type?.elementType?.name || '{}'}</span>
        }

    }
    return (
        <div className="widget">
            <div className="container-fluid my-3">
                <div className="d-flex justify-content-between align-items-center py-4">
                    <div className="d-flex align-items-center">
                        <h1 className="m-0">{sdkDocumentation?.name === ('InfrontSDK' || 'Infront') ? sdkDocumentation?.name + '/' + props?.router[2] : sdkDocumentation?.name}</h1>
                        <h1 className="options__label mx-4 my-0">{sdkDocumentation?.kindString}</h1>
                    </div>
                    <SdkDocumentationMetaData></SdkDocumentationMetaData>
                </div>


                {(sdkDocumentation?.comment?.shortText || sdkDocumentation?.comment?.text )&& <div><b>Description: </b>{ReactHtmlParser(textWithLink, {
                    transform: node => {
                        if(node?.children) {
                            return node?.children?.map(child => (
                                <a style={{color: '#1890ff'}} onClick={() => handleOnTagLinkClicked(child?.data)}>{child?.data}</a>
                            ))
                        }
                    }
                })}

                </div>
            }
                {sdkDocumentation?.comment?.tags &&<div> <OptionTag tags={sdkDocumentation?.comment?.tags} allSdkRouteItems={allSdkRouteItems} ></OptionTag></div>}
            </div>
            <div className="container-fluid my-3">
                {sdkDocumentation?.extendedBy && (
                    <span>
                        <b>Extended by: </b>
                        <div>
                            <ExtendedBy extendedBy={sdkDocumentation?.extendedBy} allSdkRouteItems={allSdkRouteItems}></ExtendedBy>
                        </div>
                        
                    </span>
                )}
            </div>
            {(sdkDocumentation?.children?.length || sdkDocumentation?.signatures) &&(
                <div className="options container-fluid my-5">
                    <h2 className="ml-3">Options</h2>
                    <Masonry
                        className={'my-gallery-class'}
                        elementType={'ul'}
                        options={masonryOptions}
                        disableImagesLoaded={false}
                        updateOnEachImageLoad={false}
                        //@ts-ignore
                        imagesLoadedOptions={imagesLoadedOptions} 
                    >
                    {sdkDocumentation && sdkDocumentation?.children?.length ? 
                        sdkDocumentation?.children?.map((option, index) => 
                            <>
                                <DataInfo option={option} index={index} allSdkRouteItems={allSdkRouteItems}></DataInfo>
                            </>
                        ) : '' 
                    }
                    {sdkDocumentation && sdkDocumentation?.signatures?.length ? 
                        sdkDocumentation?.signatures?.map((option, index) => 
                            <>
                                <DataInfo option={option} index={index} allSdkRouteItems={allSdkRouteItems}></DataInfo>
                            </>
                        ) : '' 
                    }
                    </Masonry>
                </div>
            )}
            
        </div>
    )

}
