import 'highlight.js/styles/vs2015.css';

import { Link } from 'gatsby';
import React, { useMemo } from 'react';
import { Highlight } from 'react-fast-highlight';
import ReactHtmlParser from 'react-html-parser';
import Masonry from 'react-masonry-component';

export default function GettingStarted(props) {

    const {widgetData} = props
    const acfGettingStarted = widgetData?.page?.acfGettingStarted
    const getting_started_data = acfGettingStarted?.gettingStartedData
    const title = widgetData?.page?.title
    const description = ''
    let link;
    let relationship;
    const masonryOptions = {
        transitionDuration: 0
    };
    const imagesLoadedOptions = { background: '.my-bg-image-el' }
    const widgetDescription = useMemo(() => (description ? description : ''), [])

    return (
        <div className="widget">
            <h1>{title}</h1>

            { relationship && relationship.length ? <p>Extends: <Link to={link}>{relationship[0]?.post_title}</Link></p> : ''}
            { ReactHtmlParser(widgetDescription) }
            <div className="options">
            <Masonry
                className={'my-gallery-class'}
                elementType={'ul'}
                options={masonryOptions}
                disableImagesLoaded={false}
                updateOnEachImageLoad={false}
                //@ts-ignore
                imagesLoadedOptions={imagesLoadedOptions} 
            >
                {getting_started_data && getting_started_data.length ? 
                    getting_started_data.map((data, index) => {
                        const { name } = data;
                        return (
                            <div key={index} className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
                                {<h3 className="col-lg-12 mt-3" style={{fontSize: '20px'}}>{data?.header}</h3>}
                                {(data.name && data.name.length) && <h3 style={{fontSize: '20px'}} className="col-lg-12 mt-3">{name}</h3>}
                                {(data.descriptions && data.descriptions.length) && data.descriptions.map((descriptionData,index) => <div key={index} className="col-lg-12">{ ReactHtmlParser(descriptionData.description)}</div>)}
                                {data.codeExamples && 
                                    data.codeExamples.map((codeExampleData, index) => (
                                        <div key={index} className="example mt-2 col-lg-12">
                                            <Highlight
                                                languages={['js', 'html']}
                                                className="code-highlight"
                                            >
                                                {codeExampleData.codeExample}
                                            </Highlight>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }) : '' 
                }
                {widgetData?.page?.title === 'Authentication' && (
                    <div className=" mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        </div>

                    </div>
                )}


                </Masonry>

            </div>
            
        </div>
    )
}