import { ModalState } from "../../../../../types/ModalState";
import React from "react";
import { ModalActionType } from "../../../../../enums/ModalActionType";
import { StyledButton } from "../../../styledComponents/layoutButton";
import * as defaultTheme from '../../../../../theme';
import Modal from "react-bootstrap/esm/Modal";

export const SaveModalFooter = ({
    setActionButton,
    setModalShow
}: ModalState) => (
    <Modal.Footer className="d-flex justify-content-center pb-5 py-4" style={{ borderTop: 'none' }}>
        <StyledButton
            className="mx-2"
            backgroundColor={defaultTheme.default.colors.buttons.run.backgroundColor}
            color={defaultTheme.default.colors.buttons.run.textColor}
            width="175px"
            borderRadius="4px"
            onClick={() => {
                setActionButton({type: ModalActionType.Default, clicked: false})
                setModalShow(false)
            }}
        >
            Close
        </StyledButton>
    </Modal.Footer>
)