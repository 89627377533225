import React from "react"
import Layout from '../layouts/index'
import { Router, Location } from "@reach/router"
import { useGlobal } from "reactn"
import { isBrowser } from "../utils/utils"
import { graphql, navigate, StaticQuery } from "gatsby"
import { gql} from "apollo-boost";

declare global {
  interface Infront {
    Infront: typeof Infront
  }
  interface Infinancials {
    Infinancials: typeof Infinancials
  }
  interface InfrontSDK {
    InfrontSDK: typeof InfrontSDK
  }
  interface Window { 
    infront: Infront.UI;
    visual: Infinancials.UI;
    sdk: InfrontSDK.SDK;
  }
}


const App = (props) => {
  if(isBrowser) {
    const returnUrl = localStorage.getItem('originalUrl')
    if(!returnUrl) {
      if(window.location.pathname === '/') {
        //TODO: Should be removed once we get both backends in order 
        navigate('/home')
      }
    } else {
      localStorage.removeItem('originalUrl')
      navigate(returnUrl)
    }
  }
  return (
    <StaticQuery
      query={query}
      render={data => {
        const router = window.location?.pathname.split('/')
        router.shift()
        const menuItems = data?.allWpMenuItem?.nodes?.filter(menuItem => menuItem?.path?.split('/')?.length <= 3)
        const sidebarMenuItems = data?.sidebarWpMenuItem?.edges?.filter(menuItem => menuItem?.node?.url === `/${router[0]}/${router[1]}/`)
        return (
          <>
            <DynamicRouter menuItems={menuItems} sidebarMenuItems={sidebarMenuItems} />
          </>
        )
      }} 
    />
  )
}

const DynamicRouter = ({menuItems, sidebarMenuItems}) => {
  return (
    <div className="app">
        <RouterWrapper>
          <Page menuItems={menuItems} sidebarMenuItems={sidebarMenuItems} path='/*' />
        </RouterWrapper>
    </div>
  )
}

const RouterWrapper = props => (
  <Location>
    {({ location }) => (
      <Router location={location} className="router">
        {props.children}
      </Router>
    )}
  </Location>
)

const Page = props => (
  <div className="page">
    <Layout  {...props}>
        {props.page}
    </Layout>
  </div>
)


export const query = graphql`
  query MyQuery {
   allWpMenuItem: allWpMenuItem(sort: {order: ASC, fields: order}, filter: {parentDatabaseId: {eq: 0}}) {
     nodes {
       label
       databaseId
       path
       childItems {
         nodes {
           id
           label
           databaseId
           path
           connectedNode {
            node {
              id
              databaseId
              uri
            }
           }
         }
       }
       connectedNode {
         node {
           id
           databaseId
           uri
         }
       }
     }
   },
   sidebarWpMenuItem:allWpMenuItem {
    edges {
      node {
        id
        label
        childItems {
          nodes {
            label
            id
            childItems {
              nodes {
                connectedNode {
                  node {
                    id
                  }
                }
                id
                parentDatabaseId
                url
                path
                label
                childItems {
                  nodes {
                    connectedNode {
                      node {
                        databaseId
                        id
                        uri
                      }
                    }
                    id
                    parentDatabaseId
                    parentId
                    title
                    path
                    label
                  }
                }
              }
            }
            connectedNode {
              node {
                id
                uri
                databaseId
              }
            }
            path
            parentDatabaseId
          }
        }
        url
        parentDatabaseId
        connectedNode {
          node {
            databaseId
            uri
            id
          }
        }
      }
    }
  }
}
`

export default App
