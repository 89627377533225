import { ModalActionType } from "../../../enums/ModalActionType";
import { ModalType } from "../../../enums/ModalType";
import { SavedCodeProperties } from "../../../enums/savedCodeProperties";
import { UserCodeSnippetSidebarActions } from "../../../enums/UserCodeSnippetSidebarAction";
import { GetPostById } from "../../../queries/queryPost";
import { translateDatabaseShareItems, isBrowser } from "../../../utils/utils";
import { CodeStringifierService } from "./codeStringifierService";

export const handleCRUD = (
        setUserCodeSnippetSidebarAction, 
        actionType: ModalActionType, 
        markdown: any, 
        javascript: any, 
        styles: any, 
        categoryName: string | any, 
        snippetName: string, 
        loginId: number, 
        modalType: ModalType, 
        props: any, 
        userCodeSnippetSidebarAction: { action: UserCodeSnippetSidebarActions; item: any; }, 
        updatePostData, 
        updateComment, 
        deleteComment,
        updateCommentAndRefresh, 
        addComment, 
        editComment: (editedStringifiedCode: string, refetchQueries?: boolean) => void, 
        setShowSharingUrl: React.Dispatch<React.SetStateAction<boolean>>,
        resetState: () => void
    ) => {
    setUserCodeSnippetSidebarAction(null);

    let codeValues = null;
    switch (actionType) {
        case ModalActionType.SaveOnly:
            codeValues = [
                CodeStringifierService({ originalmarkdown: markdown, originaljavascript: javascript, originalstyles: styles, category: categoryName, codeType: ModalActionType.SaveOnly, name: snippetName, owner: loginId.toString() })
            ];
            break;
        case ModalActionType.SaveAndShare:
            codeValues = [
                CodeStringifierService({ originalmarkdown: markdown, originaljavascript: javascript, originalstyles: styles, category: categoryName, codeType: ModalActionType.SaveOnly, name: snippetName, owner: loginId.toString() }),
                //CodeStringifierService({ originalmarkdown, originaljavascript, originalstyles, category: 'share', codeType: ModalActionType.ShareOnly,  name: '',  owner: loginId.toString()})
            ];
            break;
        case ModalActionType.ShareOnly:
            codeValues = [CodeStringifierService({ originalmarkdown: markdown, originaljavascript: javascript, originalstyles: styles, category: 'share', codeType: ModalActionType.ShareOnly, name: '', owner: loginId.toString() })];
            break;
    }

    let updatedCategories = [];
    switch (modalType) {
        case ModalType.EditCategory: {
            updatedCategories = props?.postData?.post?.categories?.edges.map(edge => new Object({ name: edge.node?.name === categoryName?.categoryName ?  categoryName.updatedCategoryName : edge.node?.name})).filter(e => e);
            const commentsToUpdate = props?.postData?.post?.comments?.nodes?.map(comment => {
                const translatedCommentToUpdate = translateDatabaseShareItems(comment?.content);
                if (translatedCommentToUpdate[SavedCodeProperties.CategoryName] === categoryName?.categoryName) {
                    return comment;
                }
            }).filter(e => e);
            if (updatedCategories?.length)
                updatePostData({
                    variables: {
                        append: false,
                        id: props?.postData?.post?.id,
                        nodes: updatedCategories
                    },
                    optimisticResponse: {
                        append: false,
                        id: props?.postData?.post?.id,
                        nodes: updatedCategories,
                        __typename: "UpdatePostPayload"
                    }
                });
            if (commentsToUpdate)
                for (let commentToUpdate of commentsToUpdate) {
                    try {
                        if (commentToUpdate.id) {
                            const translatedCommentToUpdate = translateDatabaseShareItems(commentToUpdate?.content);
                            translatedCommentToUpdate[SavedCodeProperties.CategoryName] = categoryName.updatedCategoryName;
                            const stringifiedCommentToUpdate = CodeStringifierService({
                                originalmarkdown: translatedCommentToUpdate[SavedCodeProperties.Html],
                                originaljavascript: translatedCommentToUpdate[SavedCodeProperties.Javascript],
                                originalstyles: translatedCommentToUpdate[SavedCodeProperties.Css],
                                category: translatedCommentToUpdate[SavedCodeProperties.CategoryName],
                                codeType: ModalActionType[translatedCommentToUpdate[SavedCodeProperties.SaveType]],
                                name: translatedCommentToUpdate[SavedCodeProperties.SnippetName],
                                owner: translatedCommentToUpdate[SavedCodeProperties.Owner],
                            })?.getStrigifiedCodeSnippet();
                            updateComment({ variables: { id: commentToUpdate.id, content: stringifiedCommentToUpdate } });
                        }
                    } catch (error) {
                        console.log(error);
                    }

                }

            break;
        }
        case ModalType.DeleteCategory: {
            updatedCategories = props?.postData?.post?.categories?.edges.map(edge => (edge.node?.name !== userCodeSnippetSidebarAction?.item?.categoryName) && new Object({ name: edge.node?.name })).filter(e => e);
            const commentsToDelete = props?.postData?.post?.comments?.nodes?.map(comment => {
                const translatedCommentToUpdate = translateDatabaseShareItems(comment?.content);
                if (translatedCommentToUpdate[SavedCodeProperties.CategoryName] === userCodeSnippetSidebarAction?.item?.categoryName) {
                    return comment;
                }
            }).filter(e => e);
            if (commentsToDelete)
                for (let commentToDelete of commentsToDelete)
                    commentToDelete?.id && deleteComment({ variables: { id: commentToDelete.id } });

            updatePostData({
                variables: {
                    append: false,
                    id: props?.postData?.post?.id,
                    nodes: updatedCategories
                }
            });
            break;
        }
/*         case ModalType.DeleteSnippet: {
        }
 */
        case ModalType.EditCodeSnippet: {

            const commentToUpdate = props?.postData?.post?.comments?.nodes?.find(comment => {
                const translatedCommentToUpdate = translateDatabaseShareItems(comment?.content);
                if (translatedCommentToUpdate[SavedCodeProperties.CategoryName] === userCodeSnippetSidebarAction?.item?.categoryName && translatedCommentToUpdate[SavedCodeProperties.SnippetName] === userCodeSnippetSidebarAction?.item?.snippetName) {
                    return comment;
                }
            });

            const translatedCommentToUpdate = translateDatabaseShareItems(commentToUpdate?.content);
            if (translatedCommentToUpdate) {
                translatedCommentToUpdate[SavedCodeProperties.SnippetName] = snippetName;
                const stringifiedCommentToUpdate = CodeStringifierService({
                    originalmarkdown: translatedCommentToUpdate[SavedCodeProperties.Html],
                    originaljavascript: translatedCommentToUpdate[SavedCodeProperties.Javascript],
                    originalstyles: translatedCommentToUpdate[SavedCodeProperties.Css],
                    category: translatedCommentToUpdate[SavedCodeProperties.CategoryName],
                    codeType: ModalActionType[translatedCommentToUpdate[SavedCodeProperties.SaveType]],
                    name: translatedCommentToUpdate[SavedCodeProperties.SnippetName],
                    owner: translatedCommentToUpdate[SavedCodeProperties.Owner],
                })?.getStrigifiedCodeSnippet();
                updateCommentAndRefresh(
                    {
                        variables: {
                            content: stringifiedCommentToUpdate,
                            id: userCodeSnippetSidebarAction?.item?.id
                        },
                        optimisticResponse: {
                            content: stringifiedCommentToUpdate,
                            id: userCodeSnippetSidebarAction?.item?.id,
                            __typename:'UpdateCommentPayload'
                        }
                    }
                );
                const existingCategory = props?.postData?.post?.categories?.edges.find(edge => (edge.node?.name === categoryName));
                if (!existingCategory) {
                    updatePostData({
                        variables: {
                            append: true,
                            id: props?.postData?.post?.id,
                            nodes: [{ name: categoryName }]
                        },
                        optimisticResponse: {
                            append: true,
                            id: props?.postData?.post?.id,
                            nodes: [{ name: categoryName }],
                            __typename:'UpdatePostPayload'
                        }
                    });
                }
            }

            break;
        }
        case ModalType.CreateCategoryFromSidebar:
        case ModalType.CreateCategory: {
            updatePostData({
                variables: {
                    append: true,
                    id: props?.postData?.post?.id,
                    nodes: [{ name: categoryName }]
                }, 
                optimisticResponse: {
                    append: true,
                    id: props?.postData?.post?.id,
                    nodes: [{ name: categoryName }],
                    __typename: "UpdatePostPayload"
                }
            });
            break;
        }
        case ModalType.DuplicateSnippet: {
            const commentToUpdate = props?.postData?.post?.comments?.nodes?.find(comment => {
                const translatedCommentToUpdate = translateDatabaseShareItems(comment?.content);
                if (translatedCommentToUpdate[SavedCodeProperties.CategoryName] === userCodeSnippetSidebarAction?.item?.categoryName && translatedCommentToUpdate[SavedCodeProperties.SnippetName] === userCodeSnippetSidebarAction?.item?.snippetName) {
                    return comment;
                }
            });
            const translatedCommentToUpdate = translateDatabaseShareItems(commentToUpdate?.content);
            if (translatedCommentToUpdate) {
                translatedCommentToUpdate[SavedCodeProperties.SnippetName] = snippetName;
                translatedCommentToUpdate[SavedCodeProperties.CategoryName] = categoryName;
                const stringifiedCommentToUpdate = CodeStringifierService({
                    originalmarkdown: translatedCommentToUpdate[SavedCodeProperties.Html],
                    originaljavascript: translatedCommentToUpdate[SavedCodeProperties.Javascript],
                    originalstyles: translatedCommentToUpdate[SavedCodeProperties.Css],
                    category: translatedCommentToUpdate[SavedCodeProperties.CategoryName],
                    codeType: ModalActionType[translatedCommentToUpdate[SavedCodeProperties.SaveType]],
                    name: translatedCommentToUpdate[SavedCodeProperties.SnippetName],
                    owner: translatedCommentToUpdate[SavedCodeProperties.Owner],
                })?.getStrigifiedCodeSnippet();

                addComment({
                    variables: {
                        content: stringifiedCommentToUpdate,
                        author: loginId.toString(),
                        clientMutationId: "createComment",
                        commentOn: props?.postData?.post?.databaseId
                    },

                    refetchQueries: (args) => {
                        if (actionType !== ModalActionType.ShareOnly)
                            return [{
                                query: GetPostById,
                                variables: { title: loginId?.toString() }
                            }];
                    }
                });

                updatePostData({
                    variables: {
                        append: true,
                        id: props?.postData?.post?.id,
                        nodes: [{ name: categoryName }]
                    },
                    optimisticResponse: {
                        append: true,
                        id: props?.postData?.post?.id,
                        nodes: [{ name: categoryName }],
                        __typename: "UpdatePostPayload"
                    }
                });
            }



        }
    }
    codeValues?.forEach(value => {
        const codeSnippet = value.getStrigifiedCodeSnippet();
        if (modalType === ModalType.Edit) {
            editComment(codeSnippet),
            actionType !== ModalActionType.ShareOnly && updatePostData({
                variables: {
                    append: false,
                    id: props?.postData?.post?.id,
                    nodes: updatedCategories
                },
                optimisticResponse: {
                    append: false,
                    id: props?.postData?.post?.id,
                    nodes: updatedCategories,
                    __typename: "UpdatePostPayload"
                }
            });
        }
        if (modalType === ModalType.SaveAs || modalType === ModalType.SaveOnly) {
            addComment({
                variables: {
                    content: codeSnippet,
                    author: ModalActionType.ShareOnly ? 'share' : loginId.toString(),
                    clientMutationId: "createComment",
                    commentOn: actionType === ModalActionType.ShareOnly ? 4817 : props?.postData?.post?.databaseId
                },
                optimisticResponse: {
                    updateComment: {
                        commentOn: actionType === ModalActionType.ShareOnly ? 4817 : props?.postData?.post?.databaseId,
                        __typename: "Comment",
                        content: codeSnippet
                    }
                },
                refetchQueries: (args) => {
                    if (actionType !== ModalActionType.ShareOnly)
                        return [{
                            query: GetPostById,
                            variables: { title: loginId?.toString() }
                        }];
                }
            }),
            actionType !== ModalActionType.ShareOnly &&
                updatePostData({
                    variables: {
                        append: true,
                        id: props?.postData?.post?.id,
                        nodes: [{ name: categoryName }]
                    },
                    optimisticResponse: {
                        append: true,
                        id: props?.postData?.post?.id,
                        nodes: [{ name: categoryName }],
                        __typename: "UpdatePostPayload"
                    }
                });
        }
        if(!modalType && actionType === ModalActionType.ShareOnly) {
            addComment({
                variables: {
                    content: codeSnippet,
                    author: 'share',
                    clientMutationId: "createComment",
                    commentOn:  4817 
                },
                optimisticResponse: {
                    updateComment: {
                        commentOn: 4817,
                        __typename: "Comment",
                        content: codeSnippet
                    }
                },
                refetchQueries: (args) => {
                    if (actionType !== ModalActionType.ShareOnly)
                        return [{
                            query: GetPostById,
                            variables: { title: loginId?.toString() }
                        }];
                }
            })
        }
        if (actionType === ModalActionType.ShareOnly || actionType === ModalActionType.SaveAndShare)
            setShowSharingUrl(true);
    });
   /*  actionType !== ModalActionType.ShareOnly && isBrowser && window.history.replaceState({}, '', window.location.origin + window.location.pathname + `?quer=${props?.search?.quer}&name=${snippetName}&category=${categoryName}`); */
}