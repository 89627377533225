import { Paper } from '@material-ui/core';
import { Skeleton } from 'antd';
import React from 'react';
import { MonacoEditor } from '../../../components/monaco-editor';
import * as defaultTheme from '../../../theme';
import { TabsContainer, TabsItem } from '../styledComponents/layoutButton';


export const SandboxSkeletonLoader = () => (
    <Paper square={false} elevation={0} style={{
        background: defaultTheme.default.colors.body.backgroundColor,
        height: '460px',
        minHeight: '460px'
    }}>
        <Skeleton paragraph={{ rows: 10 }} active></Skeleton>
    </Paper>
);
