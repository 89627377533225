
import MenuProps from '../MenuProps'
import { useEffect, useState } from 'react';
import { Link, navigate } from "gatsby";
import React from 'react';
import { List } from '@material-ui/core';
import { useStyles } from '../MuiStyles/MuiSidebarStyles';
import SideMenu from './components/SideMenu';

export const SideMenuWithNavigation = ({
        menuItems, 
        router, 
        filteredText,
        width,
        pageType
}: MenuProps ) => {
    const classes = useStyles(width);
    const [itemsToShow, setItemsToShow] = useState(menuItems)

    useEffect(() => {
        if(menuItems && menuItems.length && router && router.length <= 3) {
            let currentMenuItem = null
            let hasChildren = true
            let children = menuItems[0]?.childItems?.nodes
            if(children) {
                if(!children.length) navigate(menuItems[0].path + menuItems[0]?.connectedNode?.node?.id, {state: {id: menuItems[0]?.connectedNode?.node?.id}})
                if(!children || !children[0]) return
    
                while(hasChildren) {
                    if(children[0]?.childItems?.nodes?.length) {
                        children = children[0].childItems.nodes
                    } else {
                        currentMenuItem = children[0]
                        hasChildren = false
                    }
                }
            }


            if(children && children.length) {
                navigate(children[0]?.path + children[0]?.connectedNode?.node?.id, {state: {id: children[0]?.connectedNode?.node?.id}})
            }
        }
    }, [itemsToShow])

    useEffect(() => {
        if(filteredText.length) {
            const filteredItems = [];
            const items = [...new Set(menuItems.map(menuItem => {
                if(menuItem.childItems.nodes?.length) {
                    menuItem.childItems.nodes.map(menuItemChild => {
                        if(menuItemChild.childItems.nodes?.length) {
                            menuItemChild.childItems.nodes.map(menuItemGrandChild => {
                                if(menuItemGrandChild.label.toLowerCase().includes(filteredText.toLowerCase())) filteredItems.push(menuItemGrandChild)
                            })
                        } else {
                            if(menuItemChild.label.toLowerCase().includes(filteredText.toLowerCase())) filteredItems.push(menuItemChild)
                        }
                    })
                } else {
                    if(menuItem.label.toLowerCase().includes(filteredText.toLowerCase())) filteredItems.push(menuItem)
                }
                return filteredItems
            }).flatMap(e => e).filter(e => e))]
            setItemsToShow([...items])
        } else {
            setItemsToShow(menuItems)
        }
    }, [filteredText, menuItems])
    return (
            <List
                aria-labelledby="nested-list-subheader"
                className={classes().root}
            >
                <SideMenu pageType={pageType} width={width} router={router} filteredText={filteredText} itemsToShow={itemsToShow} menuItems={menuItems} />
            </List>
    )
}