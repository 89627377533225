
import { getBackendUrl, getWordpressDomain, translateDatabaseShareItems} from "../../utils/utils";


export const generateSDKDocumentation = (allSdkRouteItems: any[], setAllSdkRouteItems, setDocumentation, SDKVersion, router: string[]) => {

    return async () => {
      const res = await fetch(getBackendUrl() + '/api/json-doc?version=' + SDKVersion.version + '&name=' + SDKVersion.name);
      try {
        const docs = await res.json();
        let slugName = '';
        const noChildArr = [];
        const addNestedChildrenToArray = (obj, resultArray) => {

          if (obj?.originalName) {
            const splittedName = obj?.name.replace(/\"/g, "").split('/');
            slugName = splittedName[0].replace(/\"/g, "") + '/' + splittedName[1].replace(/\"/g, "");
          }
          if (obj?.children)
            slugName.replace(/\"/g, "") + '/' + obj?.name.replace(/\"/g, "");
          if (!obj.children && obj?.signatures) {
            noChildArr.push({ children: null, id: obj.id, parent: obj?.name, slug: obj?.originalName ? 'sdk/' + obj.name.replace(/\"/g, "") + "/" + obj.id : 'sdk/' + slugName.replace(/\"/g, "") + '/' + obj.name.replace(/\"/g, "") + "/" + obj.id });
          }
          resultArray.push({ children: obj?.children, id: obj.id, parent: obj?.name, slug: obj?.originalName ? 'sdk/' + obj.name.replace(/\"/g, "") + "/" + obj.id : 'sdk/' + slugName.replace(/\"/g, "") + '/' + obj.name.replace(/\"/g, "") + "/" + obj.id });
          obj?.children?.forEach(child => addNestedChildrenToArray(child, resultArray));
        };
        let resultArray = [];
        addNestedChildrenToArray(docs, resultArray);
        //@ts-ignore
        resultArray = resultArray.filter(result => result.children).flat().concat(...noChildArr);
        setAllSdkRouteItems(resultArray);
        setDocumentation(docs);
      } catch (error) {
        console.log(error);
      }
  
    };
  }