import { ModalState } from "../../../../../types/ModalState";
import React from "react";
import { ModalActionType } from "../../../../../enums/ModalActionType";
import { StyledButton } from "../../../styledComponents/layoutButton";
import * as defaultTheme from '../../../../../theme';
import Modal from "react-bootstrap/esm/Modal";
import { isBrowser } from "../../../../../utils/utils";
import { ModalType } from "../../../../../enums/ModalType";

export const DeleteCategoryModalFooter = ({
    deleteComment,
    setModalShow,
    shareCode,
    searchParams,
    location
}: ModalState) => (
    <Modal.Footer className="d-flex justify-content-center pb-4 py-4" style={{ borderTop: 'none' }}>
        <StyledButton
            className="mx-2"
            backgroundColor={defaultTheme.default.colors.buttons.delete.backgroundColor}
            color={defaultTheme.default.colors.buttons.delete.textColor}
            width="125px"
            borderRadius="4px"
            onClick={() => {
                shareCode(searchParams.category, ModalActionType.Delete, searchParams.name, ModalType.DeleteCategory)
                setModalShow(false)
            }}
        >
            Confirm
        </StyledButton>
    </Modal.Footer>
)