import { CompilerOptions, JsxEmit, ModuleKind, ModuleResolutionKind, ScriptTarget } from "typescript";

export const COMPILER_OPTIONS: CompilerOptions =  {
    allowJs: true,
    useDefineForClassFields: true,
    alwaysStrict: true,
    noImplicitAny: false,
    noUnusedLocals: true,
    noUnusedParameters: true,
    allowSyntheticDefaultImports: true,
    esModuleInterop: true,
    checkJs: false,
    module: ModuleKind.ESNext,
    target: ScriptTarget.ESNext, 
    jsx: JsxEmit.React,
    moduleResolution: ModuleResolutionKind.NodeJs,
    types: ["node"],
    lib: ["dom", "es6", "es2017", "es2018", "es2019", "es2020","esnext"]
}