import React, { useMemo } from "react";
import { ModalType } from "../../../../enums/ModalType";
import * as defaultTheme from '../../../../theme';
import { Icon, IconWrapper } from "../../styledComponents/layoutButton";
import { ModalTrigger } from "../sandboxModalTrigger";

export const createDeleteModal = (deleteModalShow: boolean, setDeleteModalShow: React.Dispatch<React.SetStateAction<boolean>>, props: any, deleteComment, modalType: ModalType) => {
    return useMemo(() => (
        <ModalTrigger
            modalType={modalType}
            modalShow={deleteModalShow}
            headerTitle="Delete Snippet"
            headerIconSrc="/icons/warning-triangle.svg"
            headerIconBackground={defaultTheme.default.colors.buttons.delete.backgroundColor}
            headerIconColor={defaultTheme.default.colors.buttons.delete.textColor}
            setModalShow={setDeleteModalShow}
            searchParams={props?.search}
            location={props.location}
            id={Math.floor(100000 + Math.random() * 900000)}
            deleteComment={deleteComment}
            customTriggerButton={(
                <IconWrapper onClick={() => setDeleteModalShow(true)} className="cursor-pointer" heigth="22px" width="22px">
                    <Icon width="22px" height="22px" src="/icons/trash.svg"></Icon>
                </IconWrapper>
            )} />
    ), [deleteModalShow]);
}