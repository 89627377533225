import React from "react";
import { ModalActionType } from "../../../../../enums/ModalActionType";
import { ModalState } from "../../../../../types/ModalState";
import { StyledButton } from "../../../styledComponents/layoutButton";
import * as defaultTheme from '../../../../../theme';
import Modal from "react-bootstrap/esm/Modal";
import { ModalType } from "../../../../../enums/ModalType";
import { invalidFormMessages } from "../../../../../services/messages";

export const CreateCategoryModalFooter = ({
    setModalShow,
    setOpenMenu,
    modalType,
    setActionButton,
    setIsInputInvalid,
    sidebarMenuItemsCode,
    categoryName
}: ModalState) => (
    <Modal.Footer className="d-flex justify-content-center pb-3 pt-0 mb-1" style={{
        borderTop: 'none'
    }}>
        <StyledButton borderRadius="4px" backgroundColor={defaultTheme.default.colors.buttons.run.backgroundColor} color={defaultTheme.default.colors.buttons.run.textColor} width="175px" className="mx-2"
            onClick={() => {
                if(modalType === ModalType.CreateCategory || modalType === ModalType.CreateCategoryFromSidebar) {

                    const hasCategory = sidebarMenuItemsCode?.some(itemOrItems => Array.isArray(itemOrItems) && (itemOrItems[0]?.categoryName === categoryName))
                    if(hasCategory)
                        setIsInputInvalid({message: invalidFormMessages.existingCategoryName, state: hasCategory})
                    else {
                        if(!categoryName?.length) {
                            setIsInputInvalid({message: invalidFormMessages.emptyCategoryNameField, state: true})
                        } else {
                            setModalShow(false);
                            setActionButton({type: ModalActionType.SaveOnly, clicked: true})
                        }

                    }
                } else {
                    setModalShow && setModalShow(false);
                    setOpenMenu && setOpenMenu(false);
                }
            }}
        >
            Save
        </StyledButton>
    </Modal.Footer>
)