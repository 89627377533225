import React from 'react'
import Modal from 'react-bootstrap/esm/Modal'
import { ModalState } from '../../../../../types/ModalState';
import { IconWrapper, Icon } from '../../../styledComponents/layoutButton';

export const DeafultModalHeader = ({
    headerIconSrc,
    headerIconBackground,
    headerIconColor,
    headerTitle,
    setOpenMenu,
    setModalShow
}: ModalState) => (
    <Modal.Header className="px-4 ">
        <Modal.Title style={{fontSize: '18px'}} id="contained-modal-title-vcenter" className="d-flex container p-0 justify-content-between">
            <div className="d-flex justify-content-start align-items-center  modal-header-ccontent ">
                <IconWrapper className="mr-4" width="35px" height="35px" background={headerIconBackground}>
                    <Icon width="16px" height="16px" src={headerIconSrc} background={headerIconColor}></Icon>
                </IconWrapper>
                {headerTitle}
            </div>
            <Icon
                alignSelf="baseline"
                width="12px"
                height="30px"
                src="/icons/close.svg"
                className="ml-5 cursor-pointer"
                onClick={() => {
                    setModalShow && setModalShow(false);
                    setOpenMenu && setOpenMenu(false)
                }}
            />
        </Modal.Title>
    </Modal.Header>

)