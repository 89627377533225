import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import { ModalState } from '../../../../types/ModalState'
import { ModalFactory } from "../sandboxModalFactory";
import { ModalPart } from "../../../../enums/modalPart";

export const DiscardConfirmModal = ({
    modalShow,
    setModalShow,
    headerIconBackground,
    headerIconColor,
    headerTitle,
    headerIconSrc,
    modalType,
    discardChanges
}: ModalState) => (
    <Modal contentClassName="delete-modal" centered show={modalShow} onHide={() => {
        setModalShow(false);
    }} id={1}>
        {[ModalPart.Header, ModalPart.Body, ModalPart.Footer].map((modalPart, index) => (
            <ModalFactory key={"modalPart_"+index} modalPart={ModalPart[ModalPart[modalPart]]} modalPartState={{
                modalShow,
                setModalShow,
                discardChanges,
                headerIconBackground,
                headerIconColor,
                headerTitle,
                headerIconSrc,
                modalType
            }} />
        ))}
    </Modal>
);