import { MutationTuple, useLazyQuery, useMutation } from "@apollo/client";
import { navigate } from "@reach/router";
import { OperationVariables } from "apollo-boost";
import { useLazySwrQuery } from "../../../hooks/useLazySwrQuery";
import { getCommentQuery, setCommentQuery, updateCommentQuery, deleteCommentQuery } from "../../../queries/queryComments";
import { UpdatePostCategories } from "../../../queries/queryPost";
import { isBrowser } from "../../../utils/utils";
import {clientTestServer} from './../../../apollo/client'

export const getCommentLazyQuery = (): [any, { data: any; loading: any; }] => {
    return useLazyQuery(getCommentQuery, {
        fetchPolicy: 'network-only',
        //@ts-ignore
        client: clientTestServer
    });
}

export const addCommentMutation = (): MutationTuple<any, OperationVariables> => {
    return useMutation(setCommentQuery, {
        //@ts-ignore
        client: clientTestServer,
        onCompleted: data => {
        },
        errorPolicy: 'all'
    });
}

export const updatePostMutation = (props: any): MutationTuple<any, OperationVariables> => {
    return useMutation(UpdatePostCategories, {
        //@ts-ignore
        client: clientTestServer,
        onCompleted: data => {
            props.refetchPostData();
        },
        errorPolicy: 'all'
    });
}

export const updateCommentMutation = (props: any, refetchPostData: boolean = false): MutationTuple<any, OperationVariables> => {
    return useMutation(updateCommentQuery, {
        onCompleted: data => {
            /* props.refetchPostData(); */
        },
        //@ts-ignore
        client: clientTestServer,
        errorPolicy: 'ignore'
    });
}

export const updateCommentAndRefreshPostDataMutation = ((props: any): MutationTuple<any, OperationVariables> => {
    return useMutation(updateCommentQuery, {
        onCompleted: data => {
            props.refetchPostData();
        },
        //@ts-ignore
        client: clientTestServer
    });
})

export const deleteCommentMutation = (props: any): MutationTuple<any, OperationVariables> => {
    return useMutation(deleteCommentQuery, {
        //@ts-ignore
        client: clientTestServer,
        onCompleted: data => {

            props.refetchPostData();
            if(props?.userCodeSnippets) {
                let indexOfTheitemToNavigate = props?.userCodeSnippets?.findIndex(item => Array.isArray(item) && item?.some(it => it?.id === data?.deleteComment?.deletedId))
                if(indexOfTheitemToNavigate > -1) {
                    let itemIndex = props?.userCodeSnippets[indexOfTheitemToNavigate].findIndex(it => it?.id === data?.deleteComment?.deletedId)
                    let nextItemIndex = itemIndex + 1
                    if(props?.userCodeSnippets[indexOfTheitemToNavigate][nextItemIndex]) {
                        navigate('?' + 'quer=' + props?.userCodeSnippets[indexOfTheitemToNavigate][nextItemIndex]?.id + '&name=' + props?.userCodeSnippets[indexOfTheitemToNavigate][nextItemIndex]?.snippetName + '&category=' + props?.userCodeSnippets[indexOfTheitemToNavigate][nextItemIndex]?.categoryName )
                    } else {
                        props?.userCodeSnippets.forEach(userCodeSnippet => {
                            if(Array.isArray(userCodeSnippet)) {
                                if(userCodeSnippet.some(snippet => snippet?.id && snippet?.id !== data?.deleteComment?.deletedId)) {
                                    userCodeSnippet.forEach(snippet =>  navigate('?' + 'quer=' + snippet?.id + '&name=' + snippet?.snippetName + '&category=' + snippet?.categoryName))
                                }
                            } else {
                                if(userCodeSnippet?.id) {
                                    return navigate('?' + 'quer=' + userCodeSnippet?.id + '&name=' + userCodeSnippet?.snippetName + '&category=' + userCodeSnippet?.categoryName)
                                }
                            }
                            props?.resetSandboxValues(true)
                            navigate(isBrowser && window.location.origin  + window.location.pathname)
    
                        })
                    }
                }
            }


        },
    });
}