import React from "react";
import { StyledButton } from "../styledComponents/layoutButton";
import { useStyles } from "../styles/sandboxStyles";
import { ModalState } from '../../../types/ModalState'
import { ModalType } from "../../../enums/ModalType";
import { SaveAsModal } from "./modals/SaveAsModal";
import { CreateCategoryModal } from "./modals/CreateCategoryModal";
import { DeleteSnippetModal } from "./modals/DeleteSnippetModal";
import { isBrowser } from "../../../utils/utils";
import { SearchParams } from "../../../types/SearchParams";
import { EditModal } from "./modals/EditModal";
import { DeleteCategoryModal } from "./modals/DeleteCategoryModal";
import { DiscardConfirmModal } from "./modals/DiscardConfirmModal";
import { CircularProgress } from "@material-ui/core";

export const ModalTrigger = ({
    shareCode,
    buttonProps,
    buttonWidth,
    buttonTitle,
    location,
    loading,
    iconClassName,
    primary,
    setOpenMenu,
    id,
    customTriggerButton,
    sidebarMenuItemsCode,
    headerTitle,
    modalType,
    modalShow,
    setModalShow,
    snippetName,
    setSnippetName,
    categoryName,
    setCategoryName,
    headerIconBackground,
    headerIconColor,
    headerIconSrc,
    deleteComment,
    noButton,
    discardChanges,
    props
}: ModalState) => {
    const classes = useStyles();
    const searchParams = isBrowser && decodeURIComponent(window.location.search).substr(1)
        .split('&')
        .reduce((obj, parts) => Object.assign(obj, { [parts.split("=")[0]]: parts.split("=")[1] }), {}) as SearchParams
    
    return (
        <>
            {!noButton && (
                <>
                    {customTriggerButton ? (
                        <>
                            {customTriggerButton}
                        </>
                    ) : (
                        <StyledButton
                            {...buttonProps}
                            onClick={() => setModalShow(true)}
                            width={buttonWidth}
                        >
                            {props?.createPostLoading ? <CircularProgress size={20} className="mr-2"></CircularProgress> : (
                                <>
                                    <div className={classes[iconClassName]}></div>
                                    <div>{buttonTitle}</div>
                                </>

                            )}

                        </StyledButton>
                    )}

                </>
            )}
          
            <ConditionalModal
                location={location} 
                codeLoading={loading} 
                modalShow={modalShow} 
                setModalShow={setModalShow} 
                categoryName={categoryName} 
                setCategoryName={setCategoryName} 
                snippetName={snippetName} 
                setSnippetName={setSnippetName} 
                loading={loading}
                primary={primary}
                id={id}
                sidebarMenuItemsCode={sidebarMenuItemsCode}
                setOpenMenu={setOpenMenu}
                headerTitle={headerTitle}
                modalType={modalType}
                headerIconBackground={headerIconBackground}
                headerIconColor={headerIconColor}
                headerIconSrc={headerIconSrc}
                shareCode={shareCode}
                deleteComment={deleteComment}
                searchParams={searchParams}
                discardChanges={discardChanges}
                props={props}
            />
        </>
    );
}

const ConditionalModal = ({
    modalActionType,
    location,
    loading,
    primary,
    setOpenMenu,
    id,
    sidebarMenuItemsCode,
    headerTitle,
    headerIconBackground,
    headerIconColor,
    modalType,
    modalShow,
    setModalShow,
    snippetName,
    setSnippetName,
    categoryName, 
    setCategoryName,
    headerIconSrc,
    shareCode,
    deleteComment,
    searchParams,
    discardChanges,
    props
}: ModalState) => {
    switch (modalType) {

        case ModalType.Edit:
        case ModalType.EditCodeSnippet:
        case ModalType.DuplicateSnippet:
        case ModalType.SaveAs:
            return <SaveAsModal 
                props={props}
                location={location} 
                codeLoading={loading} 
                modalShow={modalShow} 
                setModalShow={setModalShow} 
                categoryName={categoryName} 
                setCategoryName={setCategoryName} 
                snippetName={snippetName} 
                setSnippetName={setSnippetName} 
                loading={loading}
                primary={primary}
                id={id}
                sidebarMenuItemsCode={sidebarMenuItemsCode}
                headerTitle={headerTitle}
                headerIconBackground={headerIconBackground}
                headerIconColor={headerIconColor}
                headerIconSrc={headerIconSrc}
                modalType={modalType}
                shareCode={shareCode}
                searchParams={searchParams}
            />
        case ModalType.CreateCategory:
        case ModalType.CreateCategoryFromSidebar:  
            return <CreateCategoryModal 
                modalShow={modalShow} 
                setModalShow={setModalShow} 
                setOpenMenu={setOpenMenu} 
                categoryName={categoryName} 
                setCategoryName={setCategoryName}
                modalActionType={modalActionType}
                headerTitle={headerTitle}
                headerIconBackground={headerIconBackground}
                headerIconColor={headerIconColor}
                headerIconSrc={headerIconSrc}
                modalType={modalType}
                shareCode={shareCode}
                sidebarMenuItemsCode={sidebarMenuItemsCode}
            />
        case ModalType.DeleteSnippet: 
        case ModalType.DeleteCodeSnippetFromSidebar:
            return <DeleteSnippetModal
                modalShow={modalShow} 
                setModalShow={setModalShow} 
                headerTitle={headerTitle}
                headerIconBackground={headerIconBackground}
                headerIconColor={headerIconColor}
                headerIconSrc={headerIconSrc}
                modalType={modalType}
                deleteComment={deleteComment}
                searchParams={searchParams}
                location={location}
                shareCode={shareCode}
            />

        case ModalType.EditCategory: 
            return <EditModal
                modalShow={modalShow} 
                setModalShow={setModalShow} 
                headerTitle={headerTitle}
                headerIconBackground={headerIconBackground}
                headerIconColor={headerIconColor}
                headerIconSrc={headerIconSrc}
                modalType={modalType}
                shareCode={shareCode}
                snippetName={snippetName}
                id={id}
                categoryName={categoryName}
                searchParams={searchParams}
                loading={loading}
                sidebarMenuItemsCode={sidebarMenuItemsCode}
            />
        case ModalType.DeleteCategory: 
            return <DeleteCategoryModal
                modalShow={modalShow} 
                setModalShow={setModalShow} 
                headerTitle={headerTitle}
                headerIconBackground={headerIconBackground}
                headerIconColor={headerIconColor}
                headerIconSrc={headerIconSrc}
                modalType={modalType}
                deleteComment={deleteComment}
                searchParams={searchParams}
                location={location}
                shareCode={shareCode}
            />
        case ModalType.DiscardConfirm: 
        case ModalType.ReachedMaxAmountSavedSnippets:
            return <DiscardConfirmModal
                modalShow={modalShow} 
                setModalShow={setModalShow} 
                headerTitle={headerTitle}
                headerIconBackground={headerIconBackground}
                headerIconColor={headerIconColor}
                headerIconSrc={headerIconSrc}
                modalType={modalType}
                discardChanges={discardChanges}
            />
        default:
            return <div></div>
    }
}