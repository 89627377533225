import React, { useEffect, useRef, useState, useLayoutEffect, MutableRefObject, useMemo } from 'react'
import styled from 'styled-components'
import type Monaco from 'monaco-editor'
import { v4 as uuidv4 } from 'uuid';
//Need to import Feed like this. If we deconstruct the import, it would crash on expanding the logged object.
import { Console, Hook, Unhook } from 'console-feed'
import { FaChevronDown, FaChevronUp, FaInfo } from 'react-icons/fa';
import { Methods } from 'console-feed/lib/definitions/Console';
import { EmojiObjects, Info, Warning, Error, LibraryBooks } from '@material-ui/icons';

import { useCallback, useGlobal } from 'reactn';
const _ = require('lodash')



const InfoItemContainer = styled.div`
    width: 100%;
    height: 50px;
    position: sticky;
    top: 0;
    display: ${props => props.showConsole ? 'block' : 'none'};
`
const IconWrapper = styled.div`
    cursor: pointer;
    position: absolute;
    right: 6px;
    z-index: 99999991;
`
const InfoItemText = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
const DisplayContainer = styled.div`
    top: 25px;
    &::-webkit-scrollbar {
        display: none;
    }
`

const InfoItem = styled.div`
    justify-content: flex-start;
    display: flex;
    align-items: center;
    background-color: ${props => props.clicked ? 'white' : 'inherit'};
    color: ${props => props.clicked ? '#474747' : 'inherit'};
    border-radius: 6px;
    cursor: pointer;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

`
type Message = {
    method: string
    data?: any[]
    id: string
}

const InfoItemBadge = styled.div`
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 8px;
    color: white;
    background-color: #474747;
    border-radius: 50%;
    font-size: 14px;
`
const ConsoleContainer = styled.div`
    position: absolute;
    border-radius: 0 0 4px 4px;
    bottom: 0;
    z-index: 2;
    width: 100%;
    color: white;
    background: #242424;
    transition: 'height 1s ease-in';
    overflow-y: ${props => props.showConsole ? 'scroll' : 'hidden'};;
    height: ${props => props.showConsole ? '100%' : '25px'};
`
const logSeverityInitState = {
    hint: 0,
    info: 0,
    warning: 0,
    error: 0,
    log: 0,
    all: 0
}
const markerSeverity = {
    1: 'hint',
    2: 'info',
    4: 'warning',
    8: 'error',
}

export type MonacoConsoleProps = {
    monacoEditor: typeof Monaco.editor,
    monacoEditorInstance: Monaco.editor.IStandaloneCodeEditor
    runCode?: boolean
    iframe?: MutableRefObject<HTMLIFrameElement>
    setConsoleLogs?: (input: any) => void
    consoleLogs?: Array<any>
    persistentConsole: boolean
    props?: any
}

export const MonacoConsole = ({ monacoEditor, monacoEditorInstance, runCode, iframe, setConsoleLogs, consoleLogs, persistentConsole, props }: MonacoConsoleProps) => {
    const consoleRef = useRef(null);
    const [editorLogs, setEditorLogs] = useState([])
    const [severity, setSeverity] = useState('all');
    const [consoleHookLogs, setConsoleHookLogs] = useState([])
    const [showConsole, setShowConsole] = useGlobal('showConsole')
    const [logSeverity, setLogSeverity] = useState(logSeverityInitState)
    const ref = useRef<Console>(null)
    const [logs, setLogs] = useState([])
    const toggleShowConsole = () => {
        if (logs.length || editorLogs) {
            setShowConsole(!showConsole)
        }
    }

    useEffect(() => {
        if (monacoEditorInstance && monacoEditor) {
            try {
                handleConsoleLogs(monacoEditor, monacoEditorInstance);
                logs.forEach(log => mapMarkerSeverity(log.method));
            } catch (error) {
                console.log(error)
            }
        }
    }, [monacoEditorInstance, monacoEditor])

    // run once!
    useEffect(() => {
        if (props?.isSdk) {
            setShowConsole(persistentConsole)
        }

        if (iframe?.current?.contentWindow?.window?.console) {
            const hook = Hook(iframe.current.contentWindow.window.console, log => {
                // WTKDOC-177: console does not understand arrays nor objects, we need to stringify the data!
                log.data = log.data.map((data) => typeof data !== 'string' ? JSON.stringify(data): data);
                setLogs(currLogs => [...currLogs, log])
            }, false)
            return () => {
                Unhook(hook)
            }
        }
    }, [])

    const handleConsoleLogs = (editor: typeof monacoEditor, editorInstance: Monaco.editor.IStandaloneCodeEditor) => {
        editorInstance.onDidChangeModelDecorations(() => {
            const model = editorInstance.getModel();
            if (model === null || model.id !== "javascript") return;
            setLogSeverity(logSeverityInitState)
            const owner = model.id;
            const markers = editor.getModelMarkers({ owner: owner });
            const messages: Array<Message> = [];
            markers.forEach(marker => {
                const data = `${markerSeverity[marker.severity]}: TS${marker.code}, line: ${marker.endLineNumber}, message: ${marker.message} `
                const message: Message = {
                    method: markerSeverity[marker.severity],
                    id: uuidv4(),
                    data: [data],
                }
                messages.push(message)
            })
            setEditorLogs([...messages])
        });
    }

    const removeDuplicateObjects = arr => arr.reduce((unique, o) => {
        if (!unique.some(obj => JSON.stringify(obj.data) === JSON.stringify(o.data))) {
            unique.push(o);
        }
        return unique;
    }, []);

    useEffect(() => {
        setLogs(prev => [...prev, ...editorLogs])
        return () => setLogs([])
    }, [runCode])

    const mapMarkerSeverity = (severity: string) => {
        switch (severity) {
            case 'hint': return setLogSeverity(prevState => ({ ...prevState, hint: prevState.hint + 1 }));
            case 'info': return setLogSeverity(prevState => ({ ...prevState, info: prevState.info + 1 }))
            case 'warning': return setLogSeverity(prevState => ({ ...prevState, warning: prevState.warning + 1 }))
            case 'error': return setLogSeverity(prevState => ({ ...prevState, error: prevState.error + 1 }))
            case 'log': return setLogSeverity(prevState => ({ ...prevState, log: prevState.log + 1 }))
        }
    }

    const getSeverityIcon = useCallback(severity => {
        switch (severity) {
            case 'hint': return <EmojiObjects />
            case 'info': return <Info />
            case 'warning': return <Warning />
            case 'error': return <Error />
            case 'log': return <LibraryBooks />
        }
    }, [])

    return (
        <>
            <ConsoleContainer showConsole={showConsole} ref={consoleRef} className={'container-fluid'}>
                <div className={'row align-items-center'} style={{ height: '25px', borderBottom: '1px solid grey' }}>
                    <div className={'mx-2'}>
                        Console
                    </div>
                    {
                        !persistentConsole && (
                            <IconWrapper onClick={() => { toggleShowConsole() }} className={'text-right cursor-pointer col-2 p-0 mx-2'}>
                                {showConsole ? <FaChevronDown size={20} onClick={() => toggleShowConsole()} /> : <FaChevronUp size={20} onClick={() => toggleShowConsole()} />}
                            </IconWrapper>
                        )
                    }

                </div>
                <div className={'row'}>
                    <InfoItemContainer showConsole={showConsole} className={'col-12 d-flex flex-direction-row px-2 py-2'}>
                        {Object.keys(logSeverityInitState).map((sev, index) => {
                            return (
                                <InfoItem key={index} clicked={sev === severity} onClick={() => setSeverity(sev)} className={'col-2 px-2 py-1'}>
                                    <div className={'mr-1'}>
                                        {getSeverityIcon(sev)}
                                    </div>
                                    <InfoItemText>
                                        {sev}
                                    </InfoItemText>
                                </InfoItem>
                            )
                        })}

                    </InfoItemContainer>

                </div>
                <div className="row">
                    <DisplayContainer className={'col-12 p-0'}>
                        {
                            showConsole && <Console ref={ref} filter={severity !== "all" ? [(severity as Methods)] : undefined} logs={logs} variant="dark" />
                        }
                    </DisplayContainer>
                </div>
            </ConsoleContainer>
        </>

    )
}