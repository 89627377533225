import { List, ListItem, ListItemText } from '@material-ui/core';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useGlobal } from 'reactn';

import MenuProps from './MenuProps';
import { useStyles } from './MuiStyles/MuiSidebarStyles';

export const ReleaseNotesSidebar = ({menuItems,filteredText, state}:  MenuProps ) => {
    const [anchorName, setAnchorName] = useState('')
    const [filteredItems, setFilteredItems] = useState(menuItems)
    const [contentPageLoaded, setContentPageLoaded] = useGlobal('contentPageLoaded') 
    useEffect(() => {
        if(filteredText?.length){
            const filteredMenuItems = filteredItems?.filter(item => item.version.trim().toLowerCase().search(filteredText.trim().toLowerCase()) > -1)
            setFilteredItems(filteredMenuItems)
        } else setFilteredItems(menuItems)
    }, [filteredText, menuItems])
    const classes = useStyles()
    
    return (
        <List 
            id="anchored-sidebar-release-notes"
            aria-labelledby="nested-list-subheader"
            className={classes().list}
        >
            {filteredItems?.map((item, index) => <SmoothScrollToAnchor contentPageLoaded={contentPageLoaded} state={state} setAnchorName={setAnchorName} anchorName={anchorName} item={item} index={index}></SmoothScrollToAnchor>)}
        </List>
    )
}

export const SmoothScrollToAnchor = ({contentPageLoaded, item, index, setAnchorName, anchorName, state}) => {
    const [anchorTarget, setAnchorTarget] = useState(null);
    const classes = useStyles()
    useEffect(() => {
        if(contentPageLoaded) {
            setAnchorTarget(document.getElementById(item?.version?.toLowerCase()));
        }

    }, [contentPageLoaded]);

    useEffect(() => {
        if(state?.sdkVersion && anchorTarget) {
            scrollTo(state?.sdkVersion)
        }
    }, [state, anchorTarget, item, anchorName])

    const handleClick = event => {
        event.preventDefault()
        setAnchorName(event.currentTarget.href.split('#')[1])
        anchorTarget && anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    const scrollTo = (version) => {
        setAnchorName(version)
        anchorTarget && anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    useLayoutEffect(() => {
        const elm = document.getElementById('content-template')
        if(elm) {
            elm?.addEventListener('scroll' , ev => {
                !ev.target['scrollTop'] && setAnchorName(null)
            })
            return elm?.removeEventListener('scroll', ev => {})
        }
    }, [anchorName, item, state])

    return (
        <a style={{color: 'inherit'}} href={`${window.location.pathname.split('/').slice(0, window.location.pathname.split('/').length -1).join('/')}#${item?.version?.toLowerCase()}`} onClick={handleClick}>
            <ListItem className = {classes().listItem} selected={anchorName === item?.version?.toLowerCase()} button key={index} >
                <ListItemText  primaryTypographyProps={{style: {fontWeight: 500}, /* className: "elipsis" */}} primary={item?.version}></ListItemText>
            </ListItem>
        </a>
    )
}


