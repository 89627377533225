import React, { useRef } from "react"
import { useSelector } from "react-redux"
import InfrontWidget from '../components/infront-widget'

export default function ChainFinderTemplate(props) {
    const chainFeed = useSelector(state => state.chainFinder.feed);
    const chainRef = useRef(null);
    const getChainViewerOpts = (feed) => {
        const options = new Infront.ChainViewerWidgetOptions();
        options.useChains = true;
        options.columns = [ 
            {
              name: "TICKER",
              hover: "FULL_NAME",
              flag: true
            },
            "BID",
            "ASK",
            "OPEN",
            "Last"
        ]
        options.feed = feed;
        options.dropDownItemClick = (item) => {
            
            if(chainRef.current && item && item.name) {
                chainRef.current.innerHTML = `<div>Selected chain name is:</div><div style="font-weight: bold; margin-left: 6px;">${item.name}</div>`
            }
        }

        return options;
    }

    return (
        <div className="row">
           
            <div className={`col-md-12`}>
                <div style={{margin: '12px 0', display: 'flex'}} id="chain-name-container" ref={chainRef}></div>
                {chainFeed ? <InfrontWidget type='quoteList' options={getChainViewerOpts(chainFeed)}></InfrontWidget> : 'Please choose a chain'}
                
            </div>
        </div>
    )
}