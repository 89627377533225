import React, { useEffect, useState } from "react"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Divider from "@material-ui/core/Divider";
import MenuProps from "./MenuProps";
import { capitalizeFirstLetters } from "../../utils/formater";
import { navigate } from "gatsby";
import { useStyles } from "./MuiStyles/MuiSidebarStyles";
import { useGlobal } from "reactn";
import { useSdkDocumentationMeta } from "../../hooks/useSdkDocumentationMeta";

export const SideMenuSDK = (({ 
    menuItems,
    filteredText
}: MenuProps ) => {
    //const classes = useStyles(0);
    
    const router = typeof window !== 'undefined' && window.location.pathname.split('/').filter(e => e.length)
    const [filteredItems, setFilteredItems] = useState(null)
    const [allSdkRouteItems, _] = useGlobal('allSdkRouteItems')
    const [SDKVersion, setSDKVersion] = useGlobal('SDKVersion')
    const {packagesMetadata} = useSdkDocumentationMeta()

    const classes = useStyles('');
    const list = classes().list
    const firstChildItem= `${classes().parentItem} ${classes().listItem}`
    const grandChild = `${classes().childItem} ${classes().listItem}`
    useEffect(() => {
        if(router.length <= 3) {
            const items =  menuItems.filter(item => router[1] === item?.name?.replace(/['"]+/g, '').split('/')[0] && item?.children)
            const sdkVersion = packagesMetadata?.some(mtd => mtd.sdkVersion ===  router[router.length -1]) ?  router[router.length -1] : SDKVersion?.version.split('-')[1]
            if(items && items.length) {
                let url = ''
                url += '/sdk/'
                url += `${items[0]?.name.replace(/['"]+/g, '')}/`
                if(items[0]?.children) {
                    url += `${items[0]?.children[0]?.name}/`
                    url += `${sdkVersion ?? ''}/`
                    url += `${items[0].children[0]?.id}`
                }else {
                    url += `${sdkVersion ?? ''}/`
                    url += `${items[0]?.id}`
                }
                navigate(url, {state: {id: items[0].children ? items[0].children[0]?.id : items[0]?.id}})
            }
        }
    }, [allSdkRouteItems, window.location.href, SDKVersion])
    useEffect(() => {
        if(filteredText) {
            let filteredItems = []
            menuItems?.forEach((childItem, index) => {
                if(childItem?.children) {
                    childItem?.children.forEach(grandChild => {
                        if(grandChild?.children) {
                            grandChild?.children.forEach(grandChild => {
                                if(grandChild?.name?.trim().toLowerCase().search(filteredText.trim().toLowerCase()) >-1) {
                                    filteredItems.push(grandChild)
                                }
                            })
                        }
                    } 
                )}
            })
            setFilteredItems(filteredItems)
        } else
            setFilteredItems(null)

    }, [filteredText])
    return (
        <List  
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={list }
        >
            {filteredItems ? filteredItems.map((category, index) => {
                return(
                    <Children allSdkRouteItems={allSdkRouteItems} index={index} category={category} router={router} firstChildItem={firstChildItem} grandChild={grandChild}  classes={classes} ></Children>
                )
            }) : menuItems.map((category, index) => {
                return(
                    <Children allSdkRouteItems={allSdkRouteItems} index={index} category={category} router={router} firstChildItem={firstChildItem} grandChild={grandChild}  classes={classes} ></Children>
                )
            })}
           
        </List>
    )
})


type ChildrenProps = {
    category: any, 
    index: number,
    router: string[], 
    firstChildItem: string, 
    allSdkRouteItems: any[],
    grandChild: string, 
    classes: (props?: any) => Record<"root" | "nested" | "MuiListItemText" | "parentItem" | "childItem" | "grandChildItem" | "listItem" | "list" | "slideOutTab" | "drawer", string>
}
const Children = ({category, allSdkRouteItems, index, router, firstChildItem, grandChild, classes}:ChildrenProps) => {
        let navigationItem = null
        const cat = category?.name?.replace(/['"]+/g, '').split('/')[1];
        if(!cat) {
            navigationItem = allSdkRouteItems?.find((item: any) => item?.parent?.trim()?.toLowerCase() === category?.name?.trim()?.toLowerCase())
        }
        const shouldOpenMenu = category?.name?.replace(/['"]+/g, '').split('/')[0] === router[1] && category?.name?.replace(/['"]+/g, '').split('/')[1] === router[2];

        const [firstLevelOpen, setFirstLevelOpen] = useState(false);
        useEffect(() => {
            setFirstLevelOpen(shouldOpenMenu)
        }, [shouldOpenMenu])
        const handleClick = () => {
            if(navigationItem) {
                navigate('/' + navigationItem?.slug, {state: {id: navigationItem?.id}})
            } else {
                setTimeout(() => {
                    if(`${router[1]}/${router[2]}` !== category?.name?.replace(/['"]+/g, '')) {
                        navigate(`/${router[0]}/${category?.name?.replace(/['"]+/g, '').split('/')[0]}/${category?.name?.replace(/['"]+/g, '').split('/')[1]}/${category?.children[0]?.name}/${category?.children[0]?.id}`, {state: {id: category?.children[0]?.id}})
                    }
                }, 250)
                setFirstLevelOpen(!firstLevelOpen);
            }

        };

        const isSelected = router[2]?.toLowerCase() === category?.name?.toLowerCase()?.replace(/['"]+/g, '').split('/')[1] && router[3] === ('InfrontSDK' || 'Infront')
        const showItem = router[1]?.toLowerCase() === category?.name?.toLowerCase()?.replace(/['"]+/g, '').split('/')[0]
        return (
            <>
                {(category?.children && !navigationItem && showItem) ? (
                    <>
                        <ListItem  selected={isSelected} className={firstChildItem} key={index} onClick={handleClick} button>
                            <ListItemText  primaryTypographyProps={{style: {fontWeight: 500}, className: ""}} primary={capitalizeFirstLetters(cat || category?.name)} />
                            {firstLevelOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        {(category?.children as any[]).length && (
                            <Collapse /* onEntered={() => { navigate(`/${router[0]}/${category?.name?.replace(/['"]+/g, '').split('/')[0]}/${category?.name?.replace(/['"]+/g, '').split('/')[1]}/${category?.children[0]?.name}/${category?.children[0]?.id}`, {state: {id: category?.children[0]?.id}})}} */ in={firstLevelOpen}>
                                <ChildrenMenuItems grandChildClasses={grandChild} classes={classes} menuItems={category?.children as any[]} router={router} menuName={category?.name?.replace(/['"]+/g, '')}></ChildrenMenuItems>
                            </Collapse>
                        )}

                    </>
                ) : (
                    <>
                        {navigationItem && (
                            <>
                                <ListItem className={grandChild} key={index} onClick={handleClick} button>
                                    <ListItemText  primaryTypographyProps={{style: {fontWeight: 500}, className: ""}} primary={capitalizeFirstLetters(category?.name)} />
                                </ListItem>
                            </>
                          
                        )}

                    </>
                )}
            </>
        )
}

const ChildrenMenuItems = ({grandChildClasses, menuItems, router, menuName, classes}) => {
    return (
        <>
            <List>
                {menuItems?.map((childItem, index) => 
                    <>
                        {(childItem?.children ) && (
                        <>
                            {childItem?.children.map(grandChild => 
                                <>
                                    {grandChild?.children && 
                                        <ListItem selected={ router[3]?.toLowerCase() === grandChild?.name?.toLowerCase()} className={grandChildClasses} onClick={() => {
                                            navigate(`/${router[0]}/${menuName}/${grandChild?.name}/${grandChild?.id}`, {state: {id: grandChild?.id}})
                                        }} button key={index}>
                                            <ListItemText  primaryTypographyProps={{style: {fontWeight: 500}, className: ""}} primary={grandChild?.name}></ListItemText>
                                        </ListItem> 
                                    }
                                </>
                            )}
                        </>
                        )}
                    </>    
                )}
            </List>
        </>
    )
}