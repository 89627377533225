import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import { MonacoConsole } from '../../../components/monaco-console';
import { MonacoEditor } from '../../../components/monaco-editor';
import * as defaultTheme from '../../../theme';
import { isOdd } from '../../../utils/utils';
import { TabsContainer, TabsItem } from '../styledComponents/layoutButton';
import { SandboxIframe } from '../styledComponents/sandboxIframe';

export const SandboxCodeOutput = (props) => (
    <Grid style={{
        height: (props.sandboxChildrenCount <= 2 || props.isSdk) && '100%'
    }} item xs={isOdd(props.sandboxChildrenCount) > 0 && props.sandboxChildrenCount < 2 ? 12 : 6}>
        <Paper square={false} elevation={0} style={{
            height: props.sandboxChildrenCount <= 2 || props.isSdk ? '100%' : '460px',
            maxHeight: props.sandboxChildrenCount <= 2 || props.isSdk ? '100%' : '460px',
            position: 'relative',
            background: props.isTerminalTheme ? 'rgb(30, 30, 30)' : defaultTheme.default.colors.body.backgroundColor
        }} className="cell-body">
            {!props.isSdk && props.language >= 2 && props.sandboxChildrenCount <= 2 ? <>
                <TabsContainer>
                    {props.tabsItems.map(tab => {
                        if (tab.value === "showOutput" && props.language < 2) return;
                        return <TabsItem selected={tab.show} onClick={() => tab.callback(tab.value)}>
                            {tab.title?.toUpperCase()}
                        </TabsItem>;
                    })}
                </TabsContainer>
            </> : <>
                <div style={{
                    color: props.isTerminalTheme ? 'white' : 'black',
                    margin: 0,
                    textTransform: 'capitalize',
                    padding: '16px 16px',
                    fontWeight: 400,
                    fontSize: '12px'
                }}>

                    Result
                </div>
            </>}
            <SandboxIframe loading="lazy" onLoad={() => props.setIframeLoaded(true)} name="infront" scroll="yes" id="iframe"sandbox={'allow-forms allow-pointer-lock allow-popups allow-same-origin allow-modals allow-scripts'} ref={props.iframeRef}></SandboxIframe>
            <MonacoConsole persistentConsole={props?.isSdk} setConsoleLogs={props.setConsoleLogs} consoleLogs={props.consoleLogs} iframe={props.iframeRef} runCode={props.runCode} props={props} monacoEditor={null} monacoEditorInstance={null} />
        </Paper>
    </Grid>
);

