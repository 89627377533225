import React, { useMemo } from "react";
import { ModalActionType } from "../../../../enums/ModalActionType";
import { ModalType } from "../../../../enums/ModalType";
import { IconWrapper, Icon } from "../../styledComponents/layoutButton";
import { ModalTrigger } from "../sandboxModalTrigger";
import * as defaultTheme from '../../../../theme';

export const createEditModal = (setEditModalShow: React.Dispatch<React.SetStateAction<boolean>>, shareCode: (categoryName: string, actionType: ModalActionType, snippetName: string, modalType: ModalType) => void, editModalShow: boolean, props: any, updatingCommentLoading: boolean, codeSnippetLoading: boolean) => {
    return useMemo(() => {
        return (
            <ModalTrigger
                modalType={ModalType.Edit}
                customTriggerButton={(
                    <IconWrapper onClick={() => setEditModalShow(true)} className="cursor-pointer mr-3" heigth="22px" width="22px">
                        <Icon width="22px" height="22px" src="/icons/edit.svg"></Icon>
                    </IconWrapper>
                )}
                shareCode={shareCode}
                iconClassName="saveIcon"
                setModalShow={setEditModalShow}
                modalShow={editModalShow}

                sidebarMenuItemsCode={props.sidebarMenuItemsCode}
                loading={updatingCommentLoading || codeSnippetLoading}
                id={Math.floor(100000 + Math.random() * 900000)}
                location={props.location}
                headerIconBackground={defaultTheme.default.colors.buttons.save.backgroundColor}
                headerIconColor={defaultTheme.default.colors.buttons.save.textColor}
                headerIconSrc='/icons/edit.svg'
                headerTitle='Edit details for code snippet' />
        );

    }, [editModalShow, updatingCommentLoading, codeSnippetLoading, props.sidebarMenuItemsCode]);
}