export enum SavedCodeProperties {
    Html,
    Javascript,
    Css,
    CategoryName,
    SnippetName,
    SaveType,
    Owner,
    CategoryId, 
    Id
}