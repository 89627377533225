import styled from 'styled-components'
import {ButtonBase, ButtonProps} from './baseButton'

export const SandboxIframe = styled('iframe') `
    border: none;
    height: 90%;
    padding: ${'8px 20px'};
    padding-bottom: ${'25px'};
    width: 1px; 
    min-width: 100%;
    border-radius: 4px;
`