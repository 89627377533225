import Modal from 'react-bootstrap/esm/Modal'
import React from "react"
import { ModalState } from "../../../../../types/ModalState"
import { Icon, IconWrapper, Label, ShareUrlContainer } from '../../../styledComponents/layoutButton'
import { Link } from '@reach/router';
import { ModalActionType } from '../../../../../enums/ModalActionType';
import { LoaderComponent } from '../../../../../components/loader';
import * as defaultTheme from '../../../../../theme';
import { isBrowser } from '../../../../../utils/utils';

export const SaveAndShareModalBody = ({
    codeLoading,
    messageWithLink,
    setActionButton,
    setModalShow,
    url, 
    props,
    saveMessage
}: ModalState & {
    saveMessage: string
}) => !codeLoading ? (
    <Modal.Body className="d-flex justify-content-center container px-4">
        <div className="row d-flex">
            <div className="col-12 d-flex text-center justify-content-center">
                <IconWrapper width="45px" height="45px" background={defaultTheme.default.colors.buttons.run.backgroundColor}>
                    <Icon width="35px" src="/icons/check-long.svg" background={defaultTheme.default.colors.buttons.run.textColor}></Icon>
                </IconWrapper>
            </div>
            <div className="col-12 text-center mt-3">{saveMessage}</div>
            <div className="col-12 text-center mt-3">{messageWithLink}<Link onClick={() => {
                setActionButton({type: ModalActionType.Default, clicked: false})
                setModalShow(false)
            }} to={url}> here.</Link></div>
            <div style={{
                position: 'relative'
            }} className="mt-2 col-12 mt-5">
                <Label className="ml-2">FULL URL (valid for one week)</Label>
                <ShareUrlContainer>{isBrowser && `${window.location.origin}/sandbox/shared/cG9zdDo0ODA1?quer=${props?.newlyCreatedCodeSnippet?.createComment?.comment?.id}`}</ShareUrlContainer>
            </div>
        </div>
    </Modal.Body>
) : <LoaderComponent></LoaderComponent>;