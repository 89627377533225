import { Box, Grid, List, ListItem, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useGlobal } from 'reactn';

import PageType from '../enums/pages';
import { useHighlightSidebarOnScroll } from '../hooks/useHighlightSidebarOnScroll';
import { getBackendUrl } from '../utils/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      "& br": {
        display: "none",
      },
    },
    listItem: {
      paddingLeft: theme.spacing(2),
      margin: 0,
    },
    version: {
      paddingTop: "20px",
      marginBottom: "10px",
      fontSize: "24px!important",
      lineHeight: "32px",
      fontWeight: 700,
      color: "#f9a825",
    },
    date: {
      fontSize: 16,
      marginBottom: theme.spacing(2),
    },
  })
);
export const ReleaseNotesTemplate = (props) => {
  const classes = useStyles();
  const { wtkReleaseNotesData, sdkReleaseNotesData } = props;
  const releaseNotesData =
    props.pageType === PageType.SdkReleaseNotes
      ? sdkReleaseNotesData
      : wtkReleaseNotesData;
  const releaseNotesItems =
    releaseNotesData?.nodes[0]?.acfReleaseNotes?.releaseNotes;
  const releaseNoteId = sdkReleaseNotesData?.nodes[0]?.id;

  useHighlightSidebarOnScroll({
    scrollContainer: "release-notes-container",
    sidebarContainer: "anchored-sidebar-release-notes",
  });
  const [contentPageLoaded, setContentPageLoaded] = useGlobal(
    "contentPageLoaded"
  );
  const [severUploadMetadata, setServerUploadMetadata] = useState(undefined);
  const pageRefListener = (element) => {
    if (element) {
      !contentPageLoaded && setContentPageLoaded(true);
    }
  };

  const setWTKAndSDKPackagesMetadata = async () => {
    try {
      const res = await fetch(getBackendUrl() + "/api/packages-metadata");
      const data = await res.json();
      !severUploadMetadata && setServerUploadMetadata(data);
    } catch (error) {
      console.warn(error);
    }
  };
  useEffect(() => {
    setWTKAndSDKPackagesMetadata();

    return () => {
      setContentPageLoaded(false);
    };
  }, []);

  return (
    <div ref={pageRefListener} id="release-notes-container">
      <h1>
        Infront{" "}
        {props.pageType === PageType.WtkReleaseNotes ? "Web Toolkit" : "SDK"}{" "}
        Release Notes
      </h1>
      {releaseNotesItems?.map((release, index) => {
        return (
          <Grid key={index} container id={release?.version}>
            <Grid item xs={12}>
              <Typography className={classes.version} variant="h2">
                {props.pageType === PageType.WtkReleaseNotes
                  ? "WTK version"
                  : "SDK version"}{" "}
                {release?.version}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.date} variant="h6">
                {release?.date}
              </Typography>
            </Grid>
            {release?.sdkVersion && (
              <Grid item xs={12}>
                <a
                  style={{ color: "#1890ff" }}
                  onClick={() =>
                    navigate(
                      "/release-notes/sdk-release-notes/" + releaseNoteId,
                      {
                        state: {
                          sdkVersion: release?.sdkVersion,
                          id: releaseNoteId,
                        },
                      }
                    )
                  }
                >
                  <i>This release is using SDK v{release?.sdkVersion}</i>
                </a>
              </Grid>
            )}
            <Grid item xs={12}>
              {release?.features && (
                <>
                  <Box>
                    <Typography variant="subtitle1">Features:</Typography>
                  </Box>
                  <Box>
                    <List className={classes.list} disablePadding dense>
                      {ReactHtmlParser(release?.features, {
                        transform: (node) => {
                          if (node.data) {
                            return (
                              <ListItem
                                dense
                                disableGutters
                                className={classes.listItem}
                              >
                                {node.data}
                              </ListItem>
                            );
                          }
                        },
                      })}
                    </List>
                  </Box>
                </>
              )}
              {release?.fixes && (
                <>
                  <Box>
                    <Typography variant="subtitle1">Fixes:</Typography>
                  </Box>
                  <Box>
                    <List className={classes.list} disablePadding dense>
                      {ReactHtmlParser(release?.fixes, {
                        transform: (node) => {
                          if (node.data) {
                            return (
                              <ListItem
                                dense
                                disableGutters
                                className={classes.listItem}
                              >
                                {node.data}
                              </ListItem>
                            );
                          }
                        },
                      })}
                    </List>
                  </Box>
                </>
              )}
              {release?.remarks && (
                <>
                  <Box>
                    <Typography variant="subtitle1">Remarks:</Typography>
                  </Box>
                  <Box>
                    <List className={classes.list} disablePadding dense>
                      {ReactHtmlParser(release?.remarks, {
                        transform: (node) => {
                          if (node.data) {
                            return (
                              <ListItem
                                dense
                                disableGutters
                                className={classes.listItem}
                              >
                                {node.data}
                              </ListItem>
                            );
                          }
                        },
                      })}
                    </List>
                  </Box>
                </>
              )}

              <Grid item xs={12} className="mt-3 mb-3">
                {severUploadMetadata?.wtkVersions.find(
                  (metadata) => metadata.version === release?.version
                )?.hasTypings && (
                    <a
                      className="mr-4"
                      style={{ color: "#1890ff" }}
                      href={
                        getBackendUrl() +
                        `/api/get-uploads?version=${severUploadMetadata?.wtkVersions.find(
                          (metadata) => metadata.version === release?.version
                        )?.files.version
                        }&type=typings`
                      }
                    >
                      <i>Download type declaration</i>
                    </a>
                  )}
                {severUploadMetadata?.wtkVersions.find(
                  (metadata) => metadata.sdkVersion === release?.version
                )?.hasSDKPackage && (
                    <a
                      style={{ color: "#1890ff" }}
                      href={
                        getBackendUrl() +
                        `/api/get-uploads?version=${severUploadMetadata?.wtkVersions.find(
                          (metadata) => metadata.sdkVersion === release?.version
                        )?.files.version
                        }&type=package`
                      }
                    >
                      <i>Download SDK package</i>
                    </a>
                  )}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};
