import Modal from 'react-bootstrap/esm/Modal'
import React, { useState } from "react"
import { ModalState } from "../../../../../types/ModalState"
import { Label, StyledFormControl } from '../../../styledComponents/layoutButton'
import Form from 'react-bootstrap/esm/Form'
import { LoaderComponent } from '../../../../../components/loader'
import { useEffect } from 'reactn'
import { ModalType } from '../../../../../enums/ModalType'
import { invalidFormMessages } from '../../../../../services/messages'

export const EditCategoryOrSnippetBody = ({
    categoryName,
    setCategoryName,
    setSnippetName,
    snippetName,
    setModalShow,
    modalType,
    sidebarMenuItemsCode,
    isInputInvalid, 
    setIsInputInvalid
}: ModalState) => {
    const [valueChanged, setValueChanged] = useState(null)

    if(modalType === ModalType.EditCategory || modalType === ModalType.CreateCategory) {
        useEffect(() => {
            const hasSameCategory = sidebarMenuItemsCode?.some(itemOrItems => Array.isArray(itemOrItems) && itemOrItems[0]?.categoryName === categoryName)
            setIsInputInvalid( {message: invalidFormMessages.existingCategoryName, state:  hasSameCategory && valueChanged})
        }, [categoryName, valueChanged])
    }
    useEffect(() => setValueChanged(false) , [])
    return  (
        <Modal.Body className="mt-3 px-4">
            <Form.Group className="position-relative mt-3" controlId="basicTextForm">
                <Label className="ml-2">Name</Label>
                <StyledFormControl autoComplete="off" type="text" hoverBorder background="white" placeholder="Category name" className="mt-1" style={{
                    outline: 'none'
                }} value={modalType === ModalType.EditCategory ? categoryName : snippetName} isInvalid={isInputInvalid.state} onChange={change => {
                    setValueChanged(true)
                    modalType === ModalType.EditCategory ? setCategoryName(change.target.value) : setSnippetName(change.target.value);
                }} onClick={ev => {
                    setModalShow(true);
                    ev.target.focus();
                }} />
                <Form.Control.Feedback type="invalid" className="mt-2 mx-2">
                    {isInputInvalid.message}
                </Form.Control.Feedback>
            </Form.Group>
        </Modal.Body>
    )
}