
import { ModalState } from "../../../../../types/ModalState";
import React from "react";
import { StyledButton } from "../../../styledComponents/layoutButton";
import * as defaultTheme from '../../../../../theme';
import Modal from "react-bootstrap/esm/Modal";

export const ReachedMaxAmountSavedSnippetsModalFooter = ({
    setModalShow,
}: ModalState) => (
    <Modal.Footer className="d-flex justify-content-center pb-5 py-4" style={{ borderTop: 'none' }}>
        <StyledButton
            className="mx-2"
            backgroundColor={defaultTheme.default.colors.buttons.delete.backgroundColor}
            color={defaultTheme.default.colors.buttons.delete.textColor}
            width="125px"
            borderRadius="4px"
            onClick={() => {
                setModalShow(false)
            }}
        >
            OK
        </StyledButton>
    </Modal.Footer>
)