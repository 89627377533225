import { gql } from "apollo-boost"

export const queryPageByUri = gql`
    query QueryPageByName($uri: ID!) {
        page(id: $uri, idType: URI) {
          id
        }
    }
`

export const queryDeveloperUsers = gql`
  query QueryDeveloperUsers {
    pages(where: {name: "Developer users"}) {
      nodes {
        title
        acfDeveloperUsers {
          providerInfo {
            developerName
            ownerProviderId
          }
        }
      }
    }
  }
`


    