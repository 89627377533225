import 'antd/dist/antd.css';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, Grid, makeStyles, Paper } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import { Breadcrumb } from 'antd';
import { Link } from 'gatsby';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useGlobal } from 'reactn';

import { clientTestServer } from '../apollo/client';
import Header from '../components/header';
import { LoaderComponent } from '../components/loader';
import Sidebar from '../components/sidebar/sidebar';
import BreakPointDevice from '../enums/BreakPointDevices';
import { useBreakPointDeviceState } from '../hooks/useBreakPointDeviceState';
import { useLazySwrQuery } from '../hooks/useLazySwrQuery';
import { useSdkDocumentationMeta } from '../hooks/useSdkDocumentationMeta';
import { useSidebarState } from '../hooks/useSidebarState';
import { useSwrQuery } from '../hooks/useSwrQuery';
import { CREATE_POST } from '../queries/mutatePost';
import { querySidebarMenuItems } from '../queries/queryAllMenus';
import { queryEveryWidgetPage, queryWtkSdkReleaseNotesAndDeveloperUsers } from '../queries/queryPage';
import { GetPostById } from '../queries/queryPost';
import { AboutTemplate } from '../templates/about';
import AvailableFieldsTemplate from '../templates/availableFields';
import ChainFinderTemplate from '../templates/chainFinder';
import TypeDocTemplate from '../templates/typedoc';
import DefaultTemplate from '../templates/default';
import GettingStarted from '../templates/gettingStarted';
import { ReleaseNotesTemplate } from '../templates/releaseNotes';
import SandboxTemplate from '../templates/sandbox/sandbox';
import { Icon, IconWrapper, StyledButton, TextWrapper } from '../templates/sandbox/styledComponents/layoutButton';
import SDKTemplate from '../templates/sdk';
import WidgetTemplate from '../templates/widget';
import { prettyRoute } from '../utils/formater';
import { PageType } from './../enums/pages';
import * as defaultTheme from './../theme';
import { generateSDKDocumentation } from './services/sdkDocumentation';
import { getUserCodeSnippetSidebarItems } from './services/sidebarMenuItems';

const templates = {
  '': DefaultTemplate,
  'widgettemplate': WidgetTemplate,
  'sandboxtemplate': SandboxTemplate,
  'fullwidthtemplate': GettingStarted,
  'availablefieldstemplate': AvailableFieldsTemplate,
  'chainfinder': ChainFinderTemplate,
  'typedoc': TypeDocTemplate,
  'homepage': AboutTemplate,
  'about': AboutTemplate,
  'releasenotes': ReleaseNotesTemplate,
  [PageType.Sdk]: SDKTemplate,
}

type LayoutProps = {
  data: any
  path: any
  location: any
  setScrollPosition?: (position: number) => void
  scrollPosition?: number,
  pageContext: any
  menuItems: any[]
  sidebarMenuItems: any[]
}


const useStyles = (currentBreakPointDevice, sidebarRef, headerRef, showSidebar, showFooter) => makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
  },
  toolbar: {
    width: '100%',
    position: 'relative',
    transition: 'all 0.2s ease',
    height: showFooter ? 'calc(100vh - -60px)' : `calc(100vh - ${headerRef.current?.clientHeight}px)`,
    top: showFooter ? '84px' : headerRef.current?.clientHeight + 'px',
    ...theme.mixins.toolbar
  },
  content: {
    left: showSidebar ? '300px' : 0,
    width: !showSidebar ? '100vw' : `calc(100vw -  300px)`,
    position: 'absolute',
    overflow: 'hidden',
    zIndex: 2,
    top: 0,
    transition: 'all 0.2s ease',
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  headerGrid: {
    position: 'fixed',
    zIndex: 1000,
    top: 0,
    left: 0,
    width: '100%'
  },
  none: {
    display: 'none'
  },
  sidebar: {
    maxWidth: '300px',
    zIndex: 3,
    transition: 'all 0.2s ease',
    height: `calc(100vh - ${document.getElementById("header")?.clientHeight}px)`,
    backgroundColor: 'white',
    position: 'sticky',
    /* overflowY: 'scroll', */
    width: showSidebar ? '300px' : '0',
    left: !showSidebar && '-30px',
    //top: navbarHeight,
    /* boxShadow: '3px 0px 5px 0px rgba(0,0,0,0.2)', */
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  footerGrid: {
    position: 'absolute',
    left: 0,
    width: '100%',
    top: `${document.getElementById("content")?.clientHeight + document.getElementById("header")?.clientHeight}px`
  }
}));


const DefaultLayout = ({ menuItems, data, location, pageContext, sidebarMenuItems: staticSidebarMenuItems }: LayoutProps) => {
  const { keycloak } = useKeycloak()
  if (keycloak.isTokenExpired()) keycloak.logout()

  const [infront] = useGlobal('infront');
  const [contentPageLoaded, setContentPageLoaded] = useGlobal('contentPageLoaded')
  const [monacoInstances, setMonacoInstances] = useGlobal('monacoInstances')
  const [SDKVersion, setSDKVersion] = useGlobal('SDKVersion')
  const { packagesMetadata } = useSdkDocumentationMeta()

  const path = location.pathname
  let router = path ? path.split('/').filter(p => p.length !== 0) : [];

  //TODO change name. Should not be called routes, but maybe pathParts, or pathLevels
  const routes = location.pathname.split('/').filter(path => path.length !== 0);

  const getPageType = () => {
    const pageSection = path.split('/')[1]; // Documentation, Tools, Sandbox, Release Notes
    switch (pageSection) {
      case PageType.Documentation.toLowerCase():
        {
          switch (routes[1]) {
            case 'wtk-reference':
            case 'sdk-reference':
            case 'getting-started':
            case 'util-reference':
              return PageType.TypeDoc;
            default:
              return prettyRoute(routes[1]) as PageType
          }
        }
      case PageType.Tools.toLowerCase():
        {
          switch (routes[1]) {
            case 'wtk-available-fields':
            case 'sdk-available-fields':
              return PageType.AvailableFields;
            default:
              return prettyRoute(routes[1]) as PageType
          }
        }
      // case PageType.Sdk.toLowerCase():
      case PageType.Sandbox.toLowerCase():
        {
          switch (prettyRoute(routes[1])) {
            // case PageType.AvailableFields: return PageType.AvailableFields
            case PageType.MyCodeSnippets: return PageType.MyCodeSnippets
            case PageType.Shared: return PageType.Shared
            default:
              return prettyRoute(routes[0]) as PageType
          }
        }
      case PageType.Home.toLowerCase():
      case PageType.Examples.toLowerCase():
        return prettyRoute(routes[0]) as PageType;
      default:
        return prettyRoute(routes[1]) as PageType;
    }
  }

  const pageType = getPageType();
  const isSdkAvailableFields = path.split('/')[2] === 'sdk-available-fields';
  const isExample = router?.length && router[0]?.search('examples') > -1;

  const id = menuItems?.find(menuItem => (menuItem?.label?.toLowerCase() || 'widget-docs') === router[0]?.toLowerCase())?.childItems?.nodes?.find(item => item?.path?.toLowerCase() === `/${router[0]}/${router[1]}/`)?.id
  const { data: menuData, loading: menuDataLoading } = useSwrQuery(querySidebarMenuItems, {
    variables: { id: id },
    fetchPolicy: 'cache-and-network',
  })

  const idFromPath = location?.pathname?.split('/')?.pop()
  const type = location?.state?.databaseId ? 'DATABASE_ID' : 'ID'
  const isCodeSnippet = location?.state?.isCodeSnippet

  let pageRequestId = location?.state?.id || location?.state?.navigationId || idFromPath

  if (pageType === PageType.Home) pageRequestId = 'cG9zdDo0NDI='
  const [getSdkReleaseNotesAndDeveloperUsersData, { data: wtkSdkReleaseNotesAndDeveloperUsersData }] = useLazySwrQuery(queryWtkSdkReleaseNotesAndDeveloperUsers, {
    fetchPolicy: 'cache-and-network'
  })
  const [getAllData, { data: allData, loading: allDataLoading }] = useLazySwrQuery(queryEveryWidgetPage, {
    variables: { id: pageRequestId, type: type },
    fetchPolicy: 'cache-and-network',
  })

  const wtkReleaseNotesData = wtkSdkReleaseNotesAndDeveloperUsersData?.WTKReleaseNotes
  const sdkReleaseNotesData = wtkSdkReleaseNotesAndDeveloperUsersData?.SDKReleaseNotes
  const developerUsers = wtkSdkReleaseNotesAndDeveloperUsersData?.DeveloperUsers
  const languages = wtkSdkReleaseNotesAndDeveloperUsersData?.Languages
  const developerUsersInfo = developerUsers?.nodes[0]?.acfDeveloperUsers?.providerInfo


  const isInfrontUser = developerUsersInfo?.find(developer => (developer?.developerName === "Infront" && infront?.getModel()?.login?.loginInfo?.ownerProviderId === 0 || infront?.getModel()?.login?.loginInfo?.ownerProviderId === 1) || developer?.ownerProviderId === infront?.getModel()?.login?.loginInfo?.ownerProviderId)
  const snippetLimit = isInfrontUser ? 500 : 50;
  globalThis.snippetLimit = snippetLimit;
  globalThis.isInfrontUser = isInfrontUser;

  const [createPost, { loading: createPostLoading, data: postCreatedData }] = useMutation(CREATE_POST, {
    //@ts-ignore
    client: clientTestServer
  })


  const loginId = infront?.getModel()?.login?.loginInfo?.loginId
  const [getPostData, { data: postData, loading: postDataLoading }] = useLazyQuery(GetPostById, {
    variables: { title: loginId?.toString() },
    fetchPolicy: 'network-only',
    //@ts-ignore
    client: clientTestServer,
    onCompleted: data => {
      if (!data?.post?.id) {
        createPost({
          variables: {
            MUTATION_ID: loginId.toString()
          },
          refetchQueries: [
            {
              query: GetPostById,
              variables: { title: loginId?.toString() }
            }
          ]
        })
      }
    }
  })


  const refetchPostData = () => {
    getPostData();
  }

  const [SDKDocumentation, setSDKDocumentation] = useGlobal('SDKDocumentation')
  const [allSdkRouteItems, setAllSdkRouteItems] = useGlobal('allSdkRouteItems')


  useEffect(() => {
    if (!allData && !isCodeSnippet) getAllData()
    !wtkSdkReleaseNotesAndDeveloperUsersData && getSdkReleaseNotesAndDeveloperUsersData()
    if (pageType !== PageType.Sandbox && monacoInstances?.length > 0) {
      setMonacoInstances([])
    }
  }, [location.href])

  useEffect(() => {
    setContentPageLoaded(false)
    getPostData()

  }, [])

  useEffect(() => {

    if (SDKVersion) {
      const urlHasSDKVersion = router.some(route => SDKVersion.version.search(route) > -1)
      if (!urlHasSDKVersion && router.length >= 6) {
        const newestSDKVersion = packagesMetadata?.find(mtd => mtd.hasSDKDocs && mtd.sdkVersion?.trim() === router[4]?.trim())
        const fileName = newestSDKVersion?.files?.files?.find((file: string) => file.search('.json') > -1)
        setSDKVersion({ version: newestSDKVersion?.version + '-' + router[4], name: fileName })
      }
      generateSDKDocumentation(allSdkRouteItems, setAllSdkRouteItems, setSDKDocumentation, SDKVersion, router)()
    }
  }, [SDKVersion])

  const menuItemsHierarchy = []
  menuItemsHierarchy[0] = menuItems;
  menuItemsHierarchy[1] = menuItems?.find(menuItem => menuItem?.path?.replace(/[^a-zA-Z ]/g, "")?.toLowerCase()?.trim() === router[0]?.replace(/[^a-zA-Z ]/g, "")?.toLowerCase()?.trim())?.childItems?.nodes

  const [sidebarMenuItems, setSidebarMenuItems] = useState([])

  const getPageTemplateName = (): string => {
    if (routes[0] === "documentation" && ['wtk-reference', 'sdk-reference', 'getting-started', 'util-reference'].includes(router[1]?.toLowerCase())) {
      return 'typedoc';
    }

    if (routes[0] === "tools" && ['wtk-available-fields', 'sdk-available-fields'].includes(router[1]?.toLowerCase())) {
      return 'availablefieldstemplate';
    }

    if (pageType === PageType.Sandbox) {
      router = location.pathname.split('/').filter(e => e.length);
    }

    // if (routes[0] === "sdk" && (routes[1] === "api" || routes[1] === 'declarations' || routes[1] === 'utils')) {
    //   return PageType.Sdk;
    // }

    if (pageType === PageType.Home || router[0]?.toLowerCase() === 'about') {
      return 'homepage';
    }

    return allData?.page?.template?.templateName?.toLowerCase().replace(/\s/g, '') ?? '';
  }


  const templateName = getPageTemplateName();
  const ContentTemplate = templates[templateName];

  const splitCase = s => !s || s.indexOf(' ') >= 0 ? s :
    (s.charAt(0).toUpperCase() + s.substring(1))
      .split(/(?<=[a-z])(?=[A-Z])|(?<=[A-Z])(?=[A-Z][a-z])/g)
      .map(x => x.replace(/([0-9]+)/g, '$1 '))
      .join(' ')

  useEffect(() => {

    switch (pageType) {
      case PageType.Sdk:
        setSidebarMenuItems((SDKDocumentation as any)?.children);
        break;
      case PageType.SdkReleaseNotes:
      case PageType.WtkReleaseNotes:
        setSidebarMenuItems(wtkReleaseNotesData?.nodes[0]?.acfReleaseNotes?.releaseNotes);
        break;
      case PageType.AvailableFields:
        if (isSdkAvailableFields) {
          const contentGroups = Object.keys(InfrontSDK.SymbolDataContent);
          const sdkGroups = contentGroups.map(contentType => { return { fieldGroupTitle: splitCase(contentType) } })
          setSidebarMenuItems(sdkGroups);
        } else {
          const contentGroups = Object.keys(Infront.WTKDataContent);
          const wtkGroups = contentGroups.map(contentType => { return { fieldGroupTitle: splitCase(contentType) } })
          setSidebarMenuItems(wtkGroups);
          // setSidebarMenuItems(allData?.page?.acfFields?.fieldGroup);
        }
        break;
      case PageType.MyCodeSnippets:
        const sidebarMenuItemsCode = getUserCodeSnippetSidebarItems(postData);
        setSidebarMenuItems(sidebarMenuItemsCode);
        break;
      default:
        setSidebarMenuItems(menuData?.menuItem?.childItems?.nodes);
        break;
    }

    // TODO: remove unnecessary dependencies here?
  }, [sdkReleaseNotesData, wtkReleaseNotesData, allData, menuData, SDKDocumentation, allSdkRouteItems, SDKVersion, pageType, isSdkAvailableFields, postData])


  const [topLevelMenuItems, topLevelMenuChildItems] = menuItemsHierarchy ?? []


  let currentRoute = "";
  const sidebarRef = useRef(null)
  const headerRef = useRef(null)

  const { sidebarWidth: width } = useSidebarState(sidebarRef)
  const onBreakPointDeviceChanged = device => setCurrentBreakPointDevice(device)
  const { breakPointDevice } = useBreakPointDeviceState(onBreakPointDeviceChanged)
  const [currentBreakPointDevice, setCurrentBreakPointDevice] = useState<BreakPointDevice>(breakPointDevice)

  let pageHasSidebar = true
  let showFooter = false

  if ((pageType === PageType.ChainFinder) || (pageType === PageType.AvailableFields)) {
    pageHasSidebar = true;
  }
  if ((router[0]?.trim() === 'sdk') && (router[1]?.trim() === 'getting-started')) {
    pageHasSidebar = false;
  }
  if ((pageType === PageType.Home) || (pageType === PageType.Shared) || (router[0]?.toLowerCase() === 'about') || (pageType === PageType.TypeDoc)) {
    pageHasSidebar = false;
  }

  if (pageType === PageType.Home || router[0]?.toLowerCase() === 'about') showFooter = true

  const [showSidebar, setShowSidebar] = useState(false)

  useEffect(() => {
    setShowSidebar(pageHasSidebar)
  }, [pageHasSidebar])


  const classes = useStyles(currentBreakPointDevice, sidebarRef, headerRef, showSidebar, showFooter)()

  const bodyContainerRef = useRef()

  // TODO Simplyfy this
  const breadcrumbRouter = JSON.parse(JSON.stringify(router))
  const breadcrumbItems = breadcrumbRouter.splice(0, router.length - 1).map((route, i) => {
    if (pageType === PageType.Sdk) return
    currentRoute += "/" + route;
    let id = sidebarMenuItems?.find(({ label }) => label === prettyRoute(route))?.connectedNode?.node?.id;
    if (!id) {
      id = topLevelMenuItems?.find(({ label }) => label?.toLowerCase() === prettyRoute(route))?.connectedNode?.node?.id;
    }
    //@ts-ignore
    return (<Breadcrumb.Item key={i}><Link to={currentRoute + '/' + id}>{prettyRoute(route)}</Link></Breadcrumb.Item>)
  });

  const sidebarMenuItemsCode = getUserCodeSnippetSidebarItems(postData);
  const contentTemplateRef = useRef<any>()
  return (
    <div className={classes.root}>

      <Grid container spacing={0}>
        <Grid id="header" ref={headerRef} className={classes.headerGrid} item xs={12}>
          {topLevelMenuItems && (
            <Header
              menuItems={topLevelMenuItems}
              subMenuItems={topLevelMenuChildItems}
              parentTitle={prettyRoute(router[0])}
              childTitle={prettyRoute(router[1])}
              location={location}
              routes={router}
              router={router}
              bodyContainerRef={bodyContainerRef}
              sidebarContainerRef={sidebarRef}
              headerRef={headerRef}
              hasFooter={showFooter}
              pageType={pageType}
              {...{ wtkReleaseNotesData, developerUsers }}
            />
          )}
        </Grid>
        <Grid ref={bodyContainerRef} container className={classes.toolbar} item xs={12}>
          {pageHasSidebar && (
            <div style={{/* width: '300px', */ /* display: `${showSidebar && pageType !== PageType.About ? 'block' : 'none'}`} */ position: 'fixed' }} id="sider" className={`${classes.sidebar} thin-scrollbar`} ref={sidebarRef}>
              <StyledButton
                height={!showSidebar ? '100px' : '40px'}
                width={!showSidebar ? '40px' : '60px'}
                backgroundColor={showSidebar ? "transparent" : defaultTheme.default.colors.buttons.share.backgroundColor}
                style={{ position: 'absolute', top: showSidebar ? '80px' : '80px', right: showSidebar ? '-30px' : '-70px', zIndex: 5 }}
                onClick={() => setShowSidebar(!showSidebar)}
              >
                {showSidebar ? (
                  <IconWrapper width="39px" height="39px" background={defaultTheme.default.colors.buttons.share.backgroundColor}>
                    <Icon width="10px" height="10px" style={{ [showSidebar ? 'marginRight' : 'marginLeft']: "2px" }} background={defaultTheme.default.colors.buttons.share.fillColor} src={showSidebar ? '/icons/chevre-left.svg' : '/icons/chevre-right.svg'}></Icon>
                  </IconWrapper>
                ) : (
                  <div style={{ position: 'absolute', top: '0', height: '100%' }}>
                    <IconWrapper width="39px" height="39px" className="mb-1" /* width="30px" height="10px" */ background={defaultTheme.default.colors.buttons.share.backgroundColor}>
                      <Icon width="10px" height="10px" style={{ [showSidebar ? 'marginRight' : 'marginLeft']: "2px" }} background={defaultTheme.default.colors.buttons.share.fillColor} src={showSidebar ? '/icons/chevre-left.svg' : '/icons/chevre-right.svg'}></Icon>
                    </IconWrapper>
                    <TextWrapper width="90%" height="50%" lineHeight="2" color={defaultTheme.default.colors.buttons.share.textColor} style={{ writingMode: 'vertical-rl' }}>
                      Menu
                    </TextWrapper>

                  </div>

                )}

              </StyledButton>

              {(menuDataLoading) && pageType !== PageType.Sdk && sidebarMenuItems?.length ? (
                <LoaderComponent />
              ) : (
                <Sidebar
                  menuItems={sidebarMenuItems}
                  router={router}
                  location={location}
                  isReady={!!infront}
                  pageType={pageType}
                  width={width}
                  showSidebar={showSidebar}
                  loading={menuDataLoading}
                  {...contentTemplateRef}
                />

              )}
            </div>
          )}
          <Box className={`${classes.content} thin-scrollbar`} id="content" display="flex" justifyContent="center" margin="auto">
            <Paper
              style={{
                padding: (isExample) ? '24px' : (pageType === PageType.Home || router[0]?.toLowerCase() === 'about') ? 0 : '32px',
                width: '100%',
                minHeight: `calc(100vh - ${document.getElementById("header")?.clientHeight + 60}px)`,
                margin: (breakPointDevice === BreakPointDevice.Desktop) && '30px'
              }}
              elevation={0}
              square
            >
              <div
                style={{
                  width: '100%',
                  background: '#fff',
                  height: '100%'
                }}
              >
                {/* {(pageType !== PageType.Sdk &&  pageType !== PageType.About && !isExample) &&  <Breadcrumb>
                      {breadcrumbItems}
                    </Breadcrumb>} */}

                {ContentTemplate && (
                  <>
                    {(allDataLoading && pageType !== PageType.Home && pageType !== PageType.Sdk && router[0]?.toLowerCase() !== 'about') ? (
                      <LoaderComponent />
                    ) : (
                      <ContentTemplate
                        className="thin-scrollbar"
                        pageRequestId={pageRequestId}
                        type={type}
                        sdkReleaseNotesData={sdkReleaseNotesData}
                        wtkReleaseNotesData={wtkReleaseNotesData}
                        pageType={pageType}
                        location={location}
                        developerUsers={developerUsers}
                        {...data}
                        {...pageContext}
                        router={router}
                        key={SDKDocumentation}
                        documentation={SDKDocumentation}
                        widgetData={allData}
                        postData={postData}
                        isInfrontUser={isInfrontUser}
                        createPostLoading={createPostLoading}
                        sidebarMenuItemsCode={sidebarMenuItemsCode}
                        refetchPostData={refetchPostData}
                        menuItems={menuItems}
                        postDataLoading={postDataLoading}
                        languages={languages}
                      >
                      </ContentTemplate>
                    )
                    }
                  </>
                )}
              </div>
            </Paper>
          </Box>
        </Grid>
        {showFooter && (
          <Grid id="footer" ref={headerRef} className={classes.footerGrid} item xs={12}>
            <footer className="footer">
              <img src="/icons/footer/infrontfooterlogo.svg" alt="infront logo" />
              <p>Infront ASA, Munkedamsveien 45, 0250 Oslo, Norway. support@infrontfinance.com | © Infront 2021</p>

              <div className="social-media-links mt-2">
                <a href="https://instagram.com/infrontfinance" title="instagram" target="_blank" rel="noopener"><img alt="instagram" src="/icons/footer/instagram.svg" /></a>

                <a href="https://www.linkedin.com/company/infront-as/" title="LinkedIn" target="_blank" rel="noopener"><img alt="linkedin" src="/icons/footer/linkedin.svg" /></a>

                <a href="https://twitter.com/infrontfinance" title="Twitter" target="_blank" rel="noopener"><img alt="twitter" src="/icons/footer/twitter.svg" /></a>

                <a href="https://www.youtube.com/channel/UCFEaxYcKxuokyGORz_TH1_g" title="YouTube" target="_blank" rel="noopener"><img alt="youtube" src="/icons/footer/youtube.svg" /></a>

              </div>
            </footer>
          </Grid>
        )}

      </Grid>
    </div>
  )
}
export default DefaultLayout

