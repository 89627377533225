export enum PageType {
    ChainFinder = "Chain finder",
    Reference = "Reference",
    AvailableFields = "Available fields",
    Sandbox = "Sandbox",
    Sdk = "Sdk",
    Home = "Home",
    Examples = "Examples",
    Language = "Language",
    WtkReleaseNotes = "Wtk release notes",
    SdkReleaseNotes = "Sdk release notes",
    MyCodeSnippets = "My code snippets",
    Shared = "Shared",
    TypeDoc = "TypeDoc",
    Tools = "Tools",
    Documentation = "Documentation",
}

export default PageType