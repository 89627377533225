import { gql } from "apollo-boost";

export const CREATE_POST = gql`
    mutation($MUTATION_ID: String) {
        createPost(input: {
          clientMutationId: $MUTATION_ID
          title: $MUTATION_ID,
          status: PUBLISH, 
          slug: $MUTATION_ID
        }) {
          post {
            id
            title
            date
          }
        }
    }
`;

const UPDATE_POST = gql`
    mutation($ID: String, $MUTATION_ID: String) {
        updatePost(input: {
            clientMutationId: MUTATION_ID,
            id: ID,
        }) {
            post {
                id
                title
                date
                slug
            }
        }
    }
`;

export const DELETE_COMMENT = gql`
    mutation($comment_id: ID!) {
      deleteComment(input: {id: $comment_id}) {
        deletedId
      }
    }
`