import { ListItem, ListItemText, Collapse, List } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Link } from '@reach/router'
import { navigate } from 'gatsby';
import { useGlobal } from 'reactn';
import React, { useEffect, useState } from 'react'
import { capitalizeFirstLetters, matchText } from '../../../../utils/formater';
import { useStyles } from '../../MuiStyles/MuiSidebarStyles';
import Collapsable from './Collapsable';
import PageType from '../../../../enums/pages';
import { UserCodeSnippetSidebarActions } from '../../../../enums/UserCodeSnippetSidebarAction';

export type SideMenuProps = {
    width: number | string,
    router: any,
    itemsToShow: Array<any>,
    filteredText: string,
    menuItems: Array<any>,
    pageType: PageType
}

const SideMenu = ({ width, router, itemsToShow, filteredText, menuItems, pageType }: SideMenuProps) => {
    const compare = (first, second): boolean => {
        return first === second
    }

    const parentTitle = router[2] ? router[2] : router[1]

    const classes = useStyles(width);

    // const secondLevelChildItem = `${classes().parentItem} ${classes().listItem}`
    const childClasses = classes().list
    const firstChildItem = `${classes().parentItem} ${classes().listItem}`
    const grandChildClasses = `${classes().childItem} ${classes().listItem}`
    return (
        <div>
            {itemsToShow?.map((menuItem, index) => {
                return (
                    <MenuItem
                        key={index}
                        compare={compare}
                        parentTitle={parentTitle}
                        menuItem={menuItem}
                        menuItems={menuItems}
                        itemsToShow={itemsToShow}
                        index={index}
                        secondLevelChildItem={firstChildItem}
                        childClasses={childClasses}
                        router={router}
                        width={width}
                        grandChildClasses={grandChildClasses}
                        filteredText={filteredText}
                        pageType={pageType}
                    ></MenuItem>
                )
            })}
        </div>
    )
}

const MenuItem = (
    {
        compare,
        parentTitle,
        menuItem,
        menuItems,
        itemsToShow,
        index,
        secondLevelChildItem,
        childClasses,
        router,
        width,
        grandChildClasses,
        filteredText,
        pageType
    }
) => {
    const shouldOpenMenu = router[2]?.toLowerCase()?.replace(/[^a-zA-Z]/g, '') === menuItem?.label?.toLowerCase()?.replace(/[^a-zA-Z]/g, '');
    const [firstLevelOpen, setFirstLevelOpen] = useState(shouldOpenMenu)
    const [userCodeSnippetSidebarAction, setUserCodeSnippetSidebarAction] = useGlobal('userCodeSnippetSidebarAction')
    useEffect(() => {
        setFirstLevelOpen(shouldOpenMenu)
    }, [shouldOpenMenu])
    const handleClick = () => {
        setFirstLevelOpen(!firstLevelOpen);
    };
    const handleOnNavigationClick = (ev, childItem) => {
        ev.preventDefault();
        if (pageType === PageType.Sandbox || pageType === PageType.MyCodeSnippets) {
            setUserCodeSnippetSidebarAction({
                action: UserCodeSnippetSidebarActions.Navigate, item: {
                    url: childItem.path + childItem?.connectedNode?.node?.id,
                    state: {
                        navigationId: childItem.id,
                        id: childItem?.connectedNode?.node?.id
                    }
                }
            })
        } else {
            navigate(childItem.path + childItem?.connectedNode?.node?.id, { state: { navigationId: childItem.id, id: childItem?.connectedNode?.node?.id } })
        }
    }

    // TODO if filter exists apply it
    const label = capitalizeFirstLetters(menuItem.label);
    if (menuItem?.childItems?.nodes && menuItem?.childItems?.nodes?.length) {
        return (
            <div key={index}>
                <ListItem className={secondLevelChildItem} selected={shouldOpenMenu && !menuItem.childItems.nodes.length} button onClick={handleClick}>
                    <ListItemText primaryTypographyProps={{ style: { fontWeight: 500, color: 'black' }/* , className: "elipsis" */ }} primary={capitalizeFirstLetters(label)} />
                    {firstLevelOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                    in={firstLevelOpen}
                    timeout="auto"
                    unmountOnExit
                >
                    <List className={childClasses} key={index}>
                        {
                            menuItem.childItems.nodes.map((subMenuItem, index) => {
                                return (
                                    <ChildMenuItem
                                        key={index}
                                        compare={compare}
                                        subMenuItem={subMenuItem}
                                        index={index}
                                        router={router}
                                        width={width}
                                        grandChildClasses={grandChildClasses}
                                        filteredText={filteredText}
                                        pageType={pageType}
                                    ></ChildMenuItem>
                                )
                            }
                            )
                        }
                    </List>
                </Collapse>
            </div>
        )
    }
    return (
        <>
            <List className={childClasses} key={index}>
                <ListItem
                    className={secondLevelChildItem}
                    key={index}

                    component={Link}
                    state={{
                        id: menuItem.connectedNode?.node?.id
                    }}
                    onClick={ev => {
                        handleOnNavigationClick(ev, menuItem)
                    }}
                    to={(menuItem.path + menuItem.connectedNode?.node?.id).toString()}
                    selected={shouldOpenMenu && !menuItem.childItems?.nodes?.length}
                >
                    <ListItemText
                        key={label}
                        primaryTypographyProps={{ style: { fontWeight: 500 }, /* className: "elipsis" */ }}
                        primary={label} />
                </ListItem >
            </List>
        </>
    )
}

const ChildMenuItem = ({
    compare,
    subMenuItem,
    index,
    router,
    width,
    grandChildClasses,
    filteredText,
    pageType
}) => {
    const [userCodeSnippetSidebarAction, setUserCodeSnippetSidebarAction] = useGlobal('userCodeSnippetSidebarAction')
    const handleOnNavigationClick = (ev, childItem) => {
        ev.preventDefault();

        if (pageType === PageType.Sandbox || pageType === PageType.MyCodeSnippets) {
            setUserCodeSnippetSidebarAction({
                action: UserCodeSnippetSidebarActions.Navigate, item: {
                    url: childItem.path + childItem?.connectedNode?.node?.id,
                    state: {
                        navigationId: childItem.id,
                        id: childItem?.connectedNode?.node?.id
                    }
                }
            })
        } else {
            navigate(childItem.path + childItem?.connectedNode?.node?.id, { state: { navigationId: childItem.id, id: childItem?.connectedNode?.node?.id } })
        }
    }
    const shouldOpenMenu = router[3]?.toLowerCase()?.replace(/[^a-zA-Z]/g, '') === subMenuItem.label.toLowerCase().replace(/[^a-zA-Z]/g, '');

    return (
        subMenuItem.childItems.nodes && subMenuItem.childItems.nodes.length ? (
            <Collapsable router={router} width={width} subMenuItem={subMenuItem} index={index} filteredText={filteredText} pageType={pageType} />
        ) : (
            <>
                <ListItem
                    className={grandChildClasses}
                    selected={shouldOpenMenu}
                    key={capitalizeFirstLetters(subMenuItem.label)}
                    onClick={ev => handleOnNavigationClick(ev, subMenuItem)}
                    component={Link}
                    to={(subMenuItem.path + subMenuItem?.connectedNode?.node?.id).toString()}
                    state={{ id: subMenuItem?.connectedNode?.node?.id }}
                >
                    <ListItemText primaryTypographyProps={{ style: { fontWeight: 500 }, /* className: "elipsis" */ }} key={subMenuItem.path} primary={matchText(capitalizeFirstLetters(subMenuItem.label), filteredText)} />
                </ListItem >
            </>
        ))
}
export default SideMenu