import { useState } from "reactn"
import { useEffect, useLayoutEffect } from "react";
import { WindowEventType } from "../enums/windowEventType";


type UseWindowListenerProps = {
  callback?: (...args: any) => void
  type: WindowEventType
}
// Hook
const useWindowListener = ({type, callback}: UseWindowListenerProps) => {

    useLayoutEffect(() => {

      // Add event listener
      window.addEventListener(type, ev => callback(ev));
      
      // Call handler right away so state gets updated with initial window size
      callback && callback();
      
      // Remove event listener on cleanup
      return () => window.removeEventListener(type, callback);
    }, []); // Empty array ensures that effect is only run on mount
  
    return callback;
  }
export default useWindowListener