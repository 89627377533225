import { List, ListItem, ListItemText } from '@material-ui/core';
import React, { useEffect, useLayoutEffect, useState } from 'react';

import MenuProps from './MenuProps';
import { useStyles } from './MuiStyles/MuiSidebarStyles';

export const AvailableFieldsSidebar = ({menuItems,filteredText}:  MenuProps ) => {
    const [anchorName, setAnchorName] = useState('')
    const [filteredItems, setFilteredItems] = useState(menuItems)
    useEffect(() => {
        if(filteredText?.length){
            const filteredMenuItems = menuItems?.filter(item => item.fieldGroupTitle?.toLowerCase()?.replace(/\s/g, '')?.search(filteredText?.toLowerCase()?.replace(/\s/g, '')) > -1)
            setFilteredItems(filteredMenuItems)
        } else setFilteredItems(menuItems)
    }, [filteredText, menuItems])
    const classes = useStyles()

    return (
        <List
            aria-labelledby="nested-list-subheader"
            className={classes().list}
            id="anchored-sidebar-available-fields"
        >
            {filteredItems?.map((item, index) => <SmoothScrollToAnchor key={`${index}+${item?.fieldGroupTitle}`} setAnchorName={setAnchorName} anchorName={anchorName} item={item} index={index}></SmoothScrollToAnchor>)}
        </List>
    )
}

export const SmoothScrollToAnchor = ({item, index, setAnchorName, anchorName}) => {
    const [anchorTarget, setAnchorTarget] = useState(null);
    const classes = useStyles()
    useEffect(() => {
            setAnchorTarget(document.getElementById(item.fieldGroupTitle?.toLowerCase()?.replace(/\s/g, '')));
    }, [item.fieldGroupTitle])
    const handleClick = event => {
        event.preventDefault()
        setAnchorName(event.currentTarget.href.split('#')[1])
        anchorTarget && anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    useLayoutEffect(() => {
        const elm = document.getElementById('content-template')
        if(elm) {
            elm?.addEventListener('scroll' , ev => !ev.target['scrollTop'] && setAnchorName(null))
            return elm?.removeEventListener('scroll', ev => {})
        }

    }, [anchorName, item, setAnchorName])

    return (
        <a style={{color: 'inherit'}} href={`${window.location.pathname}#${item?.fieldGroupTitle?.toLowerCase().replace(/\s/g, '')}`} onClick={handleClick}>
            <ListItem className = {classes().listItem} selected={anchorName === item?.fieldGroupTitle?.toLowerCase().replace(/\s/g, '')} button key={index} >
                <ListItemText primaryTypographyProps={{style: {fontWeight: 500}}}  primary={item.fieldGroupTitle}></ListItemText>
            </ListItem>
        </a>
    )
}
