import React, { useState } from "react";
import Modal from "react-bootstrap/esm/Modal";
import { ModalState } from '../../../../types/ModalState'
import { ModalFactory } from "../sandboxModalFactory";
import { ModalPart } from "../../../../enums/modalPart";
import { ModalType } from "../../../../enums/ModalType";
import { ModalActionType } from "../../../../enums/ModalActionType";
import { useEffect } from "reactn";


export const CreateCategoryModal = ({
    modalShow,
    setModalShow,
    setOpenMenu,
    categoryName,
    setCategoryName,
    headerIconBackground,
    headerIconColor,
    headerTitle,
    headerIconSrc,
    modalType,
    shareCode,
    sidebarMenuItemsCode
}: ModalState) => {
    const [newCategoryName, setNewCategoryName] = useState(null)
    const [actionButton, setActionButton] = useState({type: ModalActionType.Default, clicked: false})
    const [isInputInvalid, setIsInputInvalid] = useState({message: '', state: false})

    useEffect(() => {
        if((modalType === ModalType.CreateCategory || modalType === ModalType.CreateCategoryFromSidebar) && shareCode){
            if(actionButton.clicked) {
                shareCode && shareCode(newCategoryName, actionButton.type, null, modalType)
            }
        }
    }, [actionButton, modalType, newCategoryName, shareCode])

    return (
        <Modal
            contentClassName="create-category-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modalShow}
            onHide={() => {
                setModalShow(false);
                setOpenMenu && setOpenMenu(false);
            }}
            id={2}
            style={{
                background: 'rgba(0,0,0,0.6)',
            }}
        >
            {[ModalPart.Header, ModalPart.Body, ModalPart.Footer].map((modalPart, index) => (
                <ModalFactory key={"modalPart_"+index} modalPart={ModalPart[ModalPart[modalPart]]} modalPartState={{
                    headerIconBackground,
                    headerIconColor,
                    headerTitle,
                    setModalShow,
                    setOpenMenu,
                    headerIconSrc,
                    categoryName:  modalType === ModalType.CreateCategoryFromSidebar ? newCategoryName : categoryName,
                    setCategoryName: modalType === ModalType.CreateCategoryFromSidebar ? setNewCategoryName : setCategoryName,
                    modalType,
                    setActionButton,
                    isInputInvalid,
                    setIsInputInvalid,
                    sidebarMenuItemsCode
                }} />
            ))}
        </Modal>
    );
}