import { useRef } from "react";
import { Language } from "../../../enums/language";
import { Layout } from "../../../enums/layout";
import { SandboxValue } from "../../../types/SandboxValue";

export const createSandboxTabsItems = (showOutput: boolean, changeDropdownLanguage: (selectedLayout: any) => void, showJS: boolean, showHTML: boolean, showCSS: boolean) => {
    return [
        { title: 'RESULT', show: showOutput, value: 'showOutput', callback: changeDropdownLanguage, language: null },
        { title: 'JS/TS', show: showJS, value: 'showJS', callback: changeDropdownLanguage, language: Language.Javascript },
        { title: 'HTML', show: showHTML, value: 'showHTML', callback: changeDropdownLanguage, language: Language.HTML },
        { title: 'CSS', show: showCSS, value: 'showCSS', callback: changeDropdownLanguage, language: Language.CSS },
    ];
}

export const createSandboxLayoutViews = (setGridView: () => void, setHorizontalView: () => void, setFullScreenView: () => void) => {
    return [
        { title: 'Standard', icon: '/icons/grid.svg', callback: setGridView, layout: Layout.FourByFour },
        { title: 'Tabbed split', icon: '/icons/tabbed-split.svg', callback: setHorizontalView, layout: Layout.TwoByTwo },
        { title: 'Tabbed full', icon: '/icons/tabbed-full.svg', callback: setFullScreenView, layout: Layout.OneByOne }
    ];
}


export const createSandboxItem = (showJS: boolean, javascript: SandboxValue, runCode: boolean, setJS: React.Dispatch<any>, sandboxChildrenCount: number, sandboxRef: React.MutableRefObject<HTMLDivElement>, hasCodeFromDatabase: boolean, iframeRef: React.MutableRefObject<HTMLIFrameElement>) => {
    return [
        {
            show: showJS,
            value: javascript,
            setConsoleLogs: () => { },
            consoleLogs: null,
            runCode: runCode,
            language: Language.Javascript,
            onChange: setJS,
            showConsole: false,
            childrenCount: sandboxChildrenCount,
            height: sandboxRef?.current?.clientHeight,
            hasCodeSnippet: hasCodeFromDatabase,
            iframe: iframeRef,
            output: null,
            label: 'JS/TS'
        }
    ];
}


export const createSandboxItems = (
    showJS: boolean,
    javascript: SandboxValue,
    setConsoleLogs: React.Dispatch<React.SetStateAction<any[]>>,
    consoleLogs: any[],
    runCode: boolean,
    setJS: React.Dispatch<any>,
    sandboxChildrenCount: number,
    sandboxRef: React.MutableRefObject<HTMLDivElement>,
    hasCodeFromDatabase: boolean,
    iframeRef: React.MutableRefObject<HTMLIFrameElement>,
    showHTML: boolean,
    markdown: SandboxValue,
    setHTML: React.Dispatch<any>,
    showCSS: boolean,
    styles: SandboxValue,
    setCSS: React.Dispatch<any>,
) => {
    return [
        {
            show: showJS,
            value: javascript,
            setConsoleLogs: setConsoleLogs,
            consoleLogs: consoleLogs,
            runCode: runCode,
            language: Language.Javascript,
            onChange: setJS,
            showConsole: false,
            childrenCount: sandboxChildrenCount,
            height: sandboxRef?.current?.clientHeight,
            hasCodeSnippet: hasCodeFromDatabase,
            iframe: iframeRef,
            output: null,
            label: 'JS/TS',
        },
        {
            show: showHTML,
            value: markdown ?? '',
            setConsoleLogs: () => { },
            consoleLogs: null,
            runCode: null,
            language: Language.HTML,
            onChange: setHTML,
            showConsole: false,
            childrenCount: sandboxChildrenCount,
            height: sandboxRef?.current?.clientHeight,
            hasCodeSnippet: hasCodeFromDatabase,
            iframe: null,
            output: null,
            label: 'HTML',
        },
        {
            show: showCSS,
            value: styles ?? '',
            setConsoleLogs: () => { },
            consoleLogs: null,
            runCode: null,
            language: Language.CSS,
            onChange: setCSS,
            showConsole: false,
            childrenCount: sandboxChildrenCount,
            height: sandboxRef?.current?.clientHeight,
            hasCodeSnippet: hasCodeFromDatabase,
            iframe: null,
            output: null,
            label: 'CSS',
        },
    ];
}