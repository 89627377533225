import { navigate } from "gatsby"
import React from "react"
import { useEffect } from "react"
import { useGlobal } from "reactn"
import { useSdkDocumentationMeta } from "../hooks/useSdkDocumentationMeta"
import { DropdownButton, Label, MenuItemTextWrapper, StyledDropdown, StyledDropdownItem, StyledDropdownMenu, StyledDropdownMenuItemsWrapper } from "../templates/sandbox/styledComponents/layoutButton"

export type SdkDocumentationMetaDataProps = {}

export const SdkDocumentationMetaData = ({}: SdkDocumentationMetaDataProps) => {
    const {packagesMetadata} = useSdkDocumentationMeta()
    const [SDKVersion, setSDKVersion] = useGlobal('SDKVersion')
    const [allSdkRouteItems, setAllSdkRouteItems] = useGlobal('allSdkRouteItems')
    //?.wtkVersions

    useEffect(() => {
        if(packagesMetadata?.length > 0) {
            const newestSDKVersion = packagesMetadata?.find(mtd => mtd.hasSDKDocs)
            const fileName = newestSDKVersion?.files?.files?.find((file: string) => file.search('.json') > -1)
            setSDKVersion({version: newestSDKVersion.version + '-' + newestSDKVersion.sdkVersion, name: fileName})
        }

    }, [packagesMetadata, setSDKVersion])


    return (
        <div>
        <StyledDropdown className="ml-2">
            <Label className="dropdown-label">Documentation version</Label>
            <DropdownButton width={'160px'} id="dropdown-basic">
                <div className="dropdown-button-text-container">
                    <MenuItemTextWrapper>{SDKVersion?.version?.split('-')[1]}</MenuItemTextWrapper>
                </div>
                <div className="dropdown-button-icon-arrow"></div>
            </DropdownButton>
            <StyledDropdownMenu width={'160px'} className="thin-scrollbar" alignRight>
                <StyledDropdownMenuItemsWrapper padding="4px">

                    {packagesMetadata?.map((folder, index) => {
                        const fileName = folder.files?.files?.find((file: string) => file.search('.json') > -1)
                        return folder.hasSDKDocs && (
                            <React.Fragment key={index + folder} >
                                <StyledDropdownItem margin="4px 0" onClick={() => {
                                    setSDKVersion({version: folder.version + '-' + folder.sdkVersion, name: fileName})
                                    const urlToNavigate = window.location.pathname.search('available-fields') > -1 ? window.location.pathname :
                                        window.location.pathname.split('/')[0] + '/' +
                                        window.location.pathname.split('/')[1]  + '/' +
                                        window.location.pathname.split('/')[2]  + '/' +
                                        folder.sdkVersion + '/' 
                                    setAllSdkRouteItems([])
                                    navigate(urlToNavigate)
                                }} value={folder?.version}>
                                        {folder?.sdkVersion}
                                </StyledDropdownItem>

                            </React.Fragment>
                        )
                    })}
                </StyledDropdownMenuItemsWrapper>
            </StyledDropdownMenu>
        </StyledDropdown>
    </div>
    )
}