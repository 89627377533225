import React, { useState, useEffect } from 'react';
import { setSelectedChain } from '../../actions/actions';
import { useSelector, useDispatch } from "react-redux"
import MenuProps from './MenuProps';
import Collapse from '@material-ui/core/Collapse';
import { List, ListItem, ListItemText } from '@material-ui/core';

import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useStyles } from './MuiStyles/MuiSidebarStyles';
import { useGlobal } from 'reactn';

type CollapsableProps = {
    selectedChain: any
    group: string
    index: number
}

export const SideMenuWithoutNavigation = ({ 
        setSelectedItem, 
        isReady, 
        filteredText,
}: MenuProps) => {
    const [infront, setInfront ] = useGlobal('infront')
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedCountryGroups, setSelectedCountryGroups] = useState<Object>({})
    const [countryGroups, setCountryGroups] = useState([]);
    const chainFeed = useSelector(state => state.chainFinder.feed);
    const isEmpty = (obj) => obj && Object.keys(obj).length === 0 && obj.constructor === Object

    useEffect(() => {
        if(infront && !isEmpty(infront)) { 
            infront.getModel().marketListsPromise().then((marketList: Infront.MarketInfo[] | any) => {
                marketList = marketList.sort((a: { countryName: number; }, b: { countryName: number; }) => (a.countryName > b.countryName) ? 1 : -1)
                const countryGroups = marketList.reduce((r: { [x: string]: any; }, a: { countryName: string | number; }) => {
                    r[a.countryName] = [...r[a.countryName] || [], a];
                    return r;
                }, {});
                setCountryGroups(countryGroups)
            });
        }
    }, [isReady, filteredText, infront]);

    
    useEffect(() => {
        if(filteredText.length) {
            const filteredCountryGroups = [];
            Object.values(countryGroups).flat().forEach(marketInfo => {
                if(marketInfo?.country?.toString()?.toLowerCase()?.includes(filteredText) || marketInfo?.description?.toString()?.toLowerCase()?.includes(filteredText) || marketInfo?.feed?.toString()?.includes(filteredText)) filteredCountryGroups.push(marketInfo)
                return filteredCountryGroups
            })
            const uniqueCountryGroups = filteredCountryGroups.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                  t?.feed === thing?.feed
                ))
            )
            const obj = Object.assign({}, uniqueCountryGroups)
            setSelectedCountryGroups(obj)
        } else {
            setSelectedCountryGroups(countryGroups)
        }
    }, [countryGroups, filteredText])


    const firstChildClasses = `${classes().parentItem} ${classes().listItem}`
    const Collapsable = ({group, selectedChain, index}: CollapsableProps) => {
        const [secondLevelOpen, setSecondLevelOpen] = useState(!!selectedChain)
        const handleClick = () => {
            setSecondLevelOpen(!secondLevelOpen);
        };
        return (
            <React.Fragment key={index}>
                <List className={classes().list}>
                    <ListItem  className={`${classes().listItem}`} button onClick={handleClick}>
                        <ListItemText primaryTypographyProps={{style: {fontWeight: 'bold', color: 'black'}, className: ""}} primary={group} />
                        {secondLevelOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse
                        mountOnEnter
                        //onTitleClick={()=> handleSecondLevelParentMenus(group)}
                        in={secondLevelOpen} timeout="auto" unmountOnExit
                    >
                        <List  className={classes().list}>
                            {selectedCountryGroups[group]?.map((countryObject: { service: string; feed: any; }, j: any) => (
                                <ListItem 
                                    key={countryObject.service.toLocaleLowerCase().trim()}
                                    button
                                    className={`${classes().childItem}  ${classes().listItem}`}
                                    selected={selectedChain && selectedChain.feed === countryObject.feed}
                                    onClick={() => {
                                        document.getElementById('chain-name-container').innerHTML = ''
                                        dispatch(setSelectedChain(countryObject.feed)) 
                                    }}
                                >
                                    <ListItemText  primaryTypographyProps={{style: {fontWeight: 500}, className: ""}} primary={`${countryObject.feed} - ${countryObject.service}`} ></ListItemText>
                                </ListItem >
                                ))
                            }
                        </List>
                    </Collapse>
                </List>
            </React.Fragment>
        )
    }
    return (
        <>
            <List
                
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes().root}
            >
                {
                    Object.keys(selectedCountryGroups).map((group, index) => {
                        const selectedChain = (selectedCountryGroups[group] && Array.isArray(selectedCountryGroups[group])) && selectedCountryGroups[group].find(countryGroup => countryGroup.feed === chainFeed)
                        return (
                            (selectedCountryGroups[group].length) ? (
                                <Collapsable selectedChain={selectedChain} group={group} index={index}></Collapsable>
                            ) : (
                                <ListItem 
                                    onClick={() => {
                                        document.getElementById('chain-name-container').innerHTML = ''
                                        dispatch(setSelectedChain(selectedCountryGroups[group].feed)) 
                                    }} 
                                    button
                                    className={firstChildClasses}
                                    selected={selectedCountryGroups[group].feed === selectedChain.feed}
                                >
                                    <ListItemText  primaryTypographyProps={{style: {fontWeight: 500}, className: ""}} primary={`${selectedCountryGroups[group].feed} - ${selectedCountryGroups[group].service}`} ></ListItemText>
                                   
                                </ListItem>
                            )
                        )
                    })
                }
            </List>
        </>
        
    )

}