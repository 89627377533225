import React, { useState, useEffect, useRef } from "react"
import { navigate } from "@reach/router"
import BreakPointDevice from "../enums/BreakPointDevices"
import { breakpointDevice } from "../utils/breakpointCalculator"
import { HeaderDesktop } from "./header/components/headerDesktop"
import { HeaderMobile } from "./header/components/headerMobile"
import useWindowSize from "../hooks/useWindowSize"

export default function TopNav({ developerUsers, wtkReleaseNotesData, menuItems, subMenuItems, parentTitle, routes, location, childTitle, router, headerRef, bodyContainerRef, sidebarContainerRef, hasFooter, pageType }) {
  // navigates to first submenu item if it exists
  const pathToCompare = location.pathname.split("/").slice(0, location.pathname.split("/").length - 1).join("/")?.replace(/[^a-zA-Z ]/g, "")
  if (routes && location && routes[0]?.replace(/[^a-zA-Z ]/g, "") === pathToCompare && subMenuItems && subMenuItems.length) {
    navigate(subMenuItems[0]?.path + subMenuItems[0]?.connectedNode?.node?.id)
  }
  const windowSize = useWindowSize()
  const [breakPointDevice, setBreakPointDevice] = useState<BreakPointDevice>(BreakPointDevice.Desktop)
  // Set layout based on windowWidth
  useEffect(() => {
    if (breakpointDevice() === BreakPointDevice.Mobile) setBreakPointDevice(BreakPointDevice.Mobile)
    if (breakpointDevice() === BreakPointDevice.Tablet) setBreakPointDevice(BreakPointDevice.Tablet)
    if (breakpointDevice() === BreakPointDevice.Desktop) setBreakPointDevice(BreakPointDevice.Desktop)
  }, [windowSize.width])
  return (
    <>
      {/* {breakPointDevice === BreakPointDevice.Desktop  ? (
            <HeaderDesktop developerUsers={developerUsers} widgetData={ wtkReleaseNotesData} parentTitle={parentTitle} childTitle={childTitle} menuItems={menuItems} router={router} headerRef={headerRef} />
          ):(
            <HeaderMobile parentTitle={parentTitle} childTitle={childTitle} menuItems={menuItems} />
        )} */}
      <HeaderDesktop hasFooter={hasFooter} pageType={pageType} sidebarContainerRef={sidebarContainerRef} bodyContainerRef={bodyContainerRef} developerUsers={developerUsers} widgetData={wtkReleaseNotesData} parentTitle={parentTitle} childTitle={childTitle} menuItems={menuItems} router={router} headerRef={headerRef} />
    </>
  )
}
