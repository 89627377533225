import { CircularProgress } from "@material-ui/core";
import React, { useMemo } from "react";
import { LoaderComponent } from "../../../../components/loader";
import { ModalActionType } from "../../../../enums/ModalActionType";
import { ModalType } from "../../../../enums/ModalType";
import PageType from "../../../../enums/pages";
import * as defaultTheme from '../../../../theme';
import { ModalTrigger } from "../sandboxModalTrigger";


export const createSaveAsOrDuplicateModal = (props: any, shareCode: (categoryName: string, actionType: ModalActionType, snippetName: string, modalType: ModalType) => void, setSaveAsModalShow: React.Dispatch<React.SetStateAction<boolean>>, saveAsModalShow: boolean, createdCommentLoading: boolean, codeSnippetLoading: boolean, updatedPostLoading: boolean, modalType: ModalType, headerTitle: string) => {
    return useMemo(() => {
        
            return (
                <ModalTrigger
                    props={props}
                    headerTitle={headerTitle}
                    shareCode={shareCode}
                    noButton
                    setModalShow={setSaveAsModalShow}
                    modalShow={saveAsModalShow}
                    sidebarMenuItemsCode={props.sidebarMenuItemsCode}
                    loading={createdCommentLoading || codeSnippetLoading || props?.createPostLoading || props?.postDataLoading || updatedPostLoading}
                    location={props.location}
                    modalType={modalType}
                    headerIconBackground={defaultTheme.default.colors.buttons.save.backgroundColor}
                    headerIconColor={defaultTheme.default.colors.buttons.save.textColor}
                    headerIconSrc='/icons/save.svg'
                id={Math.floor(100000 + Math.random() * 900000)} />
        );
    }, [saveAsModalShow, createdCommentLoading, props.sidebarMenuItemsCode, modalType, codeSnippetLoading, props?.createPostLoading, props?.postDataLoading, updatedPostLoading, props?.newlyCreatedCodeSnippet]);
}