
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/esm/Modal";
import { ModalActionType } from "../../../../enums/ModalActionType";
import { StyledButton } from "../../styledComponents/layoutButton";
import * as defaultTheme from '../../../../theme';
import { ModalState } from '../../../../types/ModalState'
import { ModalType } from "../../../../enums/ModalType";
import { ModalFactory } from "../sandboxModalFactory";
import { ModalPart } from "../../../../enums/modalPart";
import { useLayoutEffect } from "reactn";

export const SaveAsModal = ({
    modalShow,
    props,
    sidebarMenuItemsCode,
    setModalShow,
    id,
    codeLoading,
    headerIconBackground, 
    headerIconColor, 
    headerTitle,
    headerIconSrc,
    modalType, 
    shareCode,
    searchParams,
    location,
    snippetName: originalSnippetName,
    categoryName: originalCategoryName,
}: ModalState) => {
    const messageWithLink = 'You can see a list of all your saved snippets'
    const url = '/sandbox/my-code-snippets/cG9zdDo0ODA1'
    const [actionButton, setActionButton] = useState({type: ModalActionType.Default, clicked: false})
    const [snippetName, setSnippetName] = useState<string>(null);
    const [categoryName, setCategoryName] = useState<string>(null);
    const [modalActionType, setModalActionType] = useState<ModalActionType>(ModalActionType.Default)
    const [isInvalid, setIsInvalid] = useState({message: '', state: false})
    useEffect(() => {
        if(actionButton.clicked && snippetName?.trim()?.length > 0) {
            setModalActionType(actionButton.type)
            if (actionButton.type === ModalActionType.SaveOnly || actionButton.type === ModalActionType.SaveAndShare) {
                shareCode(categoryName, actionButton.type, snippetName, modalType)
            }
            
        }
    }, [actionButton])


    useEffect(() => {
        if(modalType !== ModalType.Edit) {
            originalSnippetName && setSnippetName(originalSnippetName)
            originalCategoryName && setCategoryName(originalCategoryName)
        }

    }, [originalSnippetName, originalCategoryName])

    useLayoutEffect(() => {
        if(modalType === ModalType.Edit) {
            setSnippetName(searchParams.name)
            setCategoryName(searchParams.category)
        }
    }, [location, searchParams])
            
    useEffect(() => {
        if (!modalShow) setTimeout(() => {
            setModalActionType(ModalActionType.Default)
            setSnippetName(null)
            setCategoryName(null)
        }, 500)
    }, [modalShow])

    useEffect(() => {   
        snippetName?.trim()?.length && setActionButton({type: ModalActionType.Default, clicked: false})
    }, [snippetName])

    useEffect(() => {
        setActionButton({type: ModalActionType.Default, clicked: false})
    }, [])

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modalShow}
            id={id}
            onHide={() => {
                setActionButton({type: ModalActionType.Default, clicked: false})
                setModalShow(false)
            }}
        >
            {[ModalPart.Header, ModalPart.Body, ModalPart.Footer].map((modalPart, index) => (
                <ModalFactory key={"modalPart_" + index} modalPart={ModalPart[ModalPart[modalPart]]} modalPartState={{
                    setSnippetName,
                    snippetName,
                    props,
                    categoryName,
                    modalActionType,
                    setCategoryName,
                    sidebarMenuItemsCode,
                    actionButton,
                    codeLoading,
                    messageWithLink,
                    url,
                    setModalShow,
                    setActionButton,
                    modalType,
                    headerIconBackground, 
                    headerIconColor, 
                    headerTitle,
                    headerIconSrc,
                    isInputInvalid: isInvalid,
                    setIsInputInvalid: setIsInvalid
                }} />
            ))}
        </Modal>
    );
}