import styled, {css} from 'styled-components'
import {ButtonBase, ButtonProps} from './baseButton'
import * as theme from './../../../theme';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import Popover from 'react-bootstrap/esm/Popover';
import Form from 'react-bootstrap/esm/Form';
import InputGroup from 'react-bootstrap/esm/InputGroup';
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'; 

const buttonPadding = 10

export const StyledButton = styled(ButtonBase)<ButtonProps>`
    background:  ${props => !props.clicked ? /* theme.default.colors.buttons.run.backgroundColor */ props.backgroundColor : props.disabled ? 'lightgrey':'#FFF'};
    color: ${props => !props.clicked ? /* theme.default.colors.buttons.run.textColor */ props.color : 'black'};
    border: ${props => !props.clicked ? 'none' : '1px solid ' + theme.default.colors.buttons.run.borderColor};
    font-weight: 500;
    width: ${props => props.width ?? '95px'};
    height: ${props => props.height ?? '42px'};
    display: flex;
    padding:  ${props => props.padding ?? '0 10px'}!important ;
    justify-content: ${props => props.justifyContent ?? 'center'};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    transition: all 0.2s ease;
    border-radius: ${props => props.borderRadius ?? 'inherit'};
`

export const DropdownButton = styled(Dropdown.Toggle).withConfig({
        shouldForwardProp: (prop) => !['backgroundColor', 'hoverBackgroundColor'].includes(prop)
})`
    display: flex;
    transition: all 0.2s ease;
    align-items: center;
    justify-content: space-between;
    background-color: ${props => props.backgroundColor ?? theme.default.colors.select.backgroundColor}!important;
    height: 42px;
    color:  ${theme.default.colors.textColor}!important;
    outline: none;
    border: none;
    width: ${props => props.width};
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    
    :hover {
        background-color: ${props => props.hoverBackgroundColor ?? theme.default.colors.select.backgroundColor}!important;
        color:  ${theme.default.colors.textColor}!important;
        filter: brightness(0.95);
        box-shadow:  none!important;
    }
    :after {
        content: '';
        display: none;
    }
    :focus {
        background-color: ${theme.default.colors.select.backgroundColor};
        color:  ${theme.default.colors.textColor};
        filter: brightness(0.95);
        border: none;
        border-color: none;
        box-shadow: none!important;
    }
    &:not(:disabled):not(.disabled):active {
        background-color: ${theme.default.colors.select.backgroundColor};
        color:  ${theme.default.colors.textColor};
        box-shadow:  none!important;
    }
    & > .dropdown-button-text-container {
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        & > .dropdown-button-icon-grid {
            color: ${theme.default.colors.textColor};
            background: ${theme.default.colors.textColor};
            mask-image: url('/icons/grid.svg');
            mask-repeat: no-repeat;
            mask-position: center;
            width: 18px;
            height: 18px; 
        }
    }

    & > .dropdown-button-icon-arrow {
        color: ${theme.default.colors.textColor};
        background: ${theme.default.colors.textColor};
        mask-image: url('/icons/dropdown-arrow.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        width: 10px;
        height: 10px;
        mask-size: contain;
    }
`
export const StyledDropdown = styled(Dropdown)`
    & > .dropdown-label {
        position: absolute;
        top: -20px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 10px;
    }
`

const menu = css`
    width: ${props => props?.width ? `${props.width}px` : 'fit-content'} ;
    max-height: ${props => props?.maxHeight ? `${props.maxHeight}px` : '500px'}; ; 
    height: ${props => props?.height ? `${props.height}px` : 'fit-content'};
    overflow-y:${props => props.overflow ?? 'auto'};
    overflow-x: ${props => props.overflow ?? 'hidden'};
    padding: 0;
    width: ${props => props.width};
    box-shadow: rgb(0 0 0 / 15%) 0px 3px 10px 0px;
    border: none;
    top: 8px!important;
    //scrollbar-width:thin;
`

export const MenuItemTextWrapper = styled('span')`
    margin-top: 1px;
`

const menuItem = css`
    width: auto;
    padding: ${props => props.padding ?? '10px 14px'};
    margin: ${props => props.margin ?? '4px'};
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: start;
    background-color: 'white'!important;
    color:  ${props => props.color ? props.color : theme.default.colors.textColor}!important;
    :hover {
        background-color: ${props => props.disableHover ? 'transparent' : theme.default.colors.buttons.save.backgroundColor}!important;
        color:  ${props => props.disableHover ? props.color ? props.color : 'transparent' : theme.default.colors.buttons.save.textColor}!important;
        box-shadow:  none!important;
        border-radius: 4px;
        & > .dropdown-button-icon-grid {
            background: ${props => props.disableHover ? 'transparent' : theme.default.colors.buttons.save.textColor}!important;
        }
    }
    :after {
        content: '';
        display: none;
    }
    :focus {
        background-color: ${theme.default.colors.select.backgroundColor};
        color:  ${theme.default.colors.textColor};
        border: none;
        border-color: none;
        box-shadow: none!important;
    }
`

export const StyledDropdownItemText = styled('div') `
`

export const StyledDropdownMenuItemsWrapper = styled('div')`
    width: 100%;
    overflow: visible;
    box-sizing: border-box;
    border-bottom: ${props => props.showBorderBottom ? ` 2px solid #F1F1F1!important` : 0};
    height: ${props => props.height};
    overflow-y: ${props => props.overflow ? props.overflow : props.height ? 'scroll' : 'visible'};
    padding: ${props => props?.padding ?? 'inherit'};
`

export const StyledDropdownMenu = styled(Dropdown.Menu)`
    ${menu}
`
export const StyledTypeheadMenu = styled(Menu) `
    ${menu}
`

export const StyledDropdownItem = styled(Dropdown.Item) `
    ${menuItem}
`

export const StyledTypeheadMenuItem = styled(MenuItem) `
    ${menuItem}
` 

export const Switch = styled('div')`
    background: ${theme.default.colors.select.backgroundColor};
    height: 42px;
    position: relative;
    display: flex;
    border-radius: 4px;
    align-items: center;
    z-index:1;
    padding: 4px;
    & > .overlay {
        z-index: 2;
        height: 85%;
        width: 47%;
        right: ${props => props.selected ? '3%' : '50%'};
        border-radius: 3px;
        position: absolute;
        transition: all 0.2s ease;
        background: white;
        margin: auto;
    }
`
export const SwitchOption = styled('div')`
    cursor: pointer;
    height: 100%;
    display: block;
    position: relative;
    border-radius: 4px;
    align-items: center;
    display: flex;
    padding: 12px;
    opacity:  ${props => props.selected ? '100%' : '50%'};
    justify-content: center;
    z-index: 3;
    color: ${theme.default.colors.textColor};
    & > .text {
        font-size: 16px;
        font-weight: 500;
    }
`

export const Label = styled('div') `
    position: absolute;
    top: -20px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10px;
`

export const Icon = styled('div')`
    color: ${props => !props.color ? theme.default.colors.textColor : props.color};
    background: ${props => !props.background ? theme.default.colors.textColor : props.background};
    background-image:  ${props => props.backgroundImage  && `url(${props.src})`};
    mask-image: ${props => !props.backgroundImage && `url(${props.src})`};
    mask-repeat: no-repeat;
    mask-position: center;
    background-size: ${props => !props.backgroundSize ? 'contain' : props.backgroundSize};
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: ${props => props.width ?? '18px'};
    height: ${props => props.height ?? '18px'};
    mask-size: contain;
    transition: all 0.2s ease;
    align-self: ${props => props.alignSelf};
    position: ${props => props.absolute && 'absolute'};
    right:  ${props => props.right};
    left:  ${props => props.left};
    :hover {
        z-index: 9999;
    }
`

export const TabsContainer = styled('div')`
    display: flex;
    height: 35px;
    color: white;
    margin: 0;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 12px;
    background-color: #1D2A2F;
    align-items: center;
    align-content: center;
    border-radius: 4px 4px 0 0;

`
export const TabsItem = styled('div')`
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 16px;
    background-color: ${props => props.selected ? '#000A0D' : '#1D2A2F'} ;
    border-right: 1px solid #000A0D;
    cursor: pointer;
    text-transform: capitalize;
    &:nth-child(1) {
        border-top-left-radius: 4px;
    }
`
export const StyledPopover = styled(Popover)`
    padding: ${props => props.padding ?? '20px'};
    max-width: 550px;
    min-width: ${props => props.minWidth};
    width: ${props => props.width};
    border: none;
    margin-top: ${props => props.marginTop}!important;
    margin-left: ${props => props.marginLeft}!important;
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.15);

    & > .MuiAutocomplete-listbox {

    }
    & > .arrow {
        display: ${props => props.disableArrow ? 'none' : 'block'};
        position: relative;
        padding: 1em;
        box-sizing: border-box;
        &:before {
            ${props => props.rightAligned ? rightAlignedPopover : props.bottomAligned ? bottomAligned :  topAlignedPopover};
        }
        &:after {
            display: none;
        }   
    }
`


const bottomAligned = css`
    z-index: -1;
    content: "";
    left: 4px;
    position: absolute;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 8px solid black;
    top: -15px;
    border-color: transparent transparent white white;
    transform: rotate(45deg);
    box-shadow: -3px 3px 3px 0px rgb(0 0 0 / 3%);
`

const rightAlignedPopover = css`
    z-index: -1;
    content: "";
    left: 4px;
    position: absolute;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 8px solid black;
    top: 0;
    border-color: transparent transparent white white;
    transform: rotate(45deg);
    box-shadow: -3px 3px 3px 0px rgb(0 0 0 / 3%);
`

const topAlignedPopover = css`
    z-index: -1;
    content: "";
    left: -5px;
    position: absolute;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 8px solid black;
    top: 4px;
    border-color: transparent transparent white white;
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px 0px rgb(0 0 0 / 3%);
`
export const StyledPopoverTitle = styled(Popover.Title)`
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    align-items: center;
`

export const ShareUrlContainer = styled('div')`
    border-radius: 4px; 
    padding: 12px;
    background-color: #F1F1F1;
`

export const IconWrapper = styled('div') `
    width: ${props => props.width ?? '18px'};
    height: ${props => props.height ?? '18px'};
    display: flex;
    justify-content: center;
    align-items: center;
    left:${props => props.left && props.left};
    right:${props => props.right ? props.right : props.appendIcon && '10px'};
    top:${props => props.top ? props.top : props.appendIcon && '25%'};
    bottom: ${props => props.bottom && props.bottom};
    position: ${props => props.absolute || props.appendIcon ? 'absolute' : 'relative'};
    background: ${props => props.background};
    border-radius:  ${props => props.borderRadius ?? '50%'};
    color: 'inherit';
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    box-shadow: ${props => props.shadow};
`
export const TextWrapper = styled('div') `
    width: ${props => props?.width ?? '100%'};
    height: ${props => props?.height};
    display: flex;
    font-size: ${props => props?.fontSize};
    font-weight: ${props => props?.fontWeight};
    justify-content: center;
    color: ${props => props.color};
    line-height: ${props => props.lineHeight};
`
export const StyledFormControlWrapper = styled('div') `
    width: ${props => props.width};
    position: relative;
`

export const StyledFormControl = styled(Form.Control) `
    background-color: ${props => props.background ?? theme.default.colors.select.backgroundColor}!important;
    width: ${props => props.width};
    height: 42px;
    :focus {
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
        border: 1px solid #F1F1F1;
    }
    :hover {
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
        /* border: ${props => props.hoverBorder ? '1px solid #F1F1F1' : 'none'}; */
    }
    border-radius: 4px;
`


export const StyledFormAppendButton = styled(InputGroup.Append) `
    position: ${props => props.anchorRight && 'absolute'};
    right: ${props => props.anchorRight && '10px'};
    top: ${props => props.top};
    background-color: ${props => props.background ?? theme.default.colors.select.backgroundColor}!important;
    :focus {
        box-shadow: none;
        border: none;
    }
    :hover {
        box-shadow: none;
        /* border: 1px solid #F1F1F1; */
    }
    border-radius: 4px;
`

export const StyledFormAppendButtonText = styled(InputGroup.Text) `
    background-color: ${props => props.background ?? theme.default.colors.select.backgroundColor}!important;
    border: inherit;
    cursor: pointer;
    border-radius: 4px;
`