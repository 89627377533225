import { QueryHookOptions, useQuery } from "@apollo/client";
import { OperationVariables } from "apollo-boost";
import { DocumentNode } from "graphql";
import { QueryResult } from '@apollo/client';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useSwrQuery<TData = any, TVariables = OperationVariables>(
    query: DocumentNode,
    options?: QueryHookOptions<TData, TVariables>
  ): QueryResult<TData, TVariables> {
    const result = useQuery(query, options);
  
    let inCache = true;
    if (result.loading) {
      try {
        result.client.readQuery({
          query: query,
          variables: result.variables
        });
      } catch (error) {
        inCache = false;
      }
    }
    result.loading = !inCache;
    return result;
  }