import { ModalState } from "../../../../../types/ModalState";
import React from "react";
import { ModalActionType } from "../../../../../enums/ModalActionType";
import { StyledButton } from "../../../styledComponents/layoutButton";
import * as defaultTheme from '../../../../../theme';
import Modal from "react-bootstrap/esm/Modal";
import { isBrowser } from "../../../../../utils/utils";
import { useGlobal } from "reactn";
import { ModalType } from "../../../../../enums/ModalType";

export const DeleteSnippetModalFooter = ({
    deleteComment,
    setModalShow,
    modalType,
    searchParams,
    location
}: ModalState) => {
    const [userCodeSnippetSidebarAction, setUserCodeSnippetSidebarAction] = useGlobal('userCodeSnippetSidebarAction')
    return (
        <Modal.Footer className="d-flex justify-content-center pb-5 py-4" style={{ borderTop: 'none' }}>
{/*             <StyledButton
                className="mx-2"
                backgroundColor="transparent"
                width="125px"
                onClick={() => {
                    setModalShow(false)
                }}
            >
                Cancel
            </StyledButton> */}
            <StyledButton
                className="mx-2"
                backgroundColor={defaultTheme.default.colors.buttons.delete.backgroundColor}
                color={defaultTheme.default.colors.buttons.delete.textColor}
                width="125px"
                borderRadius="4px"
                onClick={() => {
                    switch(modalType) {
                        case ModalType.DeleteSnippet: {
                            const idToDelete = isBrowser && window.location.search.split('&')[0].split('?')[1].split('=')[1]
                            deleteComment({
                                variables: {
                                    id: idToDelete
                                }
                            })
                            break;
                        }
                        case ModalType.DeleteCodeSnippetFromSidebar: {
                            deleteComment({
                                variables: {
                                    id: userCodeSnippetSidebarAction?.item?.id
                                }
                            })
                            break;
                        }
                    }

                    setModalShow(false)
                }}
            >
                Delete
            </StyledButton>
        </Modal.Footer>
    )
} 