import { SavedCodeProperties } from "../../enums/savedCodeProperties";
import { translateDatabaseShareItems } from "../../utils/utils";

export const getUserCodeSnippetSidebarItems = (postData) => {
  const itemsWithCategories = []
  const itemsWithoutCategories = []

  const categories = postData?.post?.categories?.edges?.map(edge => new Object({ name: edge?.node?.name }))

  const items = postData?.post?.comments?.nodes.map(comment => {
    if (translateDatabaseShareItems(comment?.content) && translateDatabaseShareItems(comment?.content)[SavedCodeProperties.SaveType] && translateDatabaseShareItems(comment?.content)[SavedCodeProperties.CategoryName] !== 'share') {
      return {
        id: comment?.id,
        categoryName: translateDatabaseShareItems(comment?.content)[SavedCodeProperties.CategoryName],
        snippetName: translateDatabaseShareItems(comment?.content)[SavedCodeProperties.SnippetName]
      }
    }
  })
    .filter(value => (value && value?.categoryName === 'null') ? value : categories?.findIndex(cat => cat?.name === value?.categoryName) > -1)
    .reduce((result, value, _, array) => {
      const existingCatItem = array.find(item => item?.categoryName === value?.categoryName)
      if (!!existingCatItem) {
        const existingCatIndex = result.findIndex(item => item[0]?.categoryName === value?.categoryName && item[0]?.categoryName !== 'null')
        existingCatIndex > -1 ? result[existingCatIndex]?.push(value) : result.push([value])
      }
      return result

    }, [])
    .map(res => (!res[0].categoryName?.replace(/\s/g, '')?.length || res[0].categoryName?.replace(/\s/g, '') === 'null') ? Object.assign.apply(Object, res) : res)
    .reduce((result, value) => {
      Array.isArray(value) ? itemsWithCategories.push(value) : itemsWithoutCategories.push(value)

      itemsWithCategories.sort((a, b) => {
        var textA = a[0]?.categoryName.toUpperCase()
        var textB = b[0]?.categoryName.toUpperCase()
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      })
      itemsWithoutCategories.sort((a, b) => {
        var textA = a?.snippetName?.toUpperCase();
        var textB = b?.snippetName?.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      })

      result = itemsWithCategories.concat(itemsWithoutCategories)
      return result
    }, [])

  categories?.forEach(cat => {
    if (items.findIndex(item => Array.isArray(item) && item[0]?.categoryName === cat?.name) < 0 && cat?.name?.toLowerCase() !== 'uncategorized') {
      items.unshift([{ categoryName: cat?.name }])
    }
  })

  return items

}