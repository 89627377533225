import React, { useState, useEffect, useRef, useLayoutEffect, useCallback, useMemo } from "react"
import { globalHistory, Link, navigate } from "@reach/router"
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Box, ButtonGroup, CircularProgress, Collapse, CssBaseline, InputLabel, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, PaletteColorOptions, Select } from "@material-ui/core"
import * as defaultTheme from "../../../theme"
//@ts-ignore
import ToggleButton from '@material-ui/lab/ToggleButton';
//@ts-ignore
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { HeaderDesktopProps } from "../headerProps";
import Search, { SearchField } from "../../search";
import { capitalizeFirstLetter, getBackendUrl } from "../../../utils/utils";
import { changeToolkitVersion } from "../../../services/toolkitVersionChanger";
import { Dialpad, ExitToApp, SettingsOutlined } from "@material-ui/icons";
import { useGlobal } from "reactn";
import router from "../../../reducers/router";
import { prettyRoute } from "../../../utils/formater";
import { useKeycloak } from "@react-keycloak/web";
import { StyledDropdownMenu, StyledDropdownMenuItemsWrapper, StyledDropdownItem, DropdownButton, MenuItemTextWrapper, StyledDropdown, Icon, Label, StyledPopover } from "../../../templates/sandbox/styledComponents/layoutButton";
import { query } from "../../post-icons";
import { UserCodeSnippetSidebarActions } from "../../../enums/UserCodeSnippetSidebarAction";
import PageType from "../../../enums/pages";
import LaunchIcon from '@material-ui/icons/Launch';


const useStyles = (hasDropdownItems: boolean) => makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,

    },
    appBar: {
      flexGrow: 1,
      padding: '12px',
      backgroundColor: 'white',
      color: defaultTheme.default.colors.mineShaft,
      boxShadow: '0px 2px 10px 1px rgb(0 0 0 / 10%)!important',
      /*       display: 'flex',
            flexDirection: 'column',
            alignItems: 'center' */

    },
    collapsable: {
      padding: 0,
      position: 'relative',
      paddingLeft: '164px',
      backgroundColor: 'white',
    },
    search: {
      '&:not($disabled):not($focused):not($error) $notchedOutline': {
        border: 'none'
      },
      position: 'relative',
      borderRadius: theme.shape.borderRadius,

      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      /* padding: theme.spacing(0, 2), */
      right: 0,
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    dropdownButton: {
      '&:not($disabled):not($focused):not($error) $notchedOutline': {
        border: 'none'
      },
      margin: theme.spacing(0, 1),
      backgroundColor: 'transparent',
      '& span': {
        transition: 'all 0.2s ease',
        color: '#a5a5a5'
      },
      '&:hover': {
        backgroundColor: 'transparent',
        '& span': {
          color: '#6b6b6b'
        }

      }
    },
    inputInput: {
      padding: theme.spacing(1, 0, 1, 1),
      // vertical padding + font size from searchIcon
      paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      backgroundImage: 'url("/logo_web-toolkit.svg")',
      backgroundRepeat: 'no-repeat',
      width: '115px',
      height: '35px',
      borderRadius: '0',
      backgroundSize: 'contain',
      '&:hover': {
        backgroundColor: 'none!important'
      }
    },
    button: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '8px 16px',
      whiteSpace: 'nowrap',
      color: defaultTheme.default.colors.textColor,
      fontSize: '15px',
      fontWeight: 500,
      opacity: '40%',
      textTransform: 'capitalize',
      '&.__expanded': {
        opacity: '100%'
      },
      '&:hover': {
        opacity: '100%',
        backgroundColor: 'transparent'
      },
      '& > span': {
        fontSize: '16px',
        fontWeight: 500,
        textTransform: 'capitalize',

      },
      '&.MuiButton-label': {
        fontSize: '.875rem'
      }
    },
    buttonCollapsable: {
      color: 'white'
    },
    menuButtonCollapse: {
      paddingLeft: '16px',
      paddingRight: '16px',
      color: 'white'
    },
    label: {
      flexGrow: 1,
    },
    list: {
      padding: 0,
      display: 'flex',
      flexDirection: 'row'
    },
    listItemCollapse: {
      width: 'inherit',
      color: 'white',
      margin: 0
    },
    listItem: {
      display: 'flex',
      flexDirection: 'column',
      color: 'white',
      padding: '0',
      '&.__expanded': {
      }
    },
    toggleButton: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '8px 16px',
      color: defaultTheme.default.colors.textColor,
      fontSize: '15px',
      fontWeight: 500,
      opacity: '40%',
      textTransform: 'capitalize',
      '&.Mui-selected': {
        opacity: '100%',
        backgroundColor: 'transparent!important',
        color: `${defaultTheme.default.colors.textColor}!important`
      },
      '&:hover': {
        opacity: '100%',
        backgroundColor: 'transparent!important',
        color: `${defaultTheme.default.colors.textColor}!important`,

      },
      border: 'none'
    },
    toggleButtonGroup: {

    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    buttonRipple: { color: defaultTheme.default.colors.sidemenuSelectedBackgroundColor },
    typography: {
      color: 'white',
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(2),
    },
    '.Mui-selected': {
      backgroundColor: 'red'
    }
  }),
);


const getNavigationRouteAndState = (menuItems, index) => {
  return {
    path: menuItems[index]?.path + menuItems[index]?.connectedNode?.node.id,
    state: { id: (menuItems[index]?.connectedNode?.node?.id ?? '') }
  }
}

const menuWrapperWidth = 200
/* const menuWrapperHeight=500 */

export const HeaderDesktop = ({ developerUsers, menuItems, childTitle, router, widgetData, bodyContainerRef, sidebarContainerRef, hasFooter, pageType }: HeaderDesktopProps) => {
  const navRef = useRef(null);

  const [menuIndex, setMenuIndex] = useState(getMenuIndex(menuItems, router[0]))
  const [infront] = useGlobal('infront')
  const [globalWtkVersion] = useGlobal('globalWtkVersion')
  const [contentPageLoaded] = useGlobal('contentPageLoaded')

  const developerUsersInfo = developerUsers?.nodes[0]?.acfDeveloperUsers?.providerInfo
  const isInfrontUser = developerUsersInfo?.find(developer => (developer?.developerName === "Infront" && infront?.getModel()?.login?.loginInfo?.ownerProviderId === 0 || infront?.getModel()?.login?.loginInfo?.ownerProviderId === 1) || developer?.ownerProviderId === infront?.getModel()?.login?.loginInfo?.ownerProviderId)

  const childMenuItemsIndex = getMenuIndex(menuItems, router[0])
  const hasDropdownItems = menuItems && menuItems[childMenuItemsIndex]?.childItems?.nodes?.length > 0

  useEffect(() => {
    bodyContainerRef.current.style.height = hasFooter ? 'calc(100vh - -60px)' : `calc(100vh - ${navRef?.current?.clientHeight}px)`
    bodyContainerRef.current.style.top = `${navRef?.current?.clientHeight}px`;

    sidebarContainerRef.current && (sidebarContainerRef.current!.style.height = hasFooter ? 'calc(100vh - -60px)' : `calc(100vh - ${navRef?.current?.clientHeight}px)`);
  }, [menuIndex, hasFooter])

  useEffect(() => {
    setMenuIndex(getMenuIndex(menuItems, router[0]))
  }, [menuItems, router])

  const classes = useStyles(hasDropdownItems);
  const classNames = {
    root: classes().root,
    list: classes().list,
    appBar: classes().appBar,
    menuButton: classes().menuButton,
    listItem: classes().listItem,
    button: classes().button,
    collapsable: classes().collapsable,
    typography: classes().typography,
    toggleButtonGroup: classes().toggleButtonGroup,
    toggleButton: classes().toggleButton,
    buttonRipple: classes().buttonRipple
  }

  const { keycloak, initialized } = useKeycloak()
  const [filteredWtkAndSdkVersions, setFilteredWtkAndSdkVersions] = useState(null)

  const [query, setQuery] = useState<string>(null)
  const contextMenuDropdown = useRef(null)

  const masterBetaLocalWTKVersions = [
    { version: 'Master' },
    { version: 'Beta' },
    { version: 'Local' },
  ]

  let toolkitReleaseNotes = [...masterBetaLocalWTKVersions]
  if (widgetData?.nodes[0]?.acfReleaseNotes?.releaseNotes) toolkitReleaseNotes = [...masterBetaLocalWTKVersions, ...widgetData?.nodes[0]?.acfReleaseNotes?.releaseNotes];
  const [userCodeSnippetSidebarAction, setUserCodeSnippetSidebarAction] = useGlobal('userCodeSnippetSidebarAction')

  const wtkVersionsToSend = toolkitReleaseNotes.map(v => v?.version)
  useEffect(() => {
    if (query?.length) {
      const filteredVersions = toolkitReleaseNotes.filter(version => (version?.version as string)?.toLowerCase()?.replace(/\s/g, '').indexOf(query?.toLowerCase().replace(/\s/g, '')) !== -1)
      setFilteredWtkAndSdkVersions(filteredVersions)

    } else {
      setFilteredWtkAndSdkVersions(toolkitReleaseNotes)
    }
  }, [query])

  const onDocumentMouseDownHandler = ev => {
    if (!contextMenuDropdown?.current?.contains(ev.target)) {
      setTopLevelMenuOpen(false)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', onDocumentMouseDownHandler)

    return () => {
      document.removeEventListener('mousedown', onDocumentMouseDownHandler)
    }
  }, [])

  const Logo = useMemo(() =>
    <div style={{
      backgroundImage: 'url("/icons/infront_logo.svg")',
      backgroundRepeat: 'no-repeat',
      width: '100px',
      height: '20px',
      borderRadius: '0',
      backgroundSize: 'contain',
    }} className="cursor-pointer mr-5 ml-2" onClick={() => {
      navigate('/home')
    }} ></div>
    , [])

  const [topLevelMenuOpen, setTopLevelMenuOpen] = useState(null);
  const [secondLevelMenuOpen, setSecondLevelMenuOpen] = useState(null);

  const handleOnNavigationClick = (ev, childItem) => {
    ev.preventDefault();
    const nodeUri = childItem?.connectedNode?.node?.uri;
    const willNavigateHome = (nodeUri === '/about/' || nodeUri === '/home/');
    const path = childItem.path ?? '';
    const nodeId = childItem?.connectedNode?.node?.id ?? '';
    const url = willNavigateHome ? (path) : path + nodeId;
    const state = { state: { navigationId: childItem.id, id: nodeId } };

    if (contentPageLoaded && router?.length > 4 && (pageType === PageType.Sandbox || pageType === PageType.MyCodeSnippets)) {
      setUserCodeSnippetSidebarAction({ action: UserCodeSnippetSidebarActions.Navigate, item: { url: url, state: state } })
    } else {
      navigate(url, state);
    }
  }



  return (
    <div className={classNames.root}>
      <CssBaseline />
      <>
        <AppBar /* onMouseLeave={() => setMenuIndex(getMenuIndex(menuItems, router[0]))} */ position="sticky" ref={navRef} className={classNames.appBar}>
          <Toolbar style={{ minHeight: '32px' }} className='p-0 container-fluid d-flex'>

            <div className="col-8 d-flex p-2 justify-content-start align-items-center">
              <div style={{ marginBottom: '10px' }} className='align-items-center'>
                {Logo}
              </div>
              <List className={classNames.list}>
                {!menuItems && (
                  <div className='ml-5'>
                    <CircularProgress size={20}></CircularProgress>
                  </div>
                )}
                {menuItems?.length && menuItems?.filter((menuItem) =>
                  !['/widgets/', '/sdk/'].includes(menuItem.path) // WTKDOC-183
                ).map((menuItem, index) => {
                  const { label } = menuItem
                  const willOpenDropdownMenu = childMenuItemsIndex === index && hasDropdownItems
                  return (
                    <ListItem
                      onMouseEnter={() => {
                        /* setMenuIndex(getMenuIndex(menuItems, menuItem?.label)) */
                      }}
                      component={Link}
                      to={getNavigationRouteAndState(menuItems, index).path}
                      state={getNavigationRouteAndState(menuItems, index).state}
                      key={index}
                      onClick={ev => {
                        /* getMenuIndex(menuItems, router[0]) */
                        handleOnNavigationClick(ev, menuItem)
                      }}
                      className={willOpenDropdownMenu ? `${classNames.listItem} __expanded` : classNames.listItem}
                    >
                      <Button disableRipple TouchRippleProps={{ classes: { root: classNames.buttonRipple } }} className={willOpenDropdownMenu ? `${classNames.button} __expanded` : classNames.button}>
                        {label?.toLowerCase()}
                      </Button>
                    </ListItem>
                  )
                })}
              </List>
            </div>
            <div className="col-4 p-2 d-flex justify-content-end">
              <Search />
              {!!isInfrontUser ? (
                <StyledDropdown ref={contextMenuDropdown} show={topLevelMenuOpen}>

                  <DropdownButton backgroundColor='transparent' hoverBackgroundColor='transparent' className='ml-2' onClick={() => setTopLevelMenuOpen(!topLevelMenuOpen)} id="dropdown-basic">
                    <div className="d-flex align-content-center align-items-center justify-content-between w-100">
                      <div className="d-flex justify-content-start align-items-center">
                        <SettingsOutlined style={{ color: "#000A0D", width: '25px', height: '25px' }} />
                      </div>
                    </div>
                  </DropdownButton>

                  <StyledDropdownMenu overflow='visible' alignRight>
                    <StyledDropdownMenuItemsWrapper overflow="visible" /* height={menuWrapperHeight + 'px'}  *//* width={menuWrapperWidth + 'px'} */ padding="4px">

                      <StyledDropdownItem padding='10px 4px' color={defaultTheme.default.colors.textColor} disableHover margin="0">
                        <StyledDropdown show={secondLevelMenuOpen}>
                          <Label className="dropdown-label ml-2 mt-4">WTK VERSION</Label>
                          <DropdownButton onClick={() => setSecondLevelMenuOpen(!secondLevelMenuOpen)} className=" mt-4" width={menuWrapperWidth + 'px'} id="dropdown-basic">
                            <div className="dropdown-button-text-container">
                              {/* <Dialpad className="mr-2" fontSize="small" /> */}
                              <MenuItemTextWrapper>{globalWtkVersion}</MenuItemTextWrapper>
                            </div>
                            <div className="dropdown-button-icon-arrow"></div>
                          </DropdownButton>
                          <StyledDropdownMenu width={menuWrapperWidth + 'px'} className="no-scrollbar" alignRight>
                            <StyledDropdownMenuItemsWrapper padding="4px">
                              <SearchField
                                placeholder='Search'
                                onClick={() => {
                                  setSecondLevelMenuOpen(true)
                                  setTopLevelMenuOpen(true)

                                }}
                                onInputValueChange={inputText => setQuery(inputText)}
                                inputValue={query}
                              />
                              {(filteredWtkAndSdkVersions ?? toolkitReleaseNotes)?.map((releaseNotes, index) => {
                                if (!isInfrontUser && window.location.hostname.split('.')[0] !== "localhost") return
                                return (
                                  <StyledDropdownItem margin="0" onClick={(ev) => {
                                    changeToolkitVersion(releaseNotes?.version)
                                  }} key={index} value={releaseNotes?.version}>
                                    {releaseNotes?.version}
                                  </StyledDropdownItem>
                                )
                              })}
                            </StyledDropdownMenuItemsWrapper>
                          </StyledDropdownMenu>
                        </StyledDropdown>

                      </StyledDropdownItem>
                      <StyledDropdownItem onClick={ev => window.open('https://infrontonline-my.sharepoint.com/:x:/g/personal/zahirovic_infrontfinance_com/EZ3i4p2cJWhDp6adsI0fem0B-5YlQ7-_eJuDH-sxftdSUA?e=iLfxAA', '_blank').focus()} padding='10px 0px'>
                        <div className="d-flex ml-2 align-content-center align-items-center justify-content-between w-100">
                          <div className="d-flex justify-content-start align-items-center">
                            <LaunchIcon className="mr-2" fontSize="small" />
                            Bug report
                          </div>
                        </div>
                      </StyledDropdownItem>

                      <StyledDropdownItem padding='10px 0px' onClick={ev => window.open(getBackendUrl() + '/api/typings', '_blank').focus()}>
                        <div className="d-flex ml-2 align-content-center align-items-center justify-content-between w-100">
                          <div className="d-flex justify-content-start align-items-center">
                            <ExitToApp className="mr-2" fontSize="small" />
                            Register typings
                          </div>
                        </div>
                      </StyledDropdownItem>

                      <StyledDropdownItem padding='10px 0px' onClick={() => keycloak.logout()}>
                        <div className="d-flex ml-2 align-content-center align-items-center justify-content-between w-100">
                          <div className="d-flex justify-content-start align-items-center">
                            <ExitToApp className="mr-2" fontSize="small" />
                            Log out
                          </div>
                        </div>
                      </StyledDropdownItem>

                    </StyledDropdownMenuItemsWrapper>
                  </StyledDropdownMenu>
                </StyledDropdown>
              ) : (
                <DropdownButton backgroundColor='transparent' hoverBackgroundColor='transparent' className='ml-2' onClick={() => keycloak.logout()} id="dropdown-basic">
                  <div className="d-flex align-content-center align-items-center justify-content-between w-100">
                    <div className="d-flex justify-content-start align-items-center">
                      <ExitToApp style={{ color: "#000A0D", width: '25px', height: '25px' }} fill="#000A0D" />
                    </div>
                  </div>
                </DropdownButton>

              )}

            </div>

          </Toolbar>
          {menuItems[menuIndex]?.childItems?.nodes?.length > 0 ?
            <Collapse mountOnEnter in={menuItems[menuIndex]?.childItems?.nodes?.length > 0} className={classNames.collapsable} timeout={100}>
              <ToggleButtonGroup className={classNames.toggleButtonGroup} value={childTitle} exclusive>
                {menuItems[menuIndex]?.childItems?.nodes?.map((childItem: any, index: number) => {
                  const { label } = childItem
                  return (

                    <ToggleButton
                      disableRipple
                      component={Link}
                      to={(childItem.path + childItem?.connectedNode?.node?.id)}
                      replace={true}
                      state={{ navigationId: childItem.id, id: childItem?.connectedNode?.node?.id }}
                      key={index}
                      onClick={ev => handleOnNavigationClick(ev, childItem)}
                      className={classNames.toggleButton}
                      value={childItem.label}
                      selected={childItem?.path === getChildRouterPath(router)}
                    >

                      {label?.toLowerCase()}
                    </ToggleButton>
                  )
                })}

              </ToggleButtonGroup>
            </Collapse> : <></>
          }
        </AppBar>
      </>

    </div>

  )
}

function getMenuIndex(menuItems: any[], name: string) {
  return menuItems && menuItems?.findIndex(menu => {
    return menu?.label.replace(/\s/g, '')?.toLowerCase()?.replace(/[^\w\s]/gi, '') === `${name?.replace(/\s/g, '')?.toLowerCase()?.replace(/[^\w\s]/gi, '')}`
  });
}

const getChildRouterPath = (router: string[]) => `/${router[0]}/${router[1]}/`
