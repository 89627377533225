import { gql } from "apollo-boost";

const setCommentQuery = gql`
    mutation($content: String, $author: String, $clientMutationId: String, $commentOn: Int) {
        createComment(
            input: {
                content: $content, 
                author:  $author, 
                clientMutationId: $clientMutationId, 
                commentOn: $commentOn
            }) 
        {
        success
        comment {
            id
        }
      }
	}
`;

const updateCommentQuery = gql`
    mutation($id: ID!, $content: String) {
        updateComment(
            input: {
                id: $id
                content: $content, 
            }) 
        {
        success
        comment {
            id
        }
      }
	}
`;

const deleteCommentQuery = gql`
    mutation($id: ID!) {
        deleteComment(input: {id: $id}) {
            clientMutationId
            deletedId
        }
	}
`;

const getCommentQuery = gql`
    query getComment($id: ID!){
        comment(id: $id) {
          id
          content
        }
    }
`

const getToolkitVersionsQuery = gql`
    query getToolkitVersions {
      posts(where: {tag: "toolkitVersions"}){
          nodes {
            acfToolkitVersionsPost {
              toolkitversion {
                fieldGroupName
                version
              }
            }
          }
        }
      }
  
`

export {setCommentQuery, getCommentQuery, getToolkitVersionsQuery, updateCommentQuery, deleteCommentQuery}