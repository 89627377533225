export enum ModalType {
    SaveAs,
    CreateCategory,
    CreateCategoryFromSidebar,
    Edit,
    DeleteSnippet,
    SaveOnly,
    SaveAndShare,
    ShareOnly,
    EditCategory,
    DeleteCategory,
    EditCodeSnippet,
    DeleteCodeSnippet,
    DeleteCodeSnippetFromSidebar,
    DuplicateSnippet,
    DiscardConfirm,
    ReachedMaxAmountSavedSnippets
}