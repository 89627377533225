import { gql } from "apollo-boost"

export const queryTemplate = gql`
    query QueryTemplate($id: ID!, $type: PageIdType) {
        page(id: $id, idType: $type) {
          template {
            templateName
          }
        }
    }
`
export const queryHomePage = gql`
  query QueryHomePage($id: ID!, $type: PageIdType) {
    page(id: $id,  idType: $type) {
      title
      
      acfHome {
        hero {
          buttonLink
          buttonText
          description
          fieldGroupName
          image {
            uri
            title
          }
        }
        content {
          leftSection {
            title
            item {
              fieldGroupName
              text
              title
            }
          }
          rightSection {
            title
            item {
              fieldGroupName
              text
              title
            }
          }
          topSection {
            fieldGroupName
            title
            description
            undertitle
          }
        }
        hidesidebar
      }
    }
  }
`

export const queryWidgetPage = gql`
  query QueryWidgetPage($id: ID!, $type: PageIdType) {
    page(id: $id,  idType: $type) {
      title
      template {
          templateName
      }
      acfWidget {
        hidesidebar
        extends {
          ... on Page {
            id
            slug
            uri
            title
          }
        }
        options {
          default
          description
          fieldGroupName
          name
          required
          types {
            fieldGroupName
            type
          }
          usage
        }
        sandboxLink {
          target
          title
          url
        }
        fieldGroupName
        example
        description
        codeExample
      }
    }
  }
`

export const querySandboxPage = gql`
  query QuerySandboxPage($id: ID!, $type: PageIdType) {
    page(id: $id,  idType: $type) {
      title
      acfSandbox {
        css
        js
        html
        fieldGroupName
        hidesidebar
      }
    }
  }
`

export const queryGettingStartedPage = gql`
  query QueryGettingStartedPage($id: ID!, $type: PageIdType) {
    page(id: $id,  idType: $type) {
      title
      template {
          templateName
      }
      acfGettingStarted {
        fieldGroupName
        gettingStartedData {
          codeExamples {
            codeExample
          }
          descriptions {
            description
          }
          fieldGroupName
          header
          name
        }
      }
    }
  }
`



export const queryEveryWidgetPage = gql`
  query QueryEveryWidgetPage($id: ID!, $type: PageIdType) {
    page(id: $id,  idType: $type) {
      title
      template {
        templateName
      }
      acfHome {
        hero {
          buttonLink
          buttonText
          description
          fieldGroupName
          image {
            uri
            title
          }
        }
        content {
          leftSection {
            title
            item {
              fieldGroupName
              text
              title
            }
          }
          rightSection {
            title
            item {
              fieldGroupName
              text
              title
            }
          }
          topSection {
            fieldGroupName
            title
            description
            undertitle
          }
        }
        hidesidebar
      }
      acfWidget {
        hidesidebar
        extends {
          ... on Page {
            id
            slug
            uri
            title
          }
        }
        options {
          default
          description
          fieldGroupName
          name
          required
          types {
            fieldGroupName
            type
          }
          usage
        }
        sandboxLink {
          target
          title
          url
        }
        fieldGroupName
        example
        description
        codeExample
      }
      acfSandbox {
        css
        js
        html
        fieldGroupName
        hidesidebar
      }
      acfFields {
        fieldGroup {
          field {
            description
            expandable
            fieldGroupName
            name
          }
          fieldGroupTitle
        }
        hidesidebar
      }
      acfGettingStarted {
        fieldGroupName
        gettingStartedData {
          codeExamples {
            codeExample
          }
          descriptions {
            description
          }
          fieldGroupName
          header
          name
        }
        hidesidebar
      }
    }
  }
`
export const queryAvailableFields = gql`
  query QueryAvailableFields {
    pages(where: {name: "Available fields"}) {
      nodes {
        acfFields {
          fieldGroup {
            field {
              description
              expandable
              fieldGroupName
              name
            }
            fieldGroupTitle
          }
        }
      }
    }
  }
`

export const queryWtkReleaseNotes = gql`
  query QueryWtkReleaseNotes {
    pages(where: {name: "Wtk release notes"}) {
      nodes {
        id
        acfReleaseNotes {
          releaseNotes {
            date
            features
            fieldGroupName
            fixes
            sdkVersion
            version
          }
        }
      }
    }
  }
`
export const queryWtkSdkReleaseNotesAndDeveloperUsers = gql`
  query WtkSdkReleaseNotesAndDeveloperUsers {
    SDKReleaseNotes: pages(where: {name: "Sdk release notes"}) {
      nodes {
        id
        acfReleaseNotes {
          releaseNotes {
            date
            features
            fieldGroupName
            fixes
            sdkVersion
            version
            remarks
          }
        }
      }
    },
    Languages:  pages(where: {name: "Languages"}) {
      edges {
        node {
          id
          acfLanguages {
            languages {
              language
              code
            }
          }
        }
      }
    }
    WTKReleaseNotes: pages(where: {name: "Wtk release notes"}) {
      nodes {
        id
        acfReleaseNotes {
          releaseNotes {
            date
            features
            fieldGroupName
            fixes
            sdkVersion
            version
            remarks
          }
        }
      }
    }
    DeveloperUsers: pages(where: {name: "Developer users"}) {
      nodes {
        title
        acfDeveloperUsers {
          providerInfo {
            developerName
            ownerProviderId
          }
        }
      }
    }
  }
`