import { ModalActionType } from "../../../enums/ModalActionType";
import { utf8_to_b64 } from "../../../utils/utils";

type CodeStringifierServiceProps = {
    originalmarkdown: string,
    originaljavascript: string,
    originalstyles: string
    category: string
    name: string
    codeType: ModalActionType
    owner: string,
}

export const CodeStringifierService = ({originalmarkdown, originaljavascript, originalstyles, category, codeType, name, owner}: CodeStringifierServiceProps) => {

    const getStrigifiedCodeSnippet = () => {
        let codeSnippet = utf8_to_b64(originalmarkdown ?? ' ') + '|?';
        codeSnippet += utf8_to_b64(originaljavascript ?? ' ') + '|?'
        codeSnippet += utf8_to_b64(originalstyles ?? ' ') + '|?'
        codeSnippet += utf8_to_b64(category) + '|?'
        codeSnippet += utf8_to_b64(name) + '|?'
        codeSnippet += utf8_to_b64(codeType) + '|?'
        codeSnippet += utf8_to_b64(owner) + '|?'
        return codeSnippet
    }
    const getStringifiedCategory = () => {
        return utf8_to_b64(category) + '|?'
    }
    return {getStrigifiedCodeSnippet, getStringifiedCategory}
}