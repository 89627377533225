import React from "react";
import { Label, Switch, SwitchOption } from "../styledComponents/layoutButton";

export const WTKSwitchThemeComponent = (props) => (
    <Switch selected={props?.isTerminalTheme} className="switch-container ml-2">
        <Label className="ml-2">WTK THEME</Label>
        <div className="overlay"></div>
        <SwitchOption selected={!props?.isTerminalTheme} onClick={() => props?.handleSwitch(false)} id="left" className="switch">
            {
                /* <div className="overlay"></div> */
            }
            <div className="text">Light</div>
        </SwitchOption>
        <SwitchOption onClick={() => props?.handleSwitch(true)} selected={props?.isTerminalTheme} id="right" className="switch">
            <div className="text">Dark</div>
        </SwitchOption>
    </Switch>
);

