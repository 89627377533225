import { useState, useEffect } from "react"
import BreakPointDevice from "../enums/BreakPointDevices"
import { breakpointDevice } from "../utils/breakpointCalculator"
import useWindowSize from "./useWindowSize"

export const useBreakPointDeviceState = (onBreakPointDeviceChanged?: (device: BreakPointDevice) => void) => {
    const windowSize = useWindowSize()
    const [breakPointDevice, setBreakPointDevice] = useState<BreakPointDevice>(BreakPointDevice.Desktop)
    // Set layout based on windowWidth
    useEffect(() => {
        if(breakpointDevice() === BreakPointDevice.Mobile) setBreakPointDevice(BreakPointDevice.Mobile)
        if(breakpointDevice() === BreakPointDevice.Tablet) setBreakPointDevice(BreakPointDevice.Tablet)
        if(breakpointDevice() === BreakPointDevice.Desktop) setBreakPointDevice(BreakPointDevice.Desktop)
    }, [windowSize.width])
    useEffect(() => {
        if(onBreakPointDeviceChanged) onBreakPointDeviceChanged(breakPointDevice)
    }, [breakPointDevice])
    return {breakPointDevice}
}
