import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useGlobal } from 'reactn';
import styled from 'styled-components';

import { LoaderComponent } from '../loader';
import { SearchField } from '../search';
import { PageType } from './../../enums/pages';
import { AvailableFieldsSidebar } from './availableFieldsSidebar';
import { useStyles } from './MuiStyles/MuiSidebarStyles';
import { ReleaseNotesSidebar } from './releaseNotesSidebar';
import { SideMenuWithNavigation } from './SidebarWithNavigation/sideMenuWithNavigation';
import { SideMenuSDK } from './sideMenuSDK';
import { SideMenuWithoutNavigation } from './sideMenuWithoutNavigation';
import { SearchBarWrapper } from './styledComponents/searchBar';
import { UserCodeSnippetsSidebar } from './userCodeSnippetsSidebar';

type SidebarProps = {
    router: any
    location: any
    menuItems: any
    pageType: PageType
    isReady: boolean
    width: number
    state?: any
    contentTemplateRef?: React.MutableRefObject<any>
    loading:boolean
    showSidebar: boolean
}

const SidebarWrapper = styled('div')`
    overflow-y: scroll;
    height: calc(100% - 100px);
`

export default function Sidebar({ router, location, menuItems, pageType, isReady, width, state, contentTemplateRef, loading, showSidebar} : SidebarProps){
    const classes = useStyles()

    const [showLoadingInSidebar] = useGlobal('showLoadingInSidebar')

    const [filterText, setFilterText] = useState('')

    //@ts-ignore
    const [selectedParents, setSelectedParents] = useGlobal('selectedParents')

    const [itemsToShow, setItemsToShow] = useState(menuItems)

    const sideMenu = (width?: number | string) => {
        if(menuItems) {
            if(pageType === PageType.ChainFinder)
                return <SideMenuWithoutNavigation
                //@ts-ignore
                isReady={isReady}
                menuItems={menuItems}
                filteredText={filterText}
                setSelectedParents={setSelectedParents}
                //@ts-ignore
                selectedParents={selectedParents}
                width={width}
            />
            if(pageType === PageType.Sandbox || pageType === PageType.Reference || pageType === PageType.Examples || pageType === PageType.Language)

                return <SideMenuWithNavigation
                //@ts-ignore
                menuItems={menuItems}
                filteredText={filterText}
                setSelectedParents={setSelectedParents}
                //@ts-ignore
                selectedParents={selectedParents}
                width={width}
                router={router}
                itemsToShow={itemsToShow}
                setItemsToShow={setItemsToShow}
                location={location}
                pageType={pageType}
            />
            if(pageType === PageType.Sdk) {
                //@ts-ignore
                return <SideMenuSDK menuItems={menuItems} filteredText={filterText}></SideMenuSDK>
            }
            if(pageType === PageType.AvailableFields) {
                return <AvailableFieldsSidebar
                    menuItems={menuItems}
                    filteredText={filterText}
                    contentTemplateRef={contentTemplateRef}
                />
            }
            if(pageType === PageType.SdkReleaseNotes || pageType === PageType.WtkReleaseNotes) {
                return <ReleaseNotesSidebar
                    menuItems={menuItems}
                    filteredText={filterText}
                    state={state}
                    contentTemplateRef={contentTemplateRef}
                />
            }
            if(pageType === PageType.MyCodeSnippets) {
                return <UserCodeSnippetsSidebar menuItems={menuItems}
                filteredText={filterText}
                state={state}
                contentTemplateRef={contentTemplateRef}
                router={router}
                location={location}
                showSidebar={showSidebar}
            ></UserCodeSnippetsSidebar>
            }
        }

    }

    const ref = useRef(null)
    const handleFilterChange = _.debounce(e => {
        if(e) setFilterText(e)
        else setFilterText('')
    },500)
    useEffect(() => {
        ref?.current && (ref.current.value = '')
        setFilterText('')
    }, [router[1]])
    const [openSidebar, setOpenSidebar] = useState(false)
    /* const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent); */
    const drawerClasses = {
        drawer: classes().drawer,
        slideOutTab: classes().slideOutTab,
        swipableArea: classes().slideOutTab,
        sidebarSlideOutTab: `${classes().slideOutTab} __swiped`
    }

    return (
        <div style={{ padding: '16px', height: '100%'}}>
            <SearchBarWrapper>
                <SearchField id="sidebar-search-field" elementRef={ref} width="100%" onInputValueChange={handleFilterChange}></SearchField>
            </SearchBarWrapper>
            <SidebarWrapper className="no-scrollbar mt-5"  style={{ }}>
                {!menuItems || (showLoadingInSidebar && pageType === PageType.MyCodeSnippets) ? (
                    <div style={{height: '100%'}} className="d-flex justify-content-center align-items-center">
                        <LoaderComponent />
                    </div>
                ) : (
                    <div style={{ height: '100%'}}>
                        {sideMenu('')}
                    </div>
                )}
            </SidebarWrapper>
        </div>
    );
}