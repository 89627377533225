import React, { useMemo } from "react";
import { ModalActionType } from "../../../../enums/ModalActionType";
import { ModalType } from "../../../../enums/ModalType";
import { UserCodeSnippetSidebarActions } from "../../../../enums/UserCodeSnippetSidebarAction";
import * as defaultTheme from '../../../../theme';
import { ModalTrigger } from "../sandboxModalTrigger";

export const createEditCategoryOrSnippetModal = (
    shareCode: (categoryName: string, actionType: ModalActionType, snippetName: string, modalType: ModalType) => void, 
    setEditCategoryModalShow: React.Dispatch<React.SetStateAction<boolean>>, 
    editCategoryModalShow: boolean, 
    props: any, 
    updatingCommentLoading: boolean, 
    codeSnippetLoading: boolean, 
    saveCodeAction: UserCodeSnippetSidebarActions, 

    updatedPostLoading: boolean, 

    categoryName: string, 
    snippetName: string 
) => {
    return useMemo(() => {
        return (
            <ModalTrigger
                modalType={saveCodeAction === UserCodeSnippetSidebarActions.EditDetailsCategory ? ModalType.EditCategory : ModalType.EditCodeSnippet}
                shareCode={shareCode}
                iconClassName="saveIcon"
                setModalShow={setEditCategoryModalShow}
                modalShow={editCategoryModalShow}
                sidebarMenuItemsCode={props.sidebarMenuItemsCode}
                categoryName={categoryName}
                snippetName={snippetName}
                loading={updatingCommentLoading || codeSnippetLoading || updatedPostLoading }
                id={Math.floor(100000 + Math.random() * 900000)}
                location={props.location}
                headerIconBackground={defaultTheme.default.colors.buttons.save.backgroundColor}
                headerIconColor={defaultTheme.default.colors.buttons.save.textColor}
                headerIconSrc='/icons/edit.svg'
                headerTitle={saveCodeAction === UserCodeSnippetSidebarActions.EditDetailsCategory ? 'Edit category' : 'Edit snippet'}
                noButton={true} 
            />
        );

    }, [editCategoryModalShow, updatingCommentLoading, codeSnippetLoading, updatedPostLoading, categoryName, snippetName]);
}