import { useState } from "react"
import { WindowEventType } from "../enums/windowEventType";
import useWindowListener from "./useWindowListener"

export const useSidebarState = (sidebarRef: React.MutableRefObject<undefined>) => {
    const [sidebarWidth, setSidebarWidth] = useState(360)
    const isBrowser = typeof window !== 'undefined';
    if(isBrowser) {
      const callback = () => {
        if(sidebarRef.current) {
          //@ts-ignore
          setSidebarWidth(sidebarRef.current.clientWidth)
        }
      }
      const type: WindowEventType = WindowEventType.Resize
      useWindowListener({type, callback})
    }

    return {sidebarWidth}
}