import React, {  } from "react"
import { LoaderComponent } from "../components/loader"

export default function DefaultTemplate(props) {
  return (
      <LoaderComponent />
  )
}


