import React, { useEffect, useState } from "react";
import styled, {css} from 'styled-components'
import Search, { SearchField } from "../../../components/search";
import { DropdownButton, Label, MenuItemTextWrapper, StyledDropdown, StyledDropdownItem, StyledDropdownMenu, StyledDropdownMenuItemsWrapper } from "../styledComponents/layoutButton";

const componentButtonWidth = 130;
const componentDropdownWidth = 200;
const componentHeight = 400;

export const WTKLanguageSelectorComponent = (props) => {
    return (
        <StyledDropdown className="ml-2">
            <Label className="dropdown-label ml-2">WTK Language</Label>
            <DropdownButton width={componentButtonWidth + 'px'} id="dropdown-basic">
                <div className="dropdown-button-text-container">

                    <MenuItemTextWrapper>{props?.wtkLanguage?.language}</MenuItemTextWrapper>
                </div>
                <div className="dropdown-button-icon-arrow"></div>
            </DropdownButton>
            <StyledDropdownMenu  className="thin-scrollbar" alignRight>
                <StyledDropdownMenuItemsWrapper padding="4px">

                    {props?.languages?.edges[0]?.node?.acfLanguages?.languages?.map((language, index) => {
                        return (
                            <StyledDropdownItem margin="4px 0" onClick={() => props?.setWtkLanguage(language)} key={index} value={language?.language}>
                                    {language?.language}
                            </StyledDropdownItem>
                        ) 
                    })}
                </StyledDropdownMenuItemsWrapper>
            </StyledDropdownMenu>
        </StyledDropdown>
    )
} 