export const getSandboxMarkdown = (js, html, css, toolkitVersion, isTerminalTheme, token, loginUrl, localToolkitVersionPort, isAnalytics, isSdkExample, isMobileExample, isNewCodeExample, language) => {
    let cssSource = ''
    let highCharts = ''
    let ui = 'https://wtk.infrontservices.com/js/InfrontUI-latest.min.js'
    let cwf = 'https://wtk.infrontservices.com/js/CommonFramework-latest.min.js'
    let vis = 'https://wtk.infrontservices.com/js/VisualUI-latest.min.js'
    let jsSource = ''
    let sdk = ''
    let languageSource =  language === 'en' ? '' : `<script type="text/javascript" src="//wtk.infrontservices.com/languages/${language}.js"></script>`

    if(toolkitVersion && toolkitVersion.toLowerCase() === 'master') {
        ui = 'https://wtk.infrontservices.com/js/InfrontUI-latest.min.js'
        cwf = 'https://wtk.infrontservices.com/js/CommonFramework-latest.min.js'
        cssSource += '<link rel="stylesheet" href="//wtk.infrontservices.com/css/InfrontFramework-latest.css" type="text/css"></link>\n'
        cssSource += '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:400,500,700)" type="text/css"></link>\n'
        cssSource += isTerminalTheme ? '<link rel="stylesheet" href="//wtk.infrontservices.com/themes/terminal-latest/theme.css"></link>\n' : '<link rel="stylesheet" href="//wtk.infrontservices.com/themes/light-latest/theme.css" type="text/css"></link>\n'
    }  else if(toolkitVersion && toolkitVersion.toLowerCase() === 'beta') {
        cwf = 'https://wtk.infrontservices.com/beta/JS/CommonFramework-latest.min.js'
        ui = 'https://wtk.infrontservices.com/beta/JS/InfrontUI-latest.min.js'
        cssSource += '<link rel="stylesheet" href="//wtk.infrontservices.com/beta/css/InfrontFramework-latest.css" type="text/css"></link>\n'
        cssSource += '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:400,500,700)" type="text/css"></link>\n'
        cssSource += isTerminalTheme ? '<link rel="stylesheet" href="//wtk.infrontservices.com/beta/themes/terminal-latest/theme.css"></link>\n' : '<link rel="stylesheet" href="//wtk.infrontservices.com/beta/themes/light-latest/theme.css" type="text/css"></link>\n'
    } else if(toolkitVersion && toolkitVersion.toLowerCase() === 'local') {
        ui =  `http://localhost:${localToolkitVersionPort}/InfrontUI/js/InfrontUI-latest.c.js`
        vis =   `http://localhost:${localToolkitVersionPort}/InfinancialsUI/output/js/VisualUI-latest.c.js`
        cssSource += `<link rel="stylesheet" href="http://localhost:${localToolkitVersionPort}/InfrontUI/css/InfrontFramework-latest.css" type="text/css"></link>\n`
        cssSource += `<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:400,500,700)" type="text/css"></link>\n`
        cssSource += isTerminalTheme ? `<link rel="stylesheet" href="http://localhost:${localToolkitVersionPort}/InfrontUI/themes/terminal-latest/theme.css"></link>\n` : `<link rel="stylesheet" href="http://localhost:${localToolkitVersionPort}/InfrontUI/themes/light-latest/theme.css"></link>\n`
    } else if(toolkitVersion && toolkitVersion.toLowerCase() !== 'latest' && toolkitVersion && toolkitVersion.toLowerCase() !== 'master') {
        let uiToCwf =  toolkitVersion.split('.')
        uiToCwf[0] = (parseInt(uiToCwf[0]) -1).toString()
        const ver = uiToCwf.join('.')
        ui = toolkitVersion ? 'https://wtk.infrontservices.com/js/InfrontUI-'+ toolkitVersion + '.min.js' : 'https://wtk.infrontservices.com/js/InfrontUI-latest.min.js'
        cwf = toolkitVersion ?  'https://wtk.infrontservices.com/js/CommonFramework-' + ver + '.min.js' : 'https://wtk.infrontservices.com/js/CommonFramework-latest.min.js'
        cssSource += `<link rel="stylesheet" href="//wtk.infrontservices.com/css/InfrontFramework-${toolkitVersion}.css" type="text/css"></link>\n`
        cssSource += `<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:400,500,700)" type="text/css"></link>\n`
        cssSource += isTerminalTheme ? `<link rel="stylesheet" href="//wtk.infrontservices.com/themes/terminal-${toolkitVersion}/theme.css"></link>\n` : `<link rel="stylesheet" href="//wtk.infrontservices.com/themes/light-${toolkitVersion}/theme.css" type="text/css"></link>\n`
    }


    if(isAnalytics) {
        cssSource = '<link rel="stylesheet" href="//wtk.infrontservices.com/css/InfrontFramework-latest.css" type="text/css"></link>\n'
        cssSource += '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:400,500,700)" type="text/css"></link>\n'
        cssSource += isTerminalTheme ? '<link rel="stylesheet" href="//wtk.infrontservices.com/themes/terminal-latest/theme.css"></link>\n' : '<link rel="stylesheet" href="//wtk.infrontservices.com/themes/light-latest/theme.css" type="text/css"></link>\n'
        cssSource += '<link rel="stylesheet" href="//wtk.infrontservices.com/css/VisualFramework-latest.css" type="text/css">'
        cssSource += '<link rel="stylesheet" href="//wtk.infrontservices.com/visualthemes/light-latest/vtheme.css" type="text/css">'
        cssSource += '<link rel="stylesheet" href="//visual.infinancials.com/css/widget/component-api-2.0/component_60.css" type="text/css" />\n';
        cssSource += '<link href="//visual.infrontanalytics.com/visual/css/perfect-scrollbar.min.css" rel="stylesheet" type="text/css" />';

        jsSource += '<script src="//ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js"></script>'
        jsSource += '<script type="text/javascript" src="//pp.infrontanalytics.com/js/chart-conf/highcharts.js"></script>'
        jsSource += '<script type="text/javascript" src="//pp.infrontanalytics.com/js/chart-conf/highcharts-more.js"></script>'
        jsSource += '<script type="text/javascript" src="//pp.infrontanalytics.com/js/chart-conf/map.js"></script>'
        jsSource += '<script src="https://code.highcharts.com/mapdata/custom/world-highres.js" type="text/javascript"></script>'
    } else {

        // highCharts += '<script type="text/javascript" src="//code.highcharts.com/stock/5.0.7/highstock.js"></scr'+'ipt>\n';
        // highCharts += '<script type="text/javascript" src="//code.highcharts.com/stock/5.0.7/highcharts-more.js"></scr'+'ipt>';
        highCharts += '<script type="text/javascript" src="https://code.highcharts.com/stock/9.3.1/highstock.js"></script>'
        highCharts += '<script type="text/javascript" src="https://code.highcharts.com/stock/9.3.1/indicators/indicators-all.js"></script>'
        highCharts += '<script type="text/javascript" src="https://code.highcharts.com/stock/9.3.1/modules/drag-panes.js"></script>'
        highCharts += '<script type="text/javascript" src="https://code.highcharts.com/stock/9.3.1/modules/annotations-advanced.js"></script>'
        highCharts += '<script type="text/javascript" src="https://code.highcharts.com/stock/9.3.1/modules/stock-tools.js"></script>'
        highCharts += '<script type="text/javascript" src="https://code.highcharts.com/stock/9.3.1/modules/heikinashi.js"></script>'
        highCharts += '<script type="text/javascript" src="https://code.highcharts.com/stock/9.3.1/modules/hollowcandlestick.js"></script>'
    }

    jsSource += '<script type="text/javascript" src="https://d3js.org/d3.v4.min.js"></script>'
    jsSource += '<script type="text/javascript" src="https://visual.infrontanalytics.com/visual/js/perfect-scrollbar.min.js"></script>'
    jsSource += '<script type="text/javascript" src="//ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js"></script>'
    jsSource += `<script type="text/javascript" src="${cwf}"></script>\n`;
    jsSource += `<script type="text/javascript" src="${ui}"></script>\n`;
    jsSource += `<script type="text/javascript" src="${vis}"></script>\n`;
    jsSource += `<script type="text/javascript" src="${sdk}"></script>\n`;

    const loginOpts  = new Infront.InfrontUIOptions()
    loginOpts.useDefaultStateStorage = true;
    loginOpts.visualStatelessEndpoint = true;
    loginOpts.visualWidgetAccess = true;
    loginOpts.visualComponentPath = 'rest/component'
    loginOpts.language = language
    if(token.token) {
        try {
            loginOpts.token_type = 'JWT'
            // loginOpts.loginUrls=[loginUrl]
            loginOpts.signed_token = token.token
            loginOpts.streaming = true;
            loginOpts.store_session = false;
            loginOpts.enableLoginDialog = false;
            loginOpts.tradingOptions.token_type = 'IDP';
            loginOpts.tradingOptions.signed_token = token.token
            loginOpts.tradingOptions.enableAutoLogin = true;

            //@ts-ignore
            if(isAnalytics) {
                loginOpts.token_type = "JWT";


                /* loginOpts.visualRoutingUrl = "https://visual.infrontanalytics.com/visual"; */
            }
        } catch (error) {
            
        }
    }
    return `
        <!DOCTYPE html>
        <html>
            <head>
                ${jsSource}
                ${cssSource}
                ${highCharts}

                ${languageSource}
                <style>
                    .cell-body {
                        background: transparent;
                    }
                    ${css}
                </style>
                <script>
                try {
                    

                    const exec = () => {
                        if(typeof Infinancials === 'undefined' && ${localToolkitVersionPort > -1}) console.log('%c Cannot reach local Infinancials. Please host it at <hostname>:<port>/InfinancialsUI/output/js/VisualUI-<version>.c.js', 'background: #222; color: #bada55');
                        if(typeof Infront === 'undefined' && ${localToolkitVersionPort > -1}) console.log('%c Cannot reach local Infront. Please host it at <hostname>:<port>/CommonWebFramework/output/js/CommonFramework-<version>.c.js', 'background: #222; color: #bada55');
                        if(typeof Infront.UI === 'undefined' && ${localToolkitVersionPort > -1}) console.log('%c Cannot reach local Infront. Please host it at <hostname>:<port>/InfrontUI/js/InfrontUI-<version>.c.js', 'background: #222; color: #bada55');
                        if(typeof InfrontSDK === 'undefined' && ${localToolkitVersionPort > -1}) console.log('%c Cannot reach local InfrontSDK. Please host it at <hostname>:<port>/CommonWebFramework/output/js/sdk-<version>.js', 'background: #222; color: #bada55');

                        var loginOpts = ${JSON.stringify(loginOpts)}
                        const newInfront = new Infront.UI(loginOpts);
                        window.infront = newInfront;
                        window.sdk = (typeof InfrontSDK != 'undefined') ? new InfrontSDK.SDK({...loginOpts, }) : undefined;
                        newInfront.registerEventObserver("onReady", function (event) {
                            if(typeof Infinancials === 'undefined') {
                                if(${localToolkitVersionPort > -1} && ${isAnalytics}) {
                                    console.error('Infinancials is not defined, please check the message above where to host it and try again. Your code will not be executed');
                                    return;
                                }
                                const src = document.getElementById("exscript");
                                if(src) {
                                    if(src.innerHTML && src.innerHTML.length > 0) src.innerHTML = null;
                                    src.innerHTML = ${JSON.stringify(js)}
                                }
                            } else {
                                var infinancialsUI  = new Infinancials.UI(${JSON.stringify(loginOpts)}, newInfront.getModel());
                                window.visual = infinancialsUI;
                                infinancialsUI.registerEventObserver(Infront.VisualReadyEvent.kEventName, ev => {
                                    const src = document.getElementById("exscript");
                                    if(src) {
                                        if(src.innerHTML && src.innerHTML.length > 0) src.innerHTML = null;
                                        src.innerHTML = ${JSON.stringify(js)}

                                    }
                                });
                                Infront.EventCallbackMap.eventMap.publish( new Infront.VisualReadyEvent() );
                            }

                        });
                        newInfront.registerEventObserver("onDisconnect", function (event) { console.error('disconnect') });
                        newInfront.registerEventObserver("onLoginFailed", function (event) { 
                            const src = document.getElementById("exscript");
                            if(src) {
                                    if(src.innerHTML && src.innerHTML.length > 0) src.innerHTML = null;
                                    src.innerHTML = ${JSON.stringify(js)};
                            } 
                        });
                        newInfront.init();

                    }
                    document.addEventListener('DOMContentLoaded', (event) => {
                        exec();
                    });
                } catch (error) {
                    console.log(error)
                }
                </script>


            </head>
            
            <body ${!isMobileExample && 'class="cell-body"'} style="color: ${isTerminalTheme ? 'white' : 'black'}">
                ${html}
            </body>
            <script id="exscript"></script>
            <script>
                var dom_observer = new MutationObserver(function(mutation) {
                    console.log('function called');
                });
                var container = document.documentElement || document.body;
                var config = { attributes: true, childList: true, characterData: true };
                dom_observer.observe(container, config);

                document.addEventListener('click', ev => {
                    ev.preventDefault();
                });
            </script>
        </html>
    `
}