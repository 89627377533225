import { useState, useEffect } from "react"
import { useGlobal } from "reactn"
import { getBackendUrl } from "../utils/utils"
import typescript from "typescript"
import Module from 'module'

export const useSdkDocumentationMeta = () => {
    const [packagesMetadata, setPackagesMetadata] = useState(null)
    const [SDKVersion, setSDKVersion] = useGlobal('SDKVersion')
    
    const [symbolFields, setSymbolFields] = useState(null)
    useEffect(() => {
        SDKVersion?.version && fetchAndSaveSymbolFields(SDKVersion?.version)
    }, [SDKVersion])

    const fetchAndSaveSymbolFields = async (version: string) => {
      const parsedVersion = version?.split('-').length ? version?.split('-')[0] : version
      const symbolFieldsResponse = await fetch(getBackendUrl() + `/api/symbol-fields?filename=${version}/typings/InfrontUI-${ parsedVersion}.c.d.ts`)
      const symbolFields = await symbolFieldsResponse.json()
      setSymbolFields(symbolFields)
    }

    const fetchAndSetPackagesMetaData = async () => {
        try {
          const packagesResponse = await fetch(getBackendUrl() +'/api/packages-metadata')
          const packagesMetadata = await packagesResponse.json()
          setPackagesMetadata(packagesMetadata?.wtkVersions)
        } catch (error) {
          console.log(error)
        }
      }
    useEffect(() => {
        fetchAndSetPackagesMetaData()
    }, [])

    return {packagesMetadata, symbolFields}
}