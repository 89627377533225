import React, { useMemo } from 'react';
import { ModalActionType } from '../../../../enums/ModalActionType';
import { ModalType } from '../../../../enums/ModalType';
import { ModalTrigger } from '../sandboxModalTrigger';
import * as defaultTheme from '../../../../theme';

export const createEditSnippetModal = (setEditModalShow: React.Dispatch<React.SetStateAction<boolean>>, shareCode: (categoryName: string, actionType: ModalActionType, snippetName: string, modalType: ModalType) => void, editModalShow: boolean, props: any, updatingCommentLoading: boolean, codeSnippetLoading: boolean,categoryName: string,snippetName: string) => {
    return useMemo(() => {
        return (
            <ModalTrigger
                modalType={ModalType.EditCodeSnippet}
                noButton
                shareCode={shareCode}
                iconClassName="saveIcon"
                setModalShow={setEditModalShow}
                modalShow={editModalShow}
                categoryName={categoryName}
                snippetName={snippetName}
                sidebarMenuItemsCode={props.sidebarMenuItemsCode}
                loading={updatingCommentLoading || codeSnippetLoading}
                id={Math.floor(100000 + Math.random() * 900000)}
                location={props.location}
                headerIconBackground={defaultTheme.default.colors.buttons.save.backgroundColor}
                headerIconColor={defaultTheme.default.colors.buttons.save.textColor}
                headerIconSrc='/icons/edit.svg'
                headerTitle='Edit details for code snippet' />
        );

    }, [editModalShow, updatingCommentLoading, codeSnippetLoading, props.sidebarMenuItemsCode, categoryName, snippetName]);
}

