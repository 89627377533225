import { gql } from "apollo-boost";

export const GetPostById = gql`
    query getPostById($title: ID!) {
        post(id: $title, idType: SLUG) {
            id
            authorId
            authorDatabaseId
            commentCount
            commentStatus
            content
            comments(first:100) {
              nodes {
                content
                id
                parentId
                databaseId
              }
            }
            databaseId
            date
            dateGmt
            desiredSlug
            enclosure
            slug
            status
            categories {
              edges {
                node {
                  id
                  name
                }
              }
            }
        }
    }
`

export const UpdatePostCategories = gql`
    mutation updatePostCategories($id: ID!, $nodes: [PostCategoriesNodeInput], $append: Boolean) {
      updatePost(input: {categories: {append: $append, nodes: $nodes}, id: $id}) {
        clientMutationId
        post {
          categories {
            nodes {
              id
              name
            }
          }
        }
      }
    }
`