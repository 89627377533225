import Modal from 'react-bootstrap/esm/Modal'
import React from "react"
import { ModalState } from "../../../../../types/ModalState"
import { StyledFormControl, TextWrapper } from '../../../styledComponents/layoutButton'

export const DeleteModalBody = ({
    categoryName,
    setCategoryName,
    setModalShow,
    message
}: ModalState & {
    message: string
}) => (
    <Modal.Body className="pt-4 px-4">
        <TextWrapper fontSize="16px" color="#000A0D" className="text-center mt-2">
            {message}
        </TextWrapper>
    </Modal.Body>
)