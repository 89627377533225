import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import { ModalState } from '../../../../types/ModalState'
import { ModalFactory } from "../sandboxModalFactory";
import { ModalPart } from "../../../../enums/modalPart";

export const DeleteCategoryModal = ({
    modalShow,
    setModalShow,
    headerIconBackground,
    headerIconColor,
    headerTitle,
    headerIconSrc,
    deleteComment,
    modalType, 
    searchParams, 
    shareCode
}: ModalState) => (
    <Modal contentClassName="delete-modal" centered show={modalShow} onHide={() => {
        setModalShow(false);
    }} id={1}>
        {[ModalPart.Header, ModalPart.Body, ModalPart.Footer].map((modalPart, index) => (
            <ModalFactory key={"modalPart_"+index} modalPart={ModalPart[ModalPart[modalPart]]} modalPartState={{
                modalShow,
                setModalShow,
                headerIconBackground,
                headerIconColor,
                headerTitle,
                headerIconSrc,
                deleteComment,
                modalType, 
                searchParams,
                shareCode
            }} />
        ))}
    </Modal>
);