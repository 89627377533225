export const ClipboardService = () => {
    const copyToClipboard = (value?: string) => {
        const textArea = document.createElement("textarea");
        textArea.value = value ? value :window.location.href;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        textArea.remove();
    }

    return {copyToClipboard}
}