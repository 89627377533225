import { useState } from "react";
import useWindowListener from "./useWindowListener";
import { WindowEventType } from "../enums/windowEventType";

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    // Handler to call on window resize
    const callback = () => {
    // Set window width/height to state
        setWindowSize({
            width: window.outerWidth,
            height: window.outerHeight,
        });
    }
    const type: WindowEventType = WindowEventType.Resize
    useWindowListener({type, callback})
    return windowSize
}
export default useWindowSize