import React, { Fragment, useEffect, useRef } from 'react';
import { useGlobal } from 'reactn';

import { infront } from '../services/auth';

type InfrontWidgetProps = {
    type: any 
    options: any 
    optionalRef?: any
    className?: string
    children?: any
}
export default function InfrontWidget({ type, options, optionalRef, className, children }:InfrontWidgetProps ) {
    const containerRef = optionalRef ? optionalRef : useRef();
    const [infront, setInfront] = useGlobal('infront');
    useEffect(() => {
        if(type && type.length && !!infront) {
            const widget = infront[type](containerRef.current, options) 
            if(widget && widget.destroy) return () => widget.destroy()
        }
    }, [containerRef, infront, options, type]);

    return (<>{!!infront && <div id="widget" className={className} ref={containerRef}>{children}</div>}</>)
}
