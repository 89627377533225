import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useGlobal } from 'reactn';

import { Language } from '../enums/language';
import { WindowEventType } from '../enums/windowEventType';
import { useExitPrompt } from '../hooks/useExitPrompt';
import { DispachState } from '../types/DispatchState';
import { SandboxValue } from '../types/SandboxValue';
import { debounce, isBrowser } from '../utils/utils';
import type Monaco from 'monaco-editor'

type MonacoEditorProps = {
    notifyOnRunCode: React.Dispatch<React.SetStateAction<DispachState>>
    value: SandboxValue
    language: Language
    className?: string
    onChange: React.Dispatch<React.SetStateAction<DispachState>>
    showConsole?: boolean
    childrenCount?: number
    height?: number
    hasCodeSnippet?: boolean
    runCode?: boolean
    iframe?: MutableRefObject<HTMLIFrameElement>
    setConsoleLogs?: (input: any) => void
    consoleLogs?: Array<any>
    props: any
    element: HTMLDivElement
    changeOrigValue: (code: string) => void
    setSandboxValue: (args: any) => void
    sandboxValue: any
    hasCodeFromDatabase: boolean
    typings: string
}

export const MonacoEditor = ({ notifyOnRunCode, value, language, changeOrigValue, onChange, className, showConsole, childrenCount, height, hasCodeSnippet, runCode, iframe, setConsoleLogs, consoleLogs, props, element, sandboxValue, setSandboxValue, hasCodeFromDatabase, typings }: MonacoEditorProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const [monacoEditor, setMonacoEditor] = useState<typeof Monaco.editor>(null)
    const [editorInstance, setEditorInstance] = useState<Monaco.editor.IStandaloneCodeEditor>(null)
    const [monacoInstance, setMonacoInstance] = useState(null)
    //we need this in order to save instances on a global scale, and to be able to dispose of them in order for webworkers to be removed
    const [monacoInstances, setMonacoInstances] = useGlobal('monacoInstances')
    const isWindow = typeof window !== 'undefined' && window


    const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

    var isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    var isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);



    useEffect(() => {
        setShowExitPrompt(sandboxValue?.hasChanged)
    }, [sandboxValue])

    //NOTE: this similar to componentWillUnmount()
    useEffect(() => {
        return () => {
            setShowExitPrompt(false)
        }
    }, [])

    useEffect(() => {
        if (isMacLike || isIOS && ref?.current) {
            ref.current.style.filter = 'brightness(1.5)'
        }
    }, [ref?.current])

    let counter = 0;


    useEffect(() => {
        if (!isWindow) return;
        // We need to import the monaco-editor package dynamically because it uses the window object that is not available on during gatsby build
        const monaco = import("monaco-editor");

        let timeout = null;
        try {
            monacoInstances.forEach(instance => {
                instance?.getModel()?.dispose()
                instance?.dispose();
            })
            try {
                monacoEditor?.getModels()?.forEach(model => model?.dispose())
            } catch (error) {
                console.log(error);
            }
            const ac = new AbortController();
            if (monaco && typings) {

                let editorInstance: Monaco.editor.IStandaloneCodeEditor = null;
                const startMonacoEditor = async () => {
                    try {
                        counter += 1

                        const monacoInstance = await createMonacoInstance( await monaco);

                        setMonacoInstanceOptions(monacoInstance, typings)

                        if (ref.current) {
                            ref.current.innerHTML = null
                            const handleKeyDown = (ev) => {


                                if (ev) {
                                    if (ev.ctrlKey) {

                                        if (ev.key === "s") {
                                            ev.preventDefault()
                                            ev.stopPropagation()
                                            //setSandboxValue({language: language, hasChanged: false})
                                            /* onChange({originalValue:  editorInstance?.getValue(), newValue: editorInstance?.getValue(), runCode: true}); */
                                            notifyOnRunCode(language)
                                        }
                                    }
                                }
                            };
                            const type: WindowEventType = WindowEventType.KeyDown
                            ref.current.addEventListener(type, handleKeyDown);

                            try {
                                editorInstance = monacoInstance?.editor.create(ref.current, {
                                    value: value?.newValue || '',
                                    language: language || '',
                                    contextmenu: false,
                                    minimap: {
                                        enabled: false
                                    },
                                    automaticLayout: true,
                                    lineNumbers: 'on',
                                    theme: 'vs-dark',
                                    fontFamily: 'Consolas, "Courier New", monospace'
                                });



                            } catch (error) {
                                console.log(error)
                            }
                            const handleSandboxCodeChange = debounce(content => {
                                if (content.changes) {
                                    onChange((prev: SandboxValue) => ({ originalValue: prev?.originalValue ?? editorInstance?.getValue(), newValue: editorInstance?.getValue(), runCode: false }));
                                }

                            }, 50, false);

                            editorInstance?.onDidChangeModelContent(handleSandboxCodeChange)
                            setMonacoEditor(monacoInstance?.editor)
                            setEditorInstance(editorInstance);
                            setMonacoInstance(monacoInstance)
                            const instances = monacoInstances
                            instances.push(editorInstance)
                            setMonacoInstances(instances)
                        }
                    } catch (e) {
                        console.log(e)
                    }

                }
                timeout = setTimeout(() => {
                    startMonacoEditor();
                }, 500)



            }
            return () => {
                clearTimeout(timeout);

                ac.abort()
            }
        } catch (error) {
            console.log(error);
        }
    }, [hasCodeSnippet, typings, isBrowser && window?.location?.href])
    useEffect(() => {
        return () => {
            editorInstance?.getModel()?.dispose()

            editorInstance?.dispose();
            monacoInstances?.forEach(instance => {
                instance?.getModel()?.dispose()
                instance?.dispose();
            })

            try {
                monacoEditor?.getModels()?.forEach(model => model?.dispose())
            } catch (error) {
                console.log(error);
            }
        }
    }, [isBrowser && window?.location?.href])
    const createMonacoInstance = async (monacoInstance: typeof Monaco) => {
        try {
            return await monacoInstance
        } catch (error) {
            console.log(error)
        }
    };

    const setMonacoInstanceOptions = (monacoInstance: any, typings: string) => {
        /* ?.languages */
        if (monacoInstance.languages) {
            setJavascriptValidationSettings(monacoInstance.languages);
            setCompilerOptions(monacoInstance.languages);
            setExtraLibraries(monacoInstance.languages, typings, monacoInstance);
        }

    }

    const setJavascriptValidationSettings = (language: typeof Monaco.languages) => {
        language.typescript.javascriptDefaults.setDiagnosticsOptions({
            noSemanticValidation: false,
            noSyntaxValidation: false
        });
    }
    const setCompilerOptions = (language: typeof Monaco.languages) => {
        language.typescript.javascriptDefaults.setCompilerOptions({
            //@ts-ignore
            target: language.typescript.ScriptTarget.ES6,
            allowNonTsExtensions: true,
            skipLibCheck: true,
            moduleResolution: language.typescript.ModuleResolutionKind.NodeJs,
        });
    }
    const setExtraLibraries = (language: typeof Monaco.languages, typings: string, monacoInstance: any) => {
        const extraTypings = 'const infront: Infront.UI; const sdk: Infront.SDK = window.sdk; window.sdk: Infront.SDK; const visual: Infinancials.UI;';
        language.typescript.javascriptDefaults.setExtraLibs([{ content: extraTypings }, { content: typings }]);
        language.typescript.javascriptDefaults.setEagerModelSync(true);
    }

    return (
        <>

            <div style={{ height: '88%' }}>
                <div className={className} ref={ref} id="container" style={{
                    position: 'relative',
                    width: '100%',
                    height: childrenCount == 2 ? '100%' : '100%',
                    maxHeight: childrenCount == 2 ? '100%' : '100%',
                    transition: 'display 0.5s ease-in',
                }}>
                    {/* {showConsole && <MonacoConsole persistentConsole={false} setConsoleLogs={setConsoleLogs} consoleLogs={consoleLogs} iframe={iframe} runCode={runCode} monacoEditor={monacoEditor} monacoEditorInstance={editorInstance} />} */}

                </div>
            </div>
        </>

    )
}
