export enum Layout  {
    FourByFour,
    TwoByTwo,
    OneByOne
}

export enum LayoutMapper {
    FourByFour = 'Standard',
    TwoByTwo = 'Tabbed split',
    OneByOne = 'Tabbed full',
}